import { store } from '../../config/configureStore';
import { getQueryParams, setQueryParams } from '@staizen/utils/dist/URLUtils';
import { Capacitor } from '@capacitor/core';
import { LINKS } from '../../config/configureLinks';

export function generateSsoLink() {
  const { config } = store.getState();
  const showAlphaFeatures = (config.features.alpha && config.environment === 'DEV');
  if (Capacitor.isNativePlatform() || showAlphaFeatures) {
    return setQueryParams(LINKS.ROOT, getQueryParams(window.location.href, true), true);
  }
  return setQueryParams(config.links.ssoPortal, { callback: `${window.location.protocol}//${window.location.host}${window.location.search}` }, true);
}
