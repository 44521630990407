import React, { useState, useEffect, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useStore } from 'react-redux';
import { registerLocaleLoader } from '@staizen/graphene';
import { StzButton } from '@staizen/graphene-react';
import { css } from 'emotion';
import retry from '../../../utils/retry';
import Dialog from '../Dialog';
import { TAG } from './constants';
import IdleTimer from 'react-idle-timer';
import { logoutUser } from '../../../api/auth';
import useCountdown from '../../../hooks/useCountdown';

registerLocaleLoader(TAG, (locale: string) => retry(() => import(`./locale.${locale}.json`)));

interface SessionTimeoutDialogProps {
  isActive: boolean;
}

const styles = {
  content: {
    base: css`
      margin: 0;
      margin-bottom: var(--stz-space-s);
      font: var(--stz-typo-ui-body-m);
    `,
    bold: css`
      font: var(--stz-typo-ui-title-m);
    `,
  },
  noPrompt: css`
    margin: var(--stz-space-m);
    .stz-input-checkbox + .stz-input-checkbox-box  {
      .stz-input-checkbox-indicator {
        margin-left: 0;
      }

      .stz-input-checkbox-content {
        --stz-input-checkbox-base-on: var(--stz-color-contrast-medium);
      }
    }
  `,
};

// Library from: https://github.com/supremetechnopriest/react-idle-timer#readme
// zIndex needs to be higher than anything else
const SessionTimeoutDialog: React.FC<SessionTimeoutDialogProps> = (props) => {
  const { isActive } = props;
  const store = useStore();
  const { config } = store.getState();
  const { sessionTimeoutCountdown } = config;
  // const { onLogout, onContinue } = props;
  const timerRef = useRef(null);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [shouldRenderDialog, setShouldRenderDialog] = useState(false);

  const sessionTimeout = useSelector((state) => {
    if (state.auth?.data?.sessionTimeout) {
      console.log('TIMER selector set:', state.auth.data.sessionTimeout);
      return state.auth.data.sessionTimeout * 60 * 1000; // convert from mins to ms
    }
    console.log('TIMER selector undefined: ', state.auth);
    return undefined;
  });

  useEffect(() => {
    const timer = timerRef.current;
    if (!timer) {
      return;
    }
    if (isActive && sessionTimeout) {
      timer.reset();
      timer.resume();
    } else {
      timer.pause();
      setShouldRenderDialog(false);
    }
  }, [isActive, sessionTimeout]);

  useEffect(() => {
    if (shouldRenderDialog) {
      start();
    } else {
      stop();
    }
  }, [shouldRenderDialog]);

  const onLogoutTriggered = () => {
    setShouldRenderDialog(false);
    setIsLoggingOut(true);
    onLogout();
  };

  const logoutOnIdle = (_: Event) => {
    const timer = timerRef.current;
    if (!timer) {
      return;
    }
    if (sessionTimeout) {
      timer.pause();
      setShouldRenderDialog(true);
      // console.log('user is idle', event, getLastActiveTime(), getRemainingTime());
    }
  };

  const onLogout = () => {
    logoutUser().catch((error: any) => {
      console.warn('Error: Error encountered while logging out user: ', error);
    }).then(() => {
      setShouldRenderDialog(false);
    });
  };

  const onContinue = () => {
    const timer = timerRef.current;
    if (!timer) {
      return;
    }
    timer.reset();
    timer.resume();
    setShouldRenderDialog(false); // ! change to render
  };

  const { t } = useTranslation(TAG);

  const { timeLeft, start, stop } = useCountdown({
    timeGiven: Math.round(sessionTimeoutCountdown / 1000),
    onExpire: onLogoutTriggered,
  });

  const seconds = String(timeLeft).padStart(2, '0');

  return (
    <>
      <IdleTimer
        ref={(ref) => { timerRef.current = ref; }}
        timeout={sessionTimeout || 0}
        startOnMount={false}
        events={[
          'mousemove',
          'keydown',
          'wheel',
          'DOMMouseScroll',
          'mousewheel',
          'mousedown',
          'touchstart',
          'touchmove',
          'MSPointerDown',
          'MSPointerMove',
          'visibilitychange',
        ]}
        onIdle={logoutOnIdle}
        throttle={3000}
        eventsThrottle={3000}
      />
      <Dialog zIndex="10000" isVisible={shouldRenderDialog}>
        <span slot="header">{t('title')}</span>
        <span slot="body">
          <p className={styles.content.base}>
            <Trans
              t={t}
              i18nKey="content"
              components={{
                bold: <span className={styles.content.bold} />,
              }}
              values={{ seconds }}
            />
          </p>
        </span>
        <span slot="key-actions">
          <StzButton variant="text" onClick={onLogoutTriggered} type="button" disabled={isLoggingOut}>
            <span>{t('buttons.logout')}</span>
          </StzButton>
        </span>
        <span slot="key-actions">
          <StzButton preset="primary" onClick={onContinue} type="button" disabled={isLoggingOut}>
            <span>{t('buttons.continue')}</span>
          </StzButton>
        </span>
      </Dialog>
    </>
  );
};

export default SessionTimeoutDialog;
