import { AxiosInstance, AxiosError, AxiosResponse } from 'axios';
import { store } from '../../config/configureStore';
import { getSaxoService } from '../../config/configureApi';
import { setSaxoClientKey } from '../../actions/creators/saxo';

export function getClientKey() {
  const api: AxiosInstance = getSaxoService();
  const { config } = store.getState();
  const { saxo } = store.getState();

  if (saxo.clientKey) { // return clientKey if already stored in memory
    return saxo.clientKey;
  }
  const clientKey = config.useMock
    ? new Promise((res) => {
      setTimeout(() => {
        store.dispatch(setSaxoClientKey(mockUserResponse.data.ClientKey));
        res(mockUserResponse.data.ClientKey);
      }, 1000);
    })
    : api.get(config.endpoints.saxo.user)
      .then((response: AxiosResponse) => {
        const nextClientKey = response.data && response.data.ClientKey; // note: Uppercase variable
        store.dispatch(setSaxoClientKey(nextClientKey));
        return nextClientKey;
      }).catch((error: AxiosError) => {
        console.error(error);
        return error;
      });

  return clientKey;
}

const mockUserResponse = {
  data: {
    ClientKey: 'MRiAff6VmX3Bqfoa54l9xQ==',
    Culture: 'en-US',
    Language: 'en',
    LastLoginStatus: 'Successful',
    LastLoginTime: '2020-09-01T10:02:16.200000Z',
    LegalAssetTypes: [],
    MarketDataViaOpenApiTermsAccepted: false,
    Name: 'Test Client 1',
    TimeZoneId: 26,
    UserId: '12612501',
    UserKey: 'MRiAff6VmX3Bqfoa54l9xQ==',
  },
  status: 200,
  statusText: 'OK',
  headers: {
    'cache-control': 'no-cache',
    'content-encoding': 'gzip',
    'content-length': '238',
    'content-type': 'application/json; charset=utf-8',
    expires: '-1',
    pragma: 'no-cache',
    'x-correlation': 'e55e852f599147b9bd05db15b00d748d#1006#3884d71c-f6c3-4c68-9a88-8d36b843bbcc#82',
  },
  config: {
    url: 'https://gateway.saxobank.com/sim/openapi/port/v1/users/me',
    method: 'get',
    headers: {
      Accept: 'application/json, text/plain, */*',
      Authorization: 'Bearer eyJhbGciOiJFUzI1NiIsIng1dCI6IjhGQzE5Qjc0MzFCNjNFNTVCNjc0M0QwQTc5MjMzNjZCREZGOEI4NTAifQ.eyJvYWEiOiI3Nzc3MCIsImlzcyI6Im9hIiwiYWlkIjoiMTAwNiIsInVpZCI6Ik1SaUFmZjZWbVgzQnFmb2E1NGw5eFE9PSIsImNpZCI6Ik1SaUFmZjZWbVgzQnFmb2E1NGw5eFE9PSIsImlzYSI6IkZhbHNlIiwidGlkIjoiNjAzMSIsInNpZCI6ImU1NWU4NTJmNTk5MTQ3YjliZDA1ZGIxNWIwMGQ3NDhkIiwiZGdpIjoiODQiLCJleHAiOiIxNTk5MDQwMDU1In0.oJI3ALNteLiC-t4HC0HP_b46uMpRFhyaxnvNmhbyXftWvsqT_Dc4NwWVuU4Bv_WBOgf6uUkjkOXEoF5R7xs4kQ',
    },
    transformRequest: [
      null,
    ],
    transformResponse: [
      null,
    ],
    timeout: 0,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    maxContentLength: -1,
  },
  request: {},
} as any;
