export enum INVESTOR_TYPE {
  YOUNG_INVESTOR = 'YI',
}
export interface Account {
  accountNo: string;
  accountType: string;
  address1: string;
  address2: string;
  address3: string;
  bcan: string;
  birthday: string; // Format will be given in 'YYYYMMDD'
  categoryCode: string;
  cipStatus: string;
  email: string;
  ew8ExpiryDate1: string; // Incorrectly named as ew8 instead of w8
  ew8ExpiryDate2: string; // Incorrectly named as ew8 instead of w8
  gsaStatusFlag: string;
  handphoneNo: string;
  lastUploadedOn: string;
  name: string;
  officePhoneNo: string;
  partyID1: string;
  partyID2: string;
  postalCode: string;
  rdsStatus: string;
  recordStatus: string;
  residencePhoneNo: string;
  saxoId: number | string;
  sipStatus: string;
  traderCode: string;
  unitCode: string;
  youngInvestor: INVESTOR_TYPE.YOUNG_INVESTOR | undefined;
}

export interface UserParticulars {
  accountNo: string;
  accountType: string;
  activationTime: string;
  address1: string;
  address2: string;
  address3: string;
  bcan: string;
  betaTester: string;
  birthday: string;
  categoryCode: string;
  cipStatus: string;
  email: string;
  ew8ExpiryDate1: string; // Incorrectly named as ew8 instead of w8
  ew8ExpiryDate2: string; // Incorrectly named as ew8 instead of w8
  gsaStatusFlag: string;
  handphoneNo: string;
  lastLoginTime: string;
  lastLogoutTime: string;
  lastUploadedOn: string;
  mobileNo: string;
  name: string;
  officePhoneNo: string;
  partyID1: string;
  partyID2: string;
  postalCode: string;
  rdsStatus: string;
  recordStatus: string;
  residencePhoneNo: string;
  saxoId: number | string;
  sipStatus: string;
  traderCode: string;
  unitCode: string;
  userID: string;
  referralCode: string;
  myrInfo? : {
    fundingEnabled: boolean;
    withdrawalEnabled: boolean;
    isResident: boolean | null;
  };
  youngInvestor?: boolean;
  privateWealthAccount: Account;
}
