import { DateTimeService } from '@staizen/graphene';
import { setQueryParams } from '@staizen/utils/dist/URLUtils';
import { AxiosError } from 'axios';

import { store } from '../../../config/configureStore';
import { aggregatePagination } from '../helper';
import { BankAccountType, TicketMap } from '../types';

const { DateTime } = DateTimeService;

interface ResponseItem {
  requestUID: string;
  bankName: string;
  bankAccNo: string;
  bankSwiftCode: string;
  requestStatus: string;
  userId: string;
  lastUpdatedTime: string; //   "lastUpdatedTime": "2020-09-11T04:31:45.273Z",
  requestType: BankAccountType;
}

const getBankAccountTickets = async (status: string = undefined) => {
  const { config } = store.getState();
  const endpointUrl = setQueryParams(config.endpoints.bankAccount.readTickets, { status }, true);
  const response = await (config.useMock ? new Promise((res) => {
    setTimeout(() => {
      res([
        {
          requestUID: 'ba1',
          requestStatus: 'AP',
          userId: 'kev',
          lastUpdatedTime: '2010-04-10T01:33:00.000Z',
          bankName: 'DBS Bank',
          bankAccNo: 'k bank account',
          bankSwiftCode: 'DBSSSG',
          requestType: BankAccountType.Add,
        },
        {
          requestUID: 'ba2',
          requestStatus: 'PE',
          userId: 'kev',
          lastUpdatedTime: '2019-04-10T01:33:00.000Z',
          bankName: 'DBS Bank',
          bankAccNo: 'k bank account',
          bankSwiftCode: 'DBSSSG',
          requestType: BankAccountType.Remove,
        },
        {
          requestUID: 'ba3',
          requestStatus: 'RE',
          userId: 'kev',
          lastUpdatedTime: '2019-04-10T01:33:00.000Z',
          bankName: 'DBS Bank',
          bankAccNo: 'k bank account',
          bankSwiftCode: 'DBSSSG',
          requestType: BankAccountType.Remove,
        },
      ]);
    }, 1000);
  }) : aggregatePagination(endpointUrl).catch((error: AxiosError) => {
    // TODO - handle connection down, leave it loading spinner?

    // no records
    if (error.response && error.response.status === 400 && error.response.data.status === 'NO_CONTENT') {
      // TODO - BE should standardize with withdrawal and deposit to return an empty array
      // BE http 400 error response =    {"status":"NO_CONTENT","message":"No Record Found","timestamp":1600079046297}
      return [];
    }
  })) as ResponseItem[];

  const tickets = response.reduce((acc: TicketMap, item) => {
    acc.index.push(item.requestUID);
    acc.data[item.requestUID] = {
      id: item.requestUID,
      type: 'BankAccount',
      status: item.requestStatus,
      lastUpdated: DateTime.fromISO(item.lastUpdatedTime),
      details: {
        bankName: item.bankName,
        bankAccount: item.bankAccNo,
        swiftCode: item.bankSwiftCode,
        requestType: item.requestType,
      },
    };
    return acc;
  }, { data: {}, index: [] });
  return tickets;
}; // HTTP 400 - bad request if fields are invalid or missing or if empty bankAccount

export default getBankAccountTickets;
