import { DateTimeService } from '@staizen/graphene';
import { ProductID } from '../../templates/Products/constants';

export interface Product {
  boEnabled: boolean;
  saxoEnabled: boolean;
  expiryDate: string | null;
}

export enum ProductEligibilityStatus {
  Ineligible = 'Ineligible',
  Evergreen = 'Evergreen',
  Expiring = 'Expiring',
  Expired = 'Expired',
  Active = 'Active',
}

export interface TransformedProduct extends Omit<Product, 'expiryDate'> {
  id: ProductID;
  expiryDate: DateTimeService.DateTime | null;
  eligibilityStatus: ProductEligibilityStatus;
}

export interface ProductResponse {
  products?: { [key: string]: Product };
  hasError?: boolean;
}

export interface TransformedProductResponse {
  products?: TransformedProduct[];
  error?: boolean;
}

export enum CkacarTicketStatus {
  Pending = 'PE', // initial status upon ticket creation
  Unsuccessful = 'UN', // ticket logged as UN with internal system
  Approved = 'AP', // ticket approved via cgs admin / or automated process
  Rejected = 'RE', // ticket rejected via cgs admin
  NullVoid = 'NV', // ticket forms invalid and failed via cgs admin
}
