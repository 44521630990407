import { store } from '../../config/configureStore';
import { getClientPortalService } from '../../config/configureApi';
import { AxiosInstance, AxiosError, AxiosResponse } from 'axios';

import { CLIENT_PORTAL_ERROR_TYPE } from '../constants';

export function getSaxoSessionToken() {
  const api: AxiosInstance = getClientPortalService();

  const { config } = store.getState();
  if (config.useMock) {
    return new Promise((res) => {
      setTimeout(() => {
        res({
          tokenType: 'Bearer',
          accessToken: 'eyJhbGciOiJFUzI1NiIsIng1dCI6IjhGQzE5Qjc0MzFCNjNFNTVCNjc0M0QwQTc5MjMzNjZCREZGOEI4NTAifQ.eyJvYWEiOiI3Nzc3MCIsImlzcyI6Im9hIiwiYWlkIjoiMTAwNiIsInVpZCI6Ik1SaUFmZjZWbVgzQnFmb2E1NGw5eFE9PSIsImNpZCI6Ik1SaUFmZjZWbVgzQnFmb2E1NGw5eFE9PSIsImlzYSI6IkZhbHNlIiwidGlkIjoiNjAzMSIsInNpZCI6IjhkYWIwMWZjODE1MzQwMDBiNDZmMjk0ODk5ZmM3MWVjIiwiZGdpIjoiODQiLCJleHAiOiIxNTk5MjA0NTY3In0.eAuwBAmbeUZQIPmLbj3_hYq-WlwcF7dmo2fkVrOO2VMJ_LdhzJjnwPZV5HLTq8FCa2l49-4kSPi4BgDmWWw3CA',
          accessTokenExpiry: 1200,
        });
      });
    });
  }
  return api.get(config.endpoints.saxoToken)
    .then((response: AxiosResponse) => {
      return {
        tokenType: response.data.token_type,
        accessToken: response.data.access_token,
        accessTokenExpiry: response.data.expires_in,
      };
    }).catch((error: AxiosError) => {
      // 403 - UserAttribute Not Found
      if (error.response?.status === 500) {
        console.warn('WARN: Saxo token retrieval failed');
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          error: CLIENT_PORTAL_ERROR_TYPE.SAXO_USER_TOKEN_FAIL,
          response: error.response,
        });
      }
      if (error.response?.status === 403) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          error: CLIENT_PORTAL_ERROR_TYPE.SAXO_USER_NOT_FOUND,
          response: error.response,
        });
      }
      return Promise.reject(error);
    });
}
