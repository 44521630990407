export const USER_UPDATE_DISMISSED_PROMPTS = 'USER_UPDATE_DISMISSED_PROMPTS';
export const USER_ADD_DISMISSED_BANNER_ALERTS = 'USER_ADD_DISMISSED_BANNER_ALERTS';
export const USER_SET_DISMISSED_BANNER_ALERTS = 'USER_SET_DISMISSED_BANNER_ALERTS';
export const USER_UPDATE_BANK_ACCOUNTS = 'USER_UPDATE_BANK_ACCOUNTS';
export const USER_SET_BANK_ACCOUNTS = 'USER_SET_BANK_ACCOUNTS';
export const USER_UPDATE_PARTICULARS = 'USER_UPDATE_PARTICULARS';
export const USER_SET_PARTICULARS = 'USER_SET_PARTICULARS';
export const USER_SET_PREFERENCES = 'USER_SET_PREFERENCES';
export const USER_SET_ANNOUNCEMENTS = 'USER_SET_ANNOUNCEMENTS';
export const USER_SET_TICKETS = 'USER_SET_TICKETS'; // Replaces tickets store
export const USER_UPDATE_TICKETS = 'USER_UPDATE_TICKETS'; // Appends to existing tickets store
export const USER_COACH_MARK_UPDATE_IS_READ = 'USER_COACH_MARK_UPDATE_IS_READ';
export const USER_COACH_MARK_UPDATE_IS_STARTED = 'USER_COACH_MARK_UPDATE_IS_STARTED';
export const USER_SET_DASHBOARDS = 'USER_SET_DASHBOARDS';
export const USER_SET_ALL_PREFERENCES = 'USER_SET_ALL_PREFERENCES';
