import { store } from '../../../config/configureStore';
import { getClientPortalService } from '../../../config/configureApi';
import { AxiosError, AxiosResponse } from 'axios';
import { FundingType } from '../types';
import { DEPOSIT_MOCK_ERROR_AMOUNT, getMockQrCode, qrImagePrefix } from './utils';

export interface AddDepositTicketParams {
  accountNo: string;
  amount: number;
  currency: string;
  fundingType: 'UEN' | 'PNQR' | 'FAST' | 'TT' | FundingType;
  name: string;
  reference?: string; // Only for paynow (uen), fast and tt
  proofDoc?: Blob[] | File[]; // Only for tt
  transactionId?: string; // For renewing qr code
}

interface AddDepositTicketResponse {
  requestUID?: string;
  qr?: string;
  message?: string;
}

export interface TransformedAddDepositTicketResponse {
  transactionId?: string;
  qr?: string;
  error?: string; // mocked response for now for failed requests
  errorCode?: string;
}

const addDepositTicket = async (
  params: AddDepositTicketParams,
): Promise<TransformedAddDepositTicketResponse> => {
  // FundingJsonRequest {
  //   accountNo -> string
  //   amount -> number($double)
  //   currency -> string
  //   fundingType -> string
  //   name -> string
  //   proofDoc -> string
  //   referenceNo -> string
  //   userID -> string
  // }

  const { config } = store.getState();
  const api = getClientPortalService();

  if (config.useMock) {
    try {
      const response: Omit<AxiosResponse<AddDepositTicketResponse>, 'headers' | 'config'> = await new Promise((res, rej) => {
        setTimeout(() => { // Timeout for delay
          if (params.amount === DEPOSIT_MOCK_ERROR_AMOUNT) { // Hardcoded to trigger fail flow whenever amount is === DEPOSIT_MOCK_ERROR_AMOUNT
            // eslint-disable-next-line prefer-promise-reject-errors
            rej({
              response: {
                status: 400,
                statusText: 'Bad Request',
                data: {
                  message: '[reason for the fail]',
                },
              },
            });
          }
          res({
            status: 200,
            statusText: 'OK',
            data: {
              requestUID: 'mock transaction id',
              qr: params.fundingType === FundingType.QR ? getMockQrCode() : null,
            },
          });
        }, 1000);
      });

      return {
        transactionId: response.data.requestUID,
        qr: response.data.qr ? qrImagePrefix + response.data.qr : undefined,
      };
    } catch (error: any) {
      const { response } = error;
      const errorMessage = response?.data?.message;
      return { error: errorMessage, errorCode: String(response.status) };
    }
  }

  const formData = new FormData();
  if (params.proofDoc?.length > 0) { // TT only
    const [proofDoc] = params.proofDoc;
    if (proofDoc instanceof File) {
      formData.append('proofDoc', proofDoc);
    } else {
      const type = proofDoc.type?.split('/')?.[1] || '';
      const fileName = type ? `proofDoc.${type}` : 'proofDoc';
      formData.append('proofDoc', proofDoc, fileName);
    }
  }

  const funding = JSON.stringify({
    accountNo: params.accountNo,
    amount: params.amount,
    currency: params.currency,
    fundingType: params.fundingType,
    name: params.name,
    referenceNo: params.reference,
  });
  formData.append('funding', new Blob([
    funding,
  ], {
    type: 'application/json',
  }));

  const response = await api({
    method: 'post',
    url: config.endpoints.funding.createTicket,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then((apiResponse: AxiosResponse) => {
    return {
      transactionId: apiResponse.data.requestUID,
      qr: apiResponse.data.qr ? qrImagePrefix + apiResponse.data.qr : undefined,
    };
  }).catch((error: AxiosError) => {
    const { response: apiResponse } = error;
    // TODO handle internet connection down
    // TODO HTTP 400, invalid or missing fields
    // TODO HTTP 500 with no response body when sent a qr payload
    if (apiResponse && apiResponse.data) {
      console.error(apiResponse.data.message); // Should mention which field was invalid
    }
    return { // apiResponse.data.message === <error message specific to the invalid field(s)>
      error: 'REJECTED',
      errorCode: String(apiResponse.status),
    };
  });

  return response;
};

export default addDepositTicket;
