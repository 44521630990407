import { TicketType } from './types';

import getFundingTicket from './funding/getFundingTicket';
import getBankAccountTicket from './bankAccount/getBankAccountTicket';
import getWithdrawalTicket from './withdrawal/getWithdrawalTicket';
import getCkacarTicket from './ckacar/getCkacarTicket';
import getRewardsDetail from '../rewards/getRewardsDetail';

// bank account ticket
export { default as addBankAccountTicket } from './bankAccount/addBankAccountTicket';
export { default as removeBankAccountTicket } from './bankAccount/removeBankAccountTicket';
export { getBankAccountTicket };

// funding ticket
export { default as addDepositTicket } from './funding/addDepositTicket';
export { getFundingTicket };
export { getWithdrawalTicket };

// cka
export { getCkacarTicket };

// get tickets
export { default as getFundingTickets } from './funding/getFundingTickets';
export { default as getWithdrawalTickets } from './withdrawal/getWithdrawalTickets';
export { default as getBankAccountTickets } from './bankAccount/getBankAccountTickets';
export { default as getCkacarTickets } from './ckacar/getCkacarTickets';
export { default as getRewardsHistory } from '../rewards/getRewardsHistory';

export const getTicketStatusHistory = async (id: string, type: TicketType | string) => {
  switch (type) {
    case TicketType.Funding:
      return getFundingTicket(id);
    case TicketType.Withdrawal:
      return getWithdrawalTicket(id);
    case TicketType.BankAccount:
      return getBankAccountTicket(id);
    case TicketType.Ckacar:
      return getCkacarTicket(id);
    case TicketType.Rewards:
      return getRewardsDetail(id);
    default:
      return null;
  }
};
