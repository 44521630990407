/* eslint-disable operator-linebreak */
import { AxiosInstance, AxiosError, AxiosResponse } from 'axios';
import { store } from '../../config/configureStore';
import { getSaxoService } from '../../config/configureApi';
import { CashAvailableForWithdrawal } from './types';
import { getQueryParams } from '@staizen/utils/dist/URLUtils';

interface AvailableWithdrawalsResponse {
  AccountKey: string;
  ClientKey: string;
  MaxAllowedWithdrawalAmount: number;
}

export async function getAvailableWithdrawals(clientKey: string, accountKey: string) {
  const api: AxiosInstance = getSaxoService();
  const { config } = store.getState();
  const queryParams = getQueryParams(window.location.search, true) as { mockCurrencyAccountsError?: string };
  if (config.useMock && queryParams.mockCurrencyAccountsError === 'true') {
    return { error: true };
  }

  const response = await ( // eslint-disable-next-line no-nested-ternary
    config.useMock
      ? (mockCurrencyAccountsResponses[accountKey] || mockCurrencyAccountsResponses.ak1)
      : api.get(
        config.endpoints.saxo.withdrawalLimits,
        { params: { ClientKey: clientKey, AccountKey: accountKey } },
      ).then((apiResponse: AxiosResponse) => {
        return apiResponse.data.Data[0] as AvailableWithdrawalsResponse;
      }).catch((error: AxiosError) => {
        console.error(error);
      }));

  return {
    cashAvailableForWithdrawal: response && response.MaxAllowedWithdrawalAmount,
  } as CashAvailableForWithdrawal;
}

const mockCurrencyAccountsResponses: { [key: string]: AvailableWithdrawalsResponse } = {
  ak1: {
    AccountKey: 'sUyiJbnf57ayjlcoNp1RkA==',
    ClientKey: 'W-CgwnsUEpETkiR8MPJalA==',
    MaxAllowedWithdrawalAmount: 5000.0,
  },
  ak2: {
    AccountKey: 'sUyiJbnf57ayjlcoNp1RkA==',
    ClientKey: 'W-CgwnsUEpETkiR8MPJalA==',
    MaxAllowedWithdrawalAmount: 3000.0,
  },
  ak3: {
    AccountKey: 'sUyiJbnf57ayjlcoNp1RkA==',
    ClientKey: 'W-CgwnsUEpETkiR8MPJalA==',
    MaxAllowedWithdrawalAmount: 2000.0,
  },
};
