import { useEffect, useState } from 'react';
import { Capacitor, Plugins } from '@capacitor/core';

const { CapacitorRootJailbreakDetector } = Plugins;

const useAppBlocker = (): { appAllowed: boolean } => {
  const [appAllowed, setAppAllowed] = useState(true);

  async function checkRootStatus() {
    try {
      const result = await CapacitorRootJailbreakDetector.isRooted();
      setAppAllowed(!result.isRooted);
    } catch (error) {
      console.error('Error checking rooted status:', error);
    }
  }

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      // Call the function to check rooted status
      checkRootStatus();
    }
  }, []);

  return { appAllowed };
};

export default useAppBlocker;
