import { i18n } from '@staizen/graphene';
import { getQueryParams } from '@staizen/utils/dist/URLUtils';

import { initReactI18next } from 'react-i18next';

function configureI18n(defaultLocale: string): Promise<any> {
  const obj = getQueryParams(window.location.href, false) as { lang: string };
  const nextDefaultLocale = obj.lang ?? defaultLocale;

  return i18n.use(initReactI18next).init({
    lng: nextDefaultLocale,
    fallbackLng: 'en',
    debug: true,
    supportedLngs: ['en', 'zh-CN'],
    // Only load the current language code, will not attempt to load all related language codes.
    // With the default value of `load: "all"`, it will attempt to load the "zh" locale for "zh-CN" and hence displaying error messages.
    load: 'currentOnly',
  });
}

export { configureI18n };
