import { DateTimeService } from '@staizen/graphene';
import set from 'lodash/set';
import { PersistState } from 'redux-persist';
import { Dashboard as DashboardType } from '@staizen/graphene/dist/types/components/stz-dashboard/typing';

import {
  USER_SET_BANK_ACCOUNTS,
  USER_SET_PARTICULARS,
  USER_SET_PREFERENCES,
  USER_UPDATE_DISMISSED_PROMPTS,
  USER_SET_TICKETS,
  USER_UPDATE_TICKETS,
  USER_UPDATE_BANK_ACCOUNTS,
  USER_UPDATE_PARTICULARS,
  USER_COACH_MARK_UPDATE_IS_READ,
  USER_COACH_MARK_UPDATE_IS_STARTED,
  USER_SET_DASHBOARDS,
  USER_SET_ALL_PREFERENCES,
  USER_ADD_DISMISSED_BANNER_ALERTS,
  USER_SET_DISMISSED_BANNER_ALERTS,
  USER_SET_ANNOUNCEMENTS,
} from '../constants/user';
import { store } from '../../config/configureStore';
import { createRootReducer } from '../../config/configureRootReducer';
import { BankAccount } from '../../api/bankAccount/types';
import { TicketMap } from '../../api/ticket/types';
import { getFundingTickets, getWithdrawalTickets, getBankAccountTickets, getCkacarTickets, getRewardsHistory } from '../../api/ticket';
import { getBankAccounts } from '../../api/bankAccount';
import { getParticulars } from '../../api/userParticulars';
import { Account } from '../../api/userParticulars/types';
import getUserPreferences from '../../api/userPreferences/getUserPreferences';
import saveUserPreference from '../../api/userPreferences/saveUserPreference';
import { setPreferencesMeta } from './meta';
import { LINKS } from '../../config/configureLinks';

export interface DismissedPromptDetails {
  lastDismissed: string;
  noPrompt?: boolean;
}

export enum AllStatus {
  UNDECLARED = 'undeclared',
  PENDING = 'pending',
  EXPIRED = 'expired',
  EXPIRING = 'expiring',
  VALID = 'valid',
  NO_EXPIRY = 'no_expiry'
}

export enum W8Status {
  UNDECLARED = 'undeclared',
  PENDING = 'pending', // pending manual verification that W8 has been updated
  EXPIRED = 'expired',
  EXPIRING = 'expiring',
  VALID = 'valid',
  UNREADY = 'unready',
}

export enum PreferenceType {
  Dashboards = 'dashboards',
  CoachMark = 'coachMark',
  DismissedPrompts = 'dismissedPrompts',
  Preferences = 'preferences',
  Announcements = 'announcements',
}

export interface TransformedUserParticulars {
  accountNo: string;
  accountType: string;
  activationTime: string;
  address1: string;
  address2: string;
  address3: string;
  bcan: string;
  betaTester: string | undefined;
  birthday: DateTimeService.DateTime | null;
  categoryCode: string;
  cipStatus: string;
  email: string;
  w8ExpiryDate1: DateTimeService.DateTime | null;
  w8ExpiryDate2: DateTimeService.DateTime | null;
  w8Status: W8Status;
  gsaStatusFlag: string;
  handphoneNo: string;
  lastLoginTime: string;
  lastLogoutTime: string;
  lastUploadedOn: string;
  mobileNo: string;
  name: string;
  officePhoneNo: string;
  partyID1: string;
  partyID2: string;
  postalCode: string;
  rdsStatus: string;
  recordStatus: string;
  residencePhoneNo: string;
  referralCode: string;
  saxoId?: number | string;
  hasTradingAccountLinked?: boolean;
  sipStatus: string;
  traderCode: string;
  unitCode: string;
  userID: string;
  myrInfo?: {
    fundingEnabled?: boolean;
    withdrawalEnabled?: boolean;
    isResident?: boolean | null;
  };
  youngInvestor?: boolean; // TODO: to check with Fulton
  privateWealthAccount: Account;
}

export interface UserStore {
  bankAccounts: {
    data: BankAccount[];
  } & UiState;
  dismissedPrompts: {
    acceptedCookie: DismissedPromptDetails;
    coachMarks: DismissedPromptDetails;
    noBankAccount?: DismissedPromptDetails;
    prefs?: DismissedPromptDetails;
    w8?: DismissedPromptDetails;
    w8Declaration?: DismissedPromptDetails;
  };
  dismissedBannerAlerts: string[];
  dashboards: {
    data: DashboardType[];
    version?: number;
  };
  particulars: {
    data: TransformedUserParticulars;
  } & UiState;
  preferences: UserPreferences;
  announcements: UserAnnouncements;
  tickets: TicketMap & UiState;
  coachMark: {
    isStarted: boolean;
    isRead: {
      home: boolean;
      account: {
        profile: boolean;
        funding: boolean;
      };
    };
  };
  _persist: PersistState;
}

export interface UserPreferences {
  [key: string]: string | number | object;
}
export interface UserAnnouncements {
  readAnnouncementIds: Array<string>;
}

const updateUserParticulars = (userParticulars: Partial<UserStore['particulars']>) => {
  return {
    type: USER_UPDATE_PARTICULARS,
    payload: userParticulars,
  };
};

const setUserParticulars = (userParticulars: UserStore['particulars']) => {
  return {
    type: USER_SET_PARTICULARS,
    payload: userParticulars,
  };
};

export const getUserParticulars = () => async (dispatch: Function) => {
  dispatch(updateUserParticulars({ ui: { isFetching: true } }));
  try {
    const userParticulars = await getParticulars();
    store.replaceReducer(createRootReducer(userParticulars.userID));
    return dispatch(setUserParticulars({ data: userParticulars, ui: { isFetching: false } }));
  } catch (_) {
    return dispatch(updateUserParticulars({ ui: { isFetching: false, isError: true } }));
  }
};

export function setUserPreferences(data: UserPreferences): Action {
  return {
    type: USER_SET_PREFERENCES,
    payload: {
      ...data,
    },
  };
}

export const saveAndSetPreferences = (data: UserPreferences) => async (dispatch: Function) => {
  await dispatch(setUserPreferences(data));
  return saveUserPreference({ key: PreferenceType.Preferences });
};

export const setAnnouncements = (data: UserAnnouncements): Action => {
  return {
    type: USER_SET_ANNOUNCEMENTS,
    payload: {
      ...data,
    },
  };
};

export const setAndSaveAnnouncements = (data: UserAnnouncements) => async (dispatch: Function) => {
  await dispatch(setAnnouncements(data));
  return saveUserPreference({ key: PreferenceType.Announcements });
};

export const loadAllUserPreferences = () => async (dispatch: Function) => {
  try {
    const prefsMap = await getUserPreferences();
    if (prefsMap && Object.keys(prefsMap).length === 0) {
      return dispatch(setPreferencesMeta({
        migratePreferences: true,
        loadedPreferences: true,
      }));
    }
    if (!prefsMap) {
      console.error('ERROR: Unable to fetch user preferences: prefsMap undefined');
      return Promise.resolve(null);
    }
    await dispatch(setAllUserPreferences({ ...prefsMap }));
    return dispatch(setPreferencesMeta({
      migratePreferences: false,
      loadedPreferences: true,
    }));
  } catch (error) {
    console.error('ERROR: Unable to fetch user preferences: ', error);
  }
};

export function setAllUserPreferences(data: any): Action {
  return {
    type: USER_SET_ALL_PREFERENCES,
    payload: {
      ...data,
    },
  };
}

const updateBankAccounts = (payload: Partial<UserStore['bankAccounts']>) => {
  return {
    type: USER_UPDATE_BANK_ACCOUNTS,
    payload,
  };
};

const setBankAccounts = (payload: UserStore['bankAccounts']) => {
  return {
    type: USER_SET_BANK_ACCOUNTS,
    payload,
  };
};

export const getUserBankAccounts = () => async (dispatch: Function) => {
  dispatch(updateBankAccounts({ ui: { isFetching: true } }));
  try {
    const { data: bankAccounts, error } = await getBankAccounts();
    return dispatch(setBankAccounts({
      data: bankAccounts,
      ui: { isFetching: false, isError: error },
    }));
  } catch (_) {
    return dispatch(updateBankAccounts({ ui: { isFetching: false, isError: true } }));
  }
};

export const setDashboards = (payload: UserStore[PreferenceType.Dashboards]) => {
  return ({
    type: USER_SET_DASHBOARDS,
    payload,
  });
};

export const saveAndSetDashboards = (
  payload: UserStore[PreferenceType.Dashboards],
) => async (dispatch: Function) => {
  await dispatch(setDashboards(payload));
  return saveUserPreference({ key: PreferenceType.Dashboards });
};

const updateUserDismissedPrompt = (
  dismissedPrompts: Partial<UserStore[PreferenceType.DismissedPrompts]>,
) => {
  return ({
    type: USER_UPDATE_DISMISSED_PROMPTS,
    payload: dismissedPrompts,
  });
};

export const updateDismissedBannerAlerts = (dismissedBannerAlertId: string) => {
  return ({
    type: USER_ADD_DISMISSED_BANNER_ALERTS,
    payload: dismissedBannerAlertId,
  });
};

export const setDismissedBannerAlerts = (payload: UserStore['dismissedBannerAlerts']) => {
  return {
    type: USER_SET_DISMISSED_BANNER_ALERTS,
    payload,
  };
};

export const saveAndAddUserDismissedPrompt = (
  dismissedPrompts: Partial<UserStore[PreferenceType.DismissedPrompts]>,
) => async (dispatch: Function) => {
  await dispatch(updateUserDismissedPrompt(dismissedPrompts));
  return saveUserPreference({ key: PreferenceType.DismissedPrompts });
};

const updateAllTickets = (payload: Partial<UserStore['tickets']>) => {
  return {
    type: USER_UPDATE_TICKETS,
    payload,
  };
};

const setAllTickets = (payload: Partial<UserStore['tickets']>) => {
  return {
    type: USER_SET_TICKETS,
    payload,
  };
};

export const getAllTickets = () => (dispatch: Function) => {
  dispatch(updateAllTickets({ ui: { isFetching: true } }));
  return Promise.all([
    getFundingTickets(),
    getWithdrawalTickets(),
    getBankAccountTickets(),
    getCkacarTickets(),
    getRewardsHistory(),
  ])
    .then((ticketResponses) => {
      // sort the tickets
      const ticketMap: TicketMap = {
        data: {
          ...ticketResponses[0].data,
          ...ticketResponses[1].data,
          ...ticketResponses[2].data,
          ...ticketResponses[3].data,
          ...ticketResponses[4].data,
        },
        index: [
          ...ticketResponses[0].index,
          ...ticketResponses[1].index,
          ...ticketResponses[2].index,
          ...ticketResponses[3].index,
          ...ticketResponses[4].index,
        ],
      };
      ticketMap.total = ticketMap.index.length;
      ticketMap.order = ticketMap.index.sort((o1, o2) => { // sort from latest to oldest updated
        return ticketMap.data[o1].lastUpdated > ticketMap.data[o2].lastUpdated ? -1 : 1;
      });

      return dispatch(setAllTickets(ticketMap));
    }).catch((e) => {
      console.error(e);
      return dispatch(updateAllTickets({ ui: { isError: true } }));
    });
};

export const getUserCoachMarkIsReadPathKey = (pathname: string) => {
  switch (pathname) {
    case LINKS.ROOT:
    case LINKS.DASHBOARD:
      return 'home';
    case LINKS.ACCOUNT:
    case LINKS.ACCOUNT_PROFILE:
      return 'account';
    case LINKS.FUNDING:
    case LINKS.FUNDING_MANAGE:
      return 'funding';
    default:
      return '';
  }
};

const getUserCoachMarkUpdateIsReadObject = (isRead: boolean, pathname: string) => {
  const pathKey = getUserCoachMarkIsReadPathKey(pathname);
  if (!pathKey) {
    return;
  }
  return set({}, pathKey, isRead);
};

export function updateUserCoachMarkIsRead(isRead: boolean, pathname: string): Action {
  return {
    type: USER_COACH_MARK_UPDATE_IS_READ,
    payload: {
      isRead: getUserCoachMarkUpdateIsReadObject(isRead, pathname),
    },
  };
}

export const saveAndUpdateUserCoachMarkIsRead = (isRead: boolean,
  pathname: string) => async (dispatch: Function) => {
  await dispatch(updateUserCoachMarkIsRead(isRead, pathname));
  return saveUserPreference({ key: PreferenceType.CoachMark });
};

export function updateUserCoachMarkIsStarted(isStarted: boolean): Action {
  return {
    type: USER_COACH_MARK_UPDATE_IS_STARTED,
    payload: {
      isStarted,
    },
  };
}
