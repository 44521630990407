import { store } from '../../config/configureStore';
import { getClientPortalService } from '../../config/configureApi';
import { AxiosInstance } from 'axios';

interface GetW8LinkResponse {
  data: string;
}

const MOCK_W8_LINK = 'https://www.cgs-cimb.com/en/home';

const getMockResponse = () => {
  return new Promise((res) => {
    setTimeout(() => {
      res({ data: MOCK_W8_LINK });
    }, 1000);
  });
};

const getApiResponse = (api: AxiosInstance, config: Config) => {
  return api.get(config.endpoints.w8.getLink);
};

const getEw8Link = async (): Promise<string | null> => {
  const { config } = store.getState();
  const api = getClientPortalService();
  try {
    const response: GetW8LinkResponse = await (config.useMock
      ? getMockResponse()
      : getApiResponse(api, config)
    ) as GetW8LinkResponse;
    return response?.data;
  } catch (e) {
    console.error(e);
  }
};

export default getEw8Link;
