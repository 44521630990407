import { store } from '../../config/configureStore';
import { getClientPortalService } from '../../config/configureApi';
import { AxiosInstance, AxiosResponse } from 'axios';

interface GetUserParticularsResponse {
  propertyValue: string;
}

export async function getLoginUserIdPolicy() {
  const api: AxiosInstance = getClientPortalService();

  const { config } = store.getState();

  const response = await (config.useMock
    ? Promise.resolve({
      data: {
        propertyValue: '^[a-zA-Z0-9\\-\\.\\_]{6,20}$',
      },
    })
    : api.get(config.endpoints.validation.login.userId));

  return {
    loginUserIdValidation: (response as AxiosResponse<GetUserParticularsResponse>)?.data?.propertyValue || '',
  };
}

// {"propertyKey":"userid.policy.format","propertyHost":"ALL","propertyDescription":"User ID policy format","propertyValue":"^[a-zA-Z0-9\\-\\.\\_]{6,20}$","lastUpdatedTime":1602147556680}
