import { AxiosInstance, AxiosError, AxiosResponse } from 'axios';
import { store } from '../../config/configureStore';
import { getSaxoService } from '../../config/configureApi';
import { setSaxoAccounts } from '../../actions/creators/saxo';

export function getAccounts() {
  const api: AxiosInstance = getSaxoService();
  const { config } = store.getState();
  const { saxo } = store.getState();
  if (saxo.accounts) {
    return saxo.accounts;
  }
  const response = config.useMock
    ? new Promise((res) => {
      return setTimeout(() => {
        store.dispatch(setSaxoAccounts(mockResponse.Data));
        return res(mockResponse.Data);
      }, 1000);
    })
    : api.get(config.endpoints.saxo.accounts)
      .then((apiResponse: AxiosResponse) => {
        store.dispatch(setSaxoAccounts(apiResponse.data.Data));
        return apiResponse.data.Data;
      }).catch((error: AxiosError) => {
        console.error(error);
        return error;
      });

  return response;
}

const mockResponse = {
  Data: [
    {
      AccountGroupKey: 'MRiAff6VmX3Bqfoa54l9xQ==',
      AccountId: 'Demo_12612501',
      AccountKey: 'mkDQYwdByur4xT4nk0LjSA==',
      AccountSubType: 'None',
      AccountType: 'Normal',
      Active: true,
      CanUseCashPositionsAsMarginCollateral: false,
      ClientId: '12612501',
      ClientKey: 'MRiAff6VmX3Bqfoa54l9xQ==',
      CreationDate: '2020-07-14T02:14:41.757000Z',
      Currency: 'EUR',
      CurrencyDecimals: 2,
      DirectMarketAccess: false,
      IndividualMargining: false,
      IsCurrencyConversionAtSettlementTime: true,
      IsMarginTradingAllowed: true,
      IsShareable: false,
      IsTrialAccount: true,
      LegalAssetTypes: [],
      MarginCalculationMethod: 'Default',
      Sharing: [
        'NoSharing',
      ],
      SupportsAccountValueProtectionLimit: false,
      UseCashPositionsAsMarginCollateral: true,
    },
  ],
} as any;
