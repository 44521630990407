import { useState } from 'react';
import useTimeout from './useTimeout';
import { DateTimeService } from '@staizen/graphene';

const { DateTime } = DateTimeService;

const DEFAULT_DELAY = 1000;

interface Countdown {
  timeGiven: number;
  onExpire?: () => void;
}

interface CountdownReturn {
  timeLeft: number;
  start: () => void;
  stop: () => void;
}

type UseCountDownFn = (props: Countdown) => CountdownReturn;

/**
 * countdown to a time based on time given in seconds
 * @param {number} timeGiven - time given in seconds
 * @param {number} [onExpire] - optional function to call on time expire
 * @returns {number} timeLeft - time left in seconds
 * @returns start - function to start countdown
 */
const useCountdown: UseCountDownFn = ({ timeGiven, onExpire }) => {
  const [timeLeft, setTimeLeft] = useState(timeGiven);
  const [expired, setExpired] = useState(null);
  const [isRunning, setIsRunning] = useState(false);

  const start = () => {
    const expiredDateTime = DateTime.fromJSDate(new Date()).plus({
      seconds: timeGiven,
    });
    setIsRunning(true);
    setExpired(expiredDateTime);
    setTimeLeft(timeGiven);
  };

  const stop = () => {
    setIsRunning(false);
  };

  const handleExpired = () => {
    setIsRunning(false);
    if (typeof onExpire === 'function') {
      onExpire();
    }
  };

  useTimeout(
    () => {
      const currentTime = DateTime.fromJSDate(new Date());
      if (timeLeft > 0 && expired) {
        const seconds = Math.max(
          Math.round(expired.diff(currentTime, 'seconds').seconds),
          0,
        );
        setTimeLeft(seconds);
      } else {
        handleExpired();
      }
    },
    isRunning ? DEFAULT_DELAY : null,
    timeLeft,
  );

  return { timeLeft, start, stop };
};

export default useCountdown;
