import { SAXO_SET_STATE, SAXO_UPDATE_CURRENCY_ACCOUNTS } from '../constants/saxo';
import { getSaxoSessionToken } from '../../api/auth';
import { getCurrencyAccounts } from '../../api/saxo';
import { CurrencyAccount } from '../../api/saxo/types';

export interface SaxoStore {
  auth: {
    accessToken: string;
    accessTokenExpiry: number;
    tokenType: string;
    refreshToken: string;
    refreshTokenExpiry: number;
  };
  currencyAccounts: { data: {
    total: CurrencyAccount;
    groups: CurrencyAccount[];
  }; } & UiState;
  accounts: any;
  clientKey: string | undefined;

}

export function setSaxoToken(token: any): Action {
  return {
    type: SAXO_SET_STATE,
    payload: {
      auth: token,
    },
  };
}

export function setSaxoAccounts(accounts: object[]): Action {
  return {
    type: SAXO_SET_STATE,
    payload: {
      accounts,
    },
  };
}

export function setSaxoCurrencyAccounts(payload: Partial<SaxoStore['currencyAccounts']>): Action {
  return {
    type: SAXO_SET_STATE,
    payload: {
      currencyAccounts: payload,
    },
  };
}

export function updateSaxoCurrencyAccounts(payload: Partial<SaxoStore['currencyAccounts']>): Action {
  return {
    type: SAXO_UPDATE_CURRENCY_ACCOUNTS,
    payload: {
      currencyAccounts: payload,
    },
  };
}

export function setSaxoClientKey(clientKey: string): Action {
  return {
    type: SAXO_SET_STATE,
    payload: {
      clientKey,
    },
  };
}

export const getSaxoToken = () => (dispatch: Function) => {
  dispatch(setSaxoToken({ accessToken: undefined, refreshToken: undefined })); // clear previous token
  return getSaxoSessionToken()
    .then((token: any) => {
      return dispatch(setSaxoToken(token));
    });
};

export const getSaxoCurrencyAccounts = () => (dispatch: Function) => {
  dispatch(updateSaxoCurrencyAccounts({ ui: { isFetching: true } }));
  return getCurrencyAccounts()
    .then((currencyAccounts: any) => {
      const error = currencyAccounts?.error;
      return dispatch(setSaxoCurrencyAccounts({
        data: currencyAccounts,
        ui: { isFetching: false, isError: Boolean(error) },
      }));
    })
    .catch(() => {
      dispatch(updateSaxoCurrencyAccounts({ ui: { isFetching: false, isError: true } }));
    });
};
