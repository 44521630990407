import { useEffect, useRef } from 'react';

type Fn = () => any;

const useTimeout = (callback: any, delay: number, nextTick: number) => {
  const callbackRef = useRef<Fn>();

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    if (!delay) {
      return;
    }

    const timeout = setTimeout(callbackRef.current, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [delay, nextTick]);
};

export default useTimeout;
