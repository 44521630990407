import React, { createContext, useContext, useState, useMemo } from 'react';

interface Props {
  children: React.ReactNode;
}

export const HasCoachMarksContext = createContext(null);

export const HasCoachMarksProvider = ({ children }: Props) => {
  const [hasCoachMarks, setHasCoachMarks] = useState(false);
  const value = useMemo(() => ({ hasCoachMarks, setHasCoachMarks }), [hasCoachMarks, setHasCoachMarks]);

  return (
    <HasCoachMarksContext.Provider value={value}>
      {children}
    </HasCoachMarksContext.Provider>
  );
};

/* Usage
const { hasCoachMarks, setHasCoachMarks } = useHasCoachMarks();

// Setter
useEffect(() => {
  setHasCoachMarks(condition ? true : false);
}, [hasCoachMarkDep]);

// Getter
return hasCoachMarks && ( ... );
*/
export const useHasCoachMarks = () => {
  const context = useContext(HasCoachMarksContext);
  if (context === null) {
    throw new Error('useHasCoachMarks must be used within HasCoachMarksProvider');
  }
  return context;
};
