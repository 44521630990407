import { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { h } from '@staizen/graphene';
// Referenced from https://revolist.github.io/revogrid/guide/framework.react.component.html
// Workaround for render function passed into Graphene's component to work successfully
type OptionsType = {
  wrapperElement?: string;
  wrapper?: HTMLElement;
  unmount?: boolean;
};

export const stencilRenderAdapter = (
  parent: any,
  toRender: ReactElement,
  options: OptionsType = {},
) => {
  const { wrapperElement, wrapper } = options;
  let nextWrapper;

  if (!parent) {
    // TODO: Investigate potential memory leak
    return;
  }

  if (parent.childNodes.length) {
    const { childNodes } = parent;
    [nextWrapper] = childNodes;
    if (options.unmount !== false) {
      // TODO: Investigate potential memory leak
      ReactDOM.unmountComponentAtNode(nextWrapper);
    }
  } else {
    nextWrapper = wrapper ?? document.createElement(wrapperElement ?? 'span');
    parent.appendChild(nextWrapper);
  }
  ReactDOM.render(toRender, nextWrapper);
  return parent;
};

export const stencilRender = (element: any, options: OptionsType = {}) => {
  return (h as any)('span', {
    ref: (el: any) => {
      return stencilRenderAdapter(el, element, options);
    },
  });
};
