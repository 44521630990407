// import { AxiosResponse } from 'axios';
import { store } from '../../../config/configureStore';
import { getClientPortalService } from '../../../config/configureApi';
import { AxiosError } from 'axios';
import { OTPError, OTPErrorResponse } from '../../auth/types';

// "Request rejected"
interface AddBankAccountTicketParams {
  country: string;
  bankName: string;
  accountNo: string;
  swiftCode: string;
  proofDoc: Blob[] | File[];
  twoFAData: string;
  challenge: string;
}

// Response shape of received data
// interface AddBankAccountTicketResponse {
//   message: string;
// };

export interface TransformedAddBankAccountTicketResponse {
  success: boolean;
  error?: string;
  errorCode?: string;
}

const addBankAccount = async (
  params: AddBankAccountTicketParams,
): Promise<TransformedAddBankAccountTicketResponse> => {
  const { config } = store.getState();
  const api = getClientPortalService();
  if (config.useMock) {
    await new Promise((res) => setTimeout(res, 1000)); // Timeout for delay
    const { twoFAData } = params;
    // Done with assumption that success === true when OTP passes, and reason filled up if add bank account fails
    if (twoFAData === '000000') {
      return { success: true };
    }
    if (twoFAData === '111111') {
      return { success: true, error: 'REJECTED' };
    }

    if (twoFAData === '333333') {
      return { success: false, error: OTPError.CHALLENGE_CANCELLED };
    }

    return {
      success: false,
      error: OTPError.INVALID_OTP,
      errorCode: '400',
    };
  }
  const formData = new FormData();

  const [proofDoc] = params.proofDoc;
  if (proofDoc instanceof File) {
    formData.append('proofDoc', proofDoc);
  } else {
    const type = proofDoc.type?.split('/')?.[1] || '';
    const proofDocFileName = type ? `proofDoc.${type}` : 'proofDoc';
    formData.append('proofDoc', proofDoc, proofDocFileName);
  }

  const bankAccountGenericRequest = JSON.stringify({
    bankAccNo: params.accountNo,
    bankName: params.bankName,
    bankSwiftCode: params.swiftCode,
    challenge: params.challenge,
    country: params.country,
    twoFAData: params.twoFAData,
  });
  formData.append('bankAccountGenericRequest', new Blob([
    bankAccountGenericRequest,
  ], {
    type: 'application/json',
  }));

  const response = await api({
    method: 'post',
    url: config.endpoints.bankAccount.createAddTicket,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(() => {
    return {
      success: true,
    };
  }).catch((error: AxiosError) => {
    // TODO handle internet connection down
    // TODO handle backend unhandled - exception HTTP status 415 - db failed to insert
    // Pending BE to return proper invalid sms msg - TODO handle service unavailable - http status 503 - {"status":"SERVICE_UNAVAILABLE","message":"error.smsotp.invalid","timestamp":1600772323831}
    // apiResponse.data.message === 'Request rejected'
    const { response: apiResponse } = error;
    const errorCode = String(apiResponse.status);
    let success = true;
    let errorStatus = 'REJECTED';
    if (apiResponse && apiResponse.status === 400) {
      if (apiResponse?.data?.message === OTPErrorResponse.INVALID_OTP) {
        success = false;
        errorStatus = OTPError.INVALID_OTP;
      } else if (apiResponse?.data?.message === OTPErrorResponse.CHALLENGE_CANCELLED) {
        success = false;
        errorStatus = OTPError.CHALLENGE_CANCELLED;
      }
    }
    return {
      success,
      error: errorStatus,
      errorCode,
    };
  });
  return response;
};

export default addBankAccount;
