import { createStore, Store } from 'redux';
import { createRootReducer } from './configureRootReducer';
import { middleware } from './configureMiddleware';
import { persistStore, Persistor, PersistorOptions } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';

// eslint-disable-next-line import/no-mutable-exports
export let store: Store = null;
// eslint-disable-next-line import/no-mutable-exports
export let persistor: Persistor = null;

export const configureStore = (preloadedState?: object): Store => {
  store = createStore(
    createRootReducer(),
    preloadedState,
    composeWithDevTools(middleware()),
  );
  // only persist after token is retrieved to effective reconcile fetched token with that in session storage
  persistor = persistStore(store, { manualPersist: true } as PersistorOptions); // leaving option here in case we need to debug persist
  return store;
};
