import {
  MENU_SET_ITEMS,
} from '../constants/menu';
import { MenuStore } from '../../config/configureMenu';

export const setMenu = (menu: MenuStore) => {
  return {
    type: MENU_SET_ITEMS,
    payload: menu,
  };
};
