import React from 'react';
import { useStore } from 'react-redux';
import { faDownload } from '@staizen/graphene/dist/icons/regular';
import { registerLocaleLoader } from '@staizen/graphene';
import { StzStatus, StzButton, StzIcon } from '@staizen/graphene-react';
import { PreNormalizedIcon } from '@staizen/graphene/dist/types/utils/icons';
import retry from '../../utils/retry';
import { useTranslation } from 'react-i18next';
import { TAG } from './constants';
import { styles } from './styles';
import { Device } from '@capacitor/device';
import { App as AppPlugin } from '@capacitor/app';
import { Browser } from '@capacitor/browser';


registerLocaleLoader(TAG, (locale: string) => retry(() => import(`./locale.${locale}.json`)));

const info = await Device.getInfo();
const platform = info.platform.toLowerCase(); // Get the platform name ( "android", "ios")

const RequiredUpdateMessage = () => {
  const { t } = useTranslation(TAG);
  const store = useStore();

  const openStore = async (): Promise<void> => {
    try {
      const { config } = store.getState();
      const info = await Device.getInfo();
      const platform = info.platform.toLowerCase(); // Get the platform name ( "android", "ios")

      const storeUrls = {
        android: config.links.playStoreApp,
        ios: config.links.appStoreApp,
      };

      return platform === 'android' ? await Browser.open({ url: storeUrls.android }) : await Browser.open({ url: storeUrls.ios });
    } catch (error) {
      console.error('Error opening store URL:', error);
    }
  };

  const exitApp = async (): Promise<void> => {
    try {
      await AppPlugin.exitApp();
    } catch (error) {
      console.error('Error exiting the app:', error);
    }
  };

  return (
    <div className={styles.wrapper.base}>
      <StzStatus
        inline
        layout="vertical"
        background="b"
        size="medium"
        subject={t('requiredUpdate.title')}
        className={styles.stzStatus}
      >
        <StzIcon slot="logo" icon={faDownload as PreNormalizedIcon} />
        <span slot="support-text" className={styles.supportText}>
          {t('requiredUpdate.content')}
        </span>
        { platform === 'android' &&
          <>
            <StzButton slot="cta-1" variant="outlined" size="small" onClick={exitApp}>
              <span>{t('requiredUpdate.buttons.cancel')}</span>
            </StzButton>
            <StzButton slot="cta-1" size="small" onClick={openStore}>
              <span>{t('requiredUpdate.buttons.confirm')}</span>
            </StzButton>
          </> }
        { platform === 'ios' &&
          <StzButton slot="cta-1" size="small" onClick={openStore}>
            <span>{t('requiredUpdate.buttons.confirm')}</span>
          </StzButton> }
      </StzStatus>
    </div>
  );
};

export default RequiredUpdateMessage;
