import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { store } from '../../config/configureStore';
import { getClientPortalService } from '../../config/configureApi';

interface GetUserPreferencesResponse {
  [key: string]: string;
}
interface TransformedGetUserPreferencesResponse {
  [key: string]: any;
}

const getUserPreferences = async () => {
  const api: AxiosInstance = getClientPortalService();
  const { config } = store.getState();

  const transformUserPreferences = (prefs: GetUserPreferencesResponse) => {
    const transformedPreferences = Object.entries(prefs)
      .reduce((transformedPrefs, [key, value]) => {
        try {
          transformedPrefs[key] = JSON.parse(value); // eslint-disable-line
        } catch (_) {
          console.warn('Unable to parse preferences key-value pair: ', key, value);
        }
        return transformedPrefs;
      }, {} as TransformedGetUserPreferencesResponse);

    return transformedPreferences;
  };

  const getUserPreferencesResponse = await (
    !config.useMock
      ? api.get(config.endpoints.userPreferences.load)
        .then((apiResponse: AxiosResponse) => {
          return transformUserPreferences(apiResponse.data as GetUserPreferencesResponse);
        }).catch((error: AxiosError) => {
          const { response: apiResponse } = error;
          if (apiResponse && apiResponse.status === 400) {
            return {} as TransformedGetUserPreferencesResponse;
          }
          return null;
        })
      : new Promise<TransformedGetUserPreferencesResponse>((res) => {
        setTimeout(() => {
          res({});
        }, 1000);
      })
  );

  return getUserPreferencesResponse;
};

export default getUserPreferences;
