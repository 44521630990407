import { getQueryParams } from '@staizen/utils/dist/URLUtils';
import { BankAccount } from './types';
import { store } from '../../config/configureStore';
import { getClientPortalService } from '../../config/configureApi';

interface BankAccountResponseItem {
  bankName: string;
  bankAccountId: string;
  bankAccountNo: string;
  swiftcode: string; // NOTE - we are being returned small 'c' swiftcode, will check with BE
  country: string;
}

const mockGetUserBankAccounts = [
  {
    bankName: 'CIMB, test test',
    bankAccountId: '11119DC1-E009-43A9-928B-C5AB31111111',
    bankAccountNo: '111-1111111-11',
    swiftcode: 'CIBBMYKL', // NOTE - keeping swiftcode without casing to be consistent with backend
    country: 'SG',
  },
  {
    bankName: 'Commerce International Merchant Bankers Berhad (CIMB)',
    bankAccountId: '22229DC1-E009-43A9-928B-C5AB32222222',
    bankAccountNo: '222-222222-22',
    swiftcode: 'CIBBMYKL',
    country: 'SG',
  },
  {
    bankName: 'HSBC',
    bankAccountId: '33339DC1-E009-43A9-928B-C5AB33333333',
    bankAccountNo: '333-333333-33',
    swiftcode: 'CIBBMYKL',
    country: 'CIMBSSSG',
  },
  {
    bankName: 'DBS',
    bankAccountId: '44449DC1-E009-43A9-928B-C5AB34444444',
    bankAccountNo: 'k bank account',
    swiftcode: 'DBSSSG',
    country: 'SG',
  },
];

// To initiate bank account flow, add `mockNoBankAccounts=true` to url as part of query param
const mockEmptyBankAccounts: BankAccountResponseItem[] = [];

const getBankAccounts = async (): Promise<{ data?: BankAccount[]; error?: boolean }> => {
  const { config } = store.getState();
  const api = getClientPortalService();

  try {
    const response = !config.useMock
      ? await api.get(config.endpoints.bankAccount.readAccounts) as any
      : await new Promise((res) => {
        // Timeout for delay
        setTimeout(() => {
          const queryParams = getQueryParams(window.location.search, true) as { mockNoBankAccounts?: string };
          const bankAccounts = queryParams.mockNoBankAccounts === 'true' ? mockEmptyBankAccounts : mockGetUserBankAccounts;
          res({ data: bankAccounts });
        }, 1000);
      }) as { data: BankAccountResponseItem[] };

    // Transform bank accounts to fit our data model
    const bankAccounts = response.data.map((bankAccount: BankAccountResponseItem) => {
      return {
        bankName: bankAccount.bankName,
        bankAccountId: bankAccount.bankAccountId,
        bankAccountNo: bankAccount.bankAccountNo,
        swiftCode: bankAccount.swiftcode, // fix for swiftcode to swiftCode
        country: bankAccount.country,
      };
    });

    return { data: bankAccounts };
  } catch (error) {
    console.error(error);
    return { error: true };
  }
};

export default getBankAccounts;
