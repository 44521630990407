import { css } from 'emotion';

import { mdUp, smDown } from '../../../hooks/useBreakpoints';

export default {
  wrapper: css({
    [`@media ${mdUp}`]: {
      display: 'flex',
      alignItems: 'center',
      padding: 'var(--stz-space-m)',
    },
  }),
  rightActionsWrapper: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '> :not(:first-child)': {
      marginLeft: 'var(--stz-space-s)',
    },
    [`@media ${smDown}`]: {
      // only hide the coach mark button (.stz-coach-mark), do not hide the entire stz-coach-mark components so that coach mark elements still show when coach mark is in progress
      '> :not(stz-coach-mark), .stz-coach-mark': {
        display: 'none',
      },
      'stz-coach-mark': {
        // This element somehow still has height even though the coach mark button is hidden, removing the height to prevent pushing main content downwards
        height: 0,
      },
    },
  }),
  btnCustomiseDashboard: css({
    width: 'max-content',
    '> :not(:first-child)': {
      marginLeft: 'var(--stz-space-xs)',
    },
  }),
  header: css`
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    color: var(--stz-color-contrast-high);
    @media ${smDown} {
      --contrast-color: var(--stz-color-swatch-header-base-contrast);
      position: fixed;
      min-height: calc(50px + env(safe-area-inset-top));
      text-align: center;
      background: var(--stz-color-swatch-header-base);
      padding-top: calc(12px + env(safe-area-inset-top));
    }
  `,
};
