import { useLocation } from 'react-router-dom';
import { LINKS } from '../config/configureLinks';

const ROUTES_WITH_DASHBOARD = [
  LINKS.ROOT,
  LINKS.DASHBOARD,
];

export const useHasDashboard = () => {
  const { pathname } = useLocation();

  return ROUTES_WITH_DASHBOARD.includes(pathname);
};

export default useHasDashboard;
