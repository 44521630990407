import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { registerLocaleLoader } from '@staizen/graphene';
import logoLight from '../../assets/login/logo-light.svg';
import logoDark from '../../assets/login/logo-dark.svg';
import { StzStatus } from '@staizen/graphene-react';
import { TAG } from './constants';
import { Theme } from '../../config/configureTheme';
import { useTheme } from '../../hooks/useTheme';
import styles from './styles';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

export default function AppBlocker(): ReactElement {
    const { t } = useTranslation(TAG);
    const theme = useTheme();
    const logoImgSrc = theme === Theme.Dark ? logoDark : logoLight;

    return (
      <div className={styles.wrapper.base}>
        <img alt="cookie" src={logoImgSrc} className={styles.logo} />
        <StzStatus
          inline
          layout="vertical"
          background="b"
          size="medium"
          subject={t('title')}
        >
          <span slot="support-text" className={styles.supportText}>
            <Trans
              t={t}
              i18nKey="description"
              components={{
                url: <a href="https://portal.prosperus.asia" rel="noopener noreferrer" target="_blank">https://portal.prosperus.asia</a>,
              }}
            />
          </span>
        </StzStatus>
      </div>
    );
  }
