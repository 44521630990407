import { DateTimeService } from '@staizen/graphene';
import { TicketStatusHistoryItem, TicketStatusHistory } from '../types';
import { AxiosResponse } from 'axios';
import { store } from '../../../config/configureStore';
import { getClientPortalService } from '../../../config/configureApi';

const { DateTime } = DateTimeService;

interface ResponseItem {
  requestUID: string;
  userId: string;
  currency: string;
  status: 'AP' | 'PE' | 'RE' | 'PR' | 'EX' | 'US'; // requestStatus
  amount: string;
  createdTime: string | null;
  lastUpdatedTime: string | null;
  withdrawalRequestStatus: WithdrawalRequestStatus[];
  bankName: string;
  bankAccount: string;
  swiftCode: string;
}

interface WithdrawalRequestStatus {
  requestUID: string;
  status: string;
  createdTime: string;
}

const getWithdrawalTicketStatusHistory = async (id: string) => {
  const { config } = store.getState();
  const api = getClientPortalService();

  const response = await (config.useMock ? new Promise((res) => {
    setTimeout(() => { // MOCK
      res(
        {
          requestUID: id,
          userId: 'ernest.cai',
          currency: 'SGD',
          amount: '300',
          status: 'AP', // requestStatus
          createdTime: '2020-07-10T08:58:00.000Z',
          lastUpdatedTime: '2020-07-10T08:59:00.000Z',
          withdrawalRequestStatus: [
            {
              requestUID: id,
              status: 'PE',
              createdTime: '2020-07-10T08:58:00.000Z',
            },
            {
              requestUID: id,
              status: 'AP',
              createdTime: '2020-07-10T08:59:00.000Z',
            },
            {
              requestUID: id,
              status: 'US',
              createdTime: '2020-07-10T08:59:00.000Z',
            },
            {
              requestUID: id,
              status: 'RE',
              createdTime: '2020-07-10T08:59:00.000Z',
            },
          ],
          bankName: 'DBS',
          bankAccount: '123456789',
          swiftCode: 'DBSSSG',
        },
      );
    }, 1000);
  }) : api.get(config.endpoints.withdrawal.readTicket, {
    params: {
      requestUID: id,
    },
  }).then((apiResponse: AxiosResponse) => {
    return apiResponse.data;
  })) as ResponseItem;
  return {
    id: response.requestUID,
    type: 'Withdrawal',
    status: response.status,
    createdTime: DateTime.fromISO(response.createdTime),
    lastUpdatedTime: DateTime.fromISO(response.lastUpdatedTime),
    statusHistory: response.withdrawalRequestStatus.map((item: WithdrawalRequestStatus) => {
      return {
        transactionId: item.requestUID,
        status: item.status,
        createdTime: DateTime.fromISO(item.createdTime),
      } as TicketStatusHistoryItem;
    }),
    details: {
      userId: response.userId,
      currency: response.currency,
      amount: response.amount,
      bankName: response.bankName,
      bankAccount: response.bankAccount,
      swiftCode: response.swiftCode,
    },
  } as TicketStatusHistory;
};

export default getWithdrawalTicketStatusHistory;
