import { DateTimeService } from '@staizen/graphene';
import { TicketStatusHistoryItem, TicketStatusHistory } from '../ticket/types';
import { AxiosResponse } from 'axios';
import { store } from '../../config/configureStore';
import { getClientPortalService } from '../../config/configureApi';
import { MyRewardsRedeemRequest, RedeemRequestStatus } from './types';

const { DateTime } = DateTimeService;

interface ResponseItem extends MyRewardsRedeemRequest {
  redeemRequestStatusList: RedeemRequestStatus[];
}

const getRewardsDetail = async (id: string) => {
  const { config } = store.getState();
  const api = getClientPortalService();

  const response = await (config.useMock ? new Promise((res) => {
    setTimeout(() => { // MOCK
      res({
        requestId: 'RR00000008',
        userId: 'testuser02',
        rewardType: 'RW002',
        rewardTitle: 'SNACK',
        accountNo: '3333321',
        status: 'AP',
        redeemAmount: 20,
        lastUpdatedTime: '2022-11-21T23:11:25.787+08:00',
        redeemRequestStatusList: [
          {
            requestUID: 'RR00000008',
            requestStatus: 'AP',
            createdTime: '2022-11-21T23:11:25.787+08:00',
          },
          {
            requestUID: 'RR00000008',
            requestStatus: 'PE',
            createdTime: '2022-11-21T23:08:12.013+08:00',
          },
          {
            requestUID: 'RR00000008',
            requestStatus: 'AP',
            createdTime: '2022-11-21T20:59:11.893+08:00',
          },
          {
            requestUID: 'RR00000008',
            requestStatus: 'RE',
            createdTime: '2022-11-21T19:42:47.737+08:00',
          },
          {
            requestUID: 'RR00000008',
            requestStatus: 'AP',
            createdTime: '2022-11-19T16:40:50.083+08:00',
          },
          {
            requestUID: 'RR00000008',
            requestStatus: 'PE',
            createdTime: '2022-11-18T15:05:28.767+08:00',
          },
        ],
      });
    }, 1000);
  }) : api.get(config.endpoints.rewards.detail, {
    params: {
      requestId: id,
    },
  }).then((apiResponse: AxiosResponse) => {
    return apiResponse.data;
  })) as ResponseItem;
  return {
    id: response.requestId,
    type: 'Rewards',
    status: response.status,
    lastUpdatedTime: DateTime.fromISO(response.lastUpdatedTime),
    statusHistory: response.redeemRequestStatusList.map(
      (item: RedeemRequestStatus) => {
        return {
          transactionId: item.requestUID,
          status: item.requestStatus,
          createdTime: DateTime.fromISO(item.createdTime),
        } as TicketStatusHistoryItem;
      },
    ),
    details: {
      userId: response.userId,
      rewardTitle: response.rewardTitle,
      accountNo: response.accountNo,
      redeemAmount: response.redeemAmount.toString(),
    },
  } as TicketStatusHistory;
};

export default getRewardsDetail;
