import { DASHBOARD_WIDGET_KEY } from '../../../../templates/Dashboard/constants';
import { LINKS } from '../../../../config/configureLinks';

export const TAG = 'HeaderCoachMark';
export const COACH_MARK_ID = 'prosperus-coach-mark';
export const DEBOUNCE_DELAY = 100;

export const PATHS_WITH_COACH_MARKS = [
  LINKS.ROOT,
  LINKS.DASHBOARD,
  // LINKS.ACCOUNT, // Disable coach marks for this page until user particulars can be updated within app
  // LINKS.ACCOUNT_PROFILE, // Disable coach marks for this page until user particulars can be updated within app
  LINKS.FUNDING,
  LINKS.FUNDING_MANAGE,
];

type CoachMarkStep = {
  id?: string; // Optional for those targeting within dashboard widgets
  tooltipOptions: {
    placement?: string;
    zIndex: number;
    [key: string]: any;
  };
  dashboard?: {
    widgetType: string;
    isOnHeader?: boolean;
  };
};

type CoachMarkStepsMeta = {
  [key: string]: {
    key: string;
    steps: CoachMarkStep[];
    dashboardKey?: string;
  };
};

export const DEFAULT_COACH_MARK_Z_INDEX = 3;

// Unlisted IDs are on targets within dashboard widget which are not selected by ID
export const COACH_MARK_STEP_IDS = {
  home: {
    3: 'coach-mark-home-step-3',
  },
  account: {
    1: 'coach-mark-account-step-1',
  },
  funding: {
    1: 'coach-mark-funding-step-1',
    2: 'coach-mark-funding-step-2',
  },
};

// TODO: Make dashboardKey and dashboard.widgetType constants/enums so that they can be referenced from one place instead of current loose strings.
export const COACH_MARK_STEPS_META: CoachMarkStepsMeta = {
  home: {
    // Placement will be dynamically specified because widget positions may change depending on widget order.
    key: 'dashboard',
    dashboardKey: 'main',
    steps: [
      {
        tooltipOptions: {
          zIndex: DEFAULT_COACH_MARK_Z_INDEX,
        },
        dashboard: {
          widgetType: DASHBOARD_WIDGET_KEY.Applications,
          isOnHeader: true,
        },
      },
      {
        tooltipOptions: {
          zIndex: DEFAULT_COACH_MARK_Z_INDEX,
        },
        dashboard: {
          widgetType: DASHBOARD_WIDGET_KEY.ListTickets,
          isOnHeader: true,
        },
      },
      {
        id: COACH_MARK_STEP_IDS.home[3],
        tooltipOptions: {
          zIndex: DEFAULT_COACH_MARK_Z_INDEX,
        },
        dashboard: {
          widgetType: DASHBOARD_WIDGET_KEY.ListFunds,
        },
      },
      {
        tooltipOptions: {
          zIndex: DEFAULT_COACH_MARK_Z_INDEX,
        },
        dashboard: {
          widgetType: DASHBOARD_WIDGET_KEY.Commentary,
          isOnHeader: true,
        },
      },
    ],
  },
  account: {
    key: 'account',
    steps: [
      {
        id: COACH_MARK_STEP_IDS.account[1],
        tooltipOptions: {
          placement: 'right-start',
          zIndex: DEFAULT_COACH_MARK_Z_INDEX,
        },
      },
    ],
  },
  funding: {
    key: 'funding',
    steps: [
      {
        id: COACH_MARK_STEP_IDS.funding[1],
        tooltipOptions: {
          placement: 'right-start',
          zIndex: DEFAULT_COACH_MARK_Z_INDEX,
        },
      },
      {
        id: COACH_MARK_STEP_IDS.funding[2],
        tooltipOptions: {
          placement: 'right-start',
          zIndex: DEFAULT_COACH_MARK_Z_INDEX,
        },
      },
    ],
  },
};
