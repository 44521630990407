import { Capacitor } from '@capacitor/core';

/**
 * Ref: https://stackoverflow.com/a/58064481
 * @returns {true} Browser/App is running on iOS
 */
export const getIsIos = () => {
  return Capacitor.getPlatform() === 'ios' || /iphone|ipod|ipad/i.test(navigator.userAgent)
    || (
      navigator.platform === 'MacIntel' // Matches macOS/iOS
      && navigator.maxTouchPoints > 1 // Only touch-screen devices will have this as true, prevent matching macOS Safari
      &&  !(window as any).MSStream// Prevent false-positives on IE
    );
};
