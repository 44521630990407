import { TicketMap } from '../types';
import { DateTimeService } from '@staizen/graphene';
import { store } from '../../../config/configureStore';
import { aggregatePagination } from '../helper';

const { DateTime } = DateTimeService;

interface ResponseItem {
  requestUID: string;
  currency: string;
  fundingType: 'UEN' | 'PNQR' | 'FAST' | 'TT';
  amount: string;
  status: 'AP' | 'PE' | 'RE' | 'PR' | 'EX' | 'US';
  userId: string;
  lastUpdatedTime: string;
}

const getFundingTickets = async () => {
  const { config } = store.getState();
  const response = await (config.useMock ? new Promise((res) => {
    setTimeout(() => {
      res([
        {
          requestUID: 'fund1',
          currency: 'SGD',
          fundingType: 'UEN',
          amount: '1000000',
          status: 'PE',
          userId: 'kev',
          lastUpdatedTime: '2020-07-10T14:58:00.000Z',
        },
        {
          requestUID: 'fund2',
          currency: 'SGD',
          fundingType: 'FAST',
          amount: '3000',
          status: 'AP',
          userId: 'kev',
          lastUpdatedTime: '2020-05-10T14:50:00.000Z',
        },
        {
          requestUID: 'fund3',
          currency: 'SGD',
          fundingType: 'FAST',
          amount: '2500000',
          status: 'PR',
          userId: 'kev',
          lastUpdatedTime: '2020-07-10T14:30:00.000Z',
        },
        {
          requestUID: 'fund4',
          currency: 'SGD',
          fundingType: 'FAST',
          amount: '500000',
          status: 'US',
          userId: 'kev',
          lastUpdatedTime: '2020-07-10T14:30:00.000Z',
        },
      ]);
    }, 1000);
  }) : aggregatePagination(config.endpoints.funding.readTickets)) as ResponseItem[];

  const tickets = response.reduce((acc: TicketMap, item) => {
    acc.index.push(item.requestUID);
    acc.data[item.requestUID] = {
      id: item.requestUID,
      type: 'Funding',
      status: item.status,
      lastUpdated: DateTime.fromISO(item.lastUpdatedTime),
      details: {
        currency: item.currency,
        fundingType: item.fundingType,
        amount: item.amount,
        userId: item.userId,
      },
    };
    return acc;
  }, { data: {}, index: [] });
  return tickets;
};

export default getFundingTickets;

// [
//   {
//     "transactionId": "FR00001029",
//     "currency": "SGD",
//     "fundingType": "TT",
//     "amount": 1232,
//     "status": "PE",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   },
//   {
//     "transactionId": "FR00001030",
//     "currency": "SGD",
//     "fundingType": "TT",
//     "amount": 1234,
//     "status": "PE",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   },
//   {
//     "transactionId": "FR00001031",
//     "currency": "SGD",
//     "fundingType": "TT",
//     "amount": 1234,
//     "status": "PE",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   },
//   {
//     "transactionId": "FR00001034",
//     "currency": "SGD",
//     "fundingType": "UEN",
//     "amount": 10000,
//     "status": "PE",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   },
//   {
//     "transactionId": "FR00001035",
//     "currency": "SGD",
//     "fundingType": "UEN",
//     "amount": 10000,
//     "status": "PE",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   },
//   {
//     "transactionId": "FR00001036",
//     "currency": "SGD",
//     "fundingType": "UEN",
//     "amount": 10000,
//     "status": "PE",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   },
//   {
//     "transactionId": "FR00001037",
//     "currency": "SGD",
//     "fundingType": "PNQR",
//     "amount": 10000,
//     "status": "US",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   },
//   {
//     "transactionId": "FR00001038",
//     "currency": "SGD",
//     "fundingType": "PNQR",
//     "amount": 10000,
//     "status": "US",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   },
//   {
//     "transactionId": "FR00001039",
//     "currency": "SGD",
//     "fundingType": "PNQR",
//     "amount": 10000,
//     "status": "US",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   },
//   {
//     "transactionId": "FR00001040",
//     "currency": "SGD",
//     "fundingType": "PNQR",
//     "amount": 10000,
//     "status": "US",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   },
//   {
//     "transactionId": "FR00001041",
//     "currency": "SGD",
//     "fundingType": "PNQR",
//     "amount": 10000,
//     "status": "US",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   },
//   {
//     "transactionId": "FR00001043",
//     "currency": "SGD",
//     "fundingType": "PNQR",
//     "amount": 10000,
//     "status": "US",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   },
//   {
//     "transactionId": "FR00001044",
//     "currency": "SGD",
//     "fundingType": "PNQR",
//     "amount": 1234,
//     "status": "US",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   },
//   {
//     "transactionId": "FR00001045",
//     "currency": "SGD",
//     "fundingType": "PNQR",
//     "amount": 123,
//     "status": "US",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   },
//   {
//     "transactionId": "FR00001046",
//     "currency": "SGD",
//     "fundingType": "PNQR",
//     "amount": 100,
//     "status": "US",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   },
//   {
//     "transactionId": "FR00001047",
//     "currency": "SGD",
//     "fundingType": "PNQR",
//     "amount": 1232,
//     "status": "US",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   },
//   {
//     "transactionId": "FR00001048",
//     "currency": "SGD",
//     "fundingType": "PNQR",
//     "amount": 1232,
//     "status": "US",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   },
//   {
//     "transactionId": "FR00001049",
//     "currency": "SGD",
//     "fundingType": "PNQR",
//     "amount": 1232,
//     "status": "US",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   },
//   {
//     "transactionId": "FR00001050",
//     "currency": "SGD",
//     "fundingType": "PNQR",
//     "amount": 123,
//     "status": "US",
//     "userId": "kyawzin",
//     "lastUpdatedTime": null
//   }
// ]
