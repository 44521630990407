import { prefersDarkTheme } from '@staizen/graphene';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDefaultTheme } from '../utils/theme';

const darkThemeMediaQuery = prefersDarkTheme();

export const useTheme = () => {
  const [deviceTheme, setDeviceTheme] = useState(getDefaultTheme()); // default device theme
  const appTheme = useSelector((state) => state?.user?.preferences?.theme);

  const handleThemeChange = () => {
    setDeviceTheme(getDefaultTheme());
  };
  // use event listeners to listen to theme changes on device.
  useEffect(() => {
    if (darkThemeMediaQuery.addEventListener) {
      darkThemeMediaQuery.addEventListener('change', handleThemeChange);
      return () => {
        darkThemeMediaQuery.removeEventListener('change', handleThemeChange);
      };
    }

    // Fallback for when MediaQueryList.addEventListener is not defined
    darkThemeMediaQuery.addListener(handleThemeChange);
    return () => {
      darkThemeMediaQuery.removeListener(handleThemeChange);
    };
  }, []);

  if (appTheme) {
    return appTheme; // return app theme if not null
  }

  return deviceTheme;
};
