import { faEdit, faEllipsisH } from '@staizen/graphene/dist/icons/regular';
import { StzBadge, StzButton, StzCoachMark, StzContextMenu, StzHeader, StzIcon } from '@staizen/graphene-react';
import { PreNormalizedIcon } from '@staizen/graphene/dist/types/utils/icons';
import { css } from 'emotion';
import get from 'lodash/get';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUserCoachMarkIsReadPathKey } from '../../actions/creators/user';
import { MenuStore } from '../../config/configureMenu';
import { mdUp, useBreakpoint } from '../../hooks/useBreakpoints';
import { stencilRenderAdapter } from '../../utils/render';
import { COACH_MARK_ID } from '../Demo/BasicHeader/CoachMark/constants';
import HeaderProfileMenu from './HeaderProfileMenu';
import { registerLocaleLoader } from '@staizen/graphene';
import { useTranslation } from 'react-i18next';
import { contextMenuItemIds, TAG } from './constants';
import useHasDashboard from '../../hooks/useHasDashboard';
import retry from '../../utils/retry';
import { useHasCoachMarks } from '../../hooks/useHasCoachMarks';
import { getIsIos } from '../../utils/browser';

registerLocaleLoader(TAG, (locale: string) => retry(() => import(`./locale.${locale}.json`)));

const styles = {
  contextMenu: css({
    position: 'fixed',
    top: 'env(safe-area-inset-top)',
    right: 'env(safe-area-inset-right)',
    zIndex: 5,
    '--contrast-color': 'var(--stz-rgb-white)',
    '[slot="context-menu-target"]': {
      button: {
        '--stz-button-square-dimension': '50px',
        '&:focus': {
          outline: 'none',
        },
      },
    },
    [`@media ${mdUp}`]: {
      display: 'none',
    },
  }),
  coachMarkButtonWrapper: css({
    display: 'flex',
  }),
  coachMark: css({
    '--stz-coach-mark-badge-position': '2px',
    '--stz-coach-mark-badge-pulse-position': '-3px',
    pointerEvents: 'none',
  }),
};

const coachMarkWrapper = document.createElement('span');
coachMarkWrapper.classList.add(styles.coachMarkButtonWrapper);

const Header: React.FC = () => {
  const menu: MenuStore = useSelector((state) => state.menu || { menu: [] });
  const breakpoints = useBreakpoint();
  const { t } = useTranslation(TAG);
  const { hasCoachMarks } = useHasCoachMarks();

  const headerWrapperRef = useRef<HTMLDivElement>();

  const location = useLocation();
  const { pathname } = location;

  const coachMarkIsRead = useSelector((state) => {
    return get(state.user?.coachMark?.isRead ?? {}, getUserCoachMarkIsReadPathKey(pathname));
  });
  const coachMarkIsStarted = useSelector((state) => state.user?.coachMark?.isStarted);

  // Trigger menuToggled event whenever location changes and menu is still open
  const menuToggledEvent = useMemo(() => new Event('menuToggled'), []);
  useEffect(() => {
    const headerRef = headerWrapperRef?.current?.firstElementChild;
    if (headerRef && headerRef.hasAttribute('is-open')) {
      headerRef.dispatchEvent(menuToggledEvent);
    }
  }, [location]);

  const scrollContainerRefs = useRef<NodeListOf<HTMLStzScrollContainerElement>>();
  /*
    autoHide requires double tap workaround to be single tap we always show the scrollbar for ios
    source/open issue: https://github.com/KingSora/OverlayScrollbars/issues/285
  */
  useLayoutEffect(() => {
    if (scrollContainerRefs?.current) {
      return;
    }
    const scrollbars: NodeListOf<HTMLStzScrollContainerElement> = document.querySelectorAll('.stz-header STZ-SCROLL-CONTAINER');
    if (scrollbars.length > 0) {
      scrollContainerRefs.current = scrollbars;
    }
    if (!scrollContainerRefs?.current || !getIsIos()) {
      return;
    }
    scrollContainerRefs.current.forEach((el) => {
      el.getScrollbar().then((instance: any) => {
        instance.options({ scrollbars: {
          visibility: 'visible',
          autoHide: 'never',
        } });
      });
    });
  });

  // Know when context menu is open to determine if coach mark indicator badge should be on context menu button
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const onContextMenuClick = () => {
    setIsContextMenuOpen(true);
  };

  const onContextMenuAction = useCallback((e: Event) => {
    const { detail: { menuItem } } = e as CustomEvent;
    if (menuItem === contextMenuItemIds.coachMark) {
      // Activate coach mark if user clicks on item to activate coach marks
      const coachMarkElement = document.querySelector(`#${COACH_MARK_ID}`) as HTMLStzCoachMarkElement;
      coachMarkElement.start();
      return;
    }
    if (menuItem === contextMenuItemIds.customiseDashboard) {
      // Show customisation dialog in dashboard manager
      const dashboardManager = document.querySelector('stz-dashboard-manager') as HTMLStzDashboardManagerElement;
      if (dashboardManager) {
        dashboardManager.toggleCustomisationDialog(true);
      }
    }
  }, []);
  const onContextMenuHidden = useCallback(() => {
    setIsContextMenuOpen(false);
  }, []);
  useEffect(() => {
    // Listen to user interaction
    window.addEventListener('contextMenuAction', onContextMenuAction);
    window.addEventListener('contextMenuHidden', onContextMenuHidden);
    return () => {
      window.removeEventListener('contextMenuAction', onContextMenuAction);
      window.removeEventListener('contextMenuHidden', onContextMenuHidden);
    };
  }, []);

  useEffect(() => {
    if (breakpoints.mdUp && isContextMenuOpen && headerWrapperRef?.current) {
      // Click on context menu to close context menu when breakpoint goes md and above, to prevent context menu from staying on screen
      headerWrapperRef.current.querySelector('stz-context-menu').click();
    }
  }, [breakpoints.mdUp]);

  const hasTradingAccountLinked = useSelector((state) => {
    return state.user?.particulars?.data?.hasTradingAccountLinked;
  });

  const hasDashboard = useHasDashboard();

  const contextMenuItems: Array<any> = useMemo(() => {
    const items = [];
    if (hasCoachMarks) {
      items.push({
        id: contextMenuItemIds.coachMark, // the id here is not the dom element's id
        label: t('menu.coachMark'),
        render: function renderCoachMark(h: any, item: any) {
          return (
            h(
              'div',
              {
                ref: (el: any) => {
                  return stencilRenderAdapter(
                    el,
                    <>
                      <StzCoachMark
                        className={`context-menu-item-icon ${styles.coachMark}`}
                        isRead={coachMarkIsRead}
                      />
                      <span className="context-menu-item-label">{item.label}</span>
                    </>,
                    { wrapper: coachMarkWrapper },
                  );
                },
              },
            )
          );
        },
      });
    }
    if (hasDashboard && hasTradingAccountLinked) {
      items.push({
        id: contextMenuItemIds.customiseDashboard, // the id here is not the dom element's id
        label: t('menu.customiseDashboard'),
        icon: faEdit,
      });
    }
    return items;
  }, [hasCoachMarks, hasDashboard, coachMarkIsRead, hasTradingAccountLinked]);

  return (
    <div ref={headerWrapperRef}>
      <StzHeader config={menu}>
        <HeaderProfileMenu />
        <div className="stz-header-divider" slot="widgets" />
      </StzHeader>
      {Boolean(contextMenuItems?.length) && (
        <StzContextMenu
          onClick={onContextMenuClick}
          className={styles.contextMenu}
          items={contextMenuItems}
          options={{ popperOptions: { strategy: 'fixed' } }}
        >
          <StzButton variant="text" square slot="context-menu-target" swatch="low-contrast">
            <StzBadge
              val={isContextMenuOpen || coachMarkIsRead || coachMarkIsStarted || !hasCoachMarks ? 0 : 'dot'} // Only show pulsing badge when 1. context menu is closed or 2. coach mark is not read or 3. coach mark is not in progress
              animation="pulse"
              className="stz-coach-mark-indicator-badge"
            >
              <StzIcon icon={faEllipsisH as PreNormalizedIcon} fixedWidth size="lg" />
            </StzBadge>
          </StzButton>
        </StzContextMenu>
      )}
    </div>
  );
};

export default Header;
