import { store } from '../../config/configureStore';
import { getClientPortalService } from '../../config/configureApi';
import { AxiosInstance, AxiosError, AxiosResponse } from 'axios';
import { SessionTokenResponse } from './types';
import { logoutUser } from './logoutUser';

export function getSessionToken(sso: string, useMock = true) {
  const api: AxiosInstance = getClientPortalService();

  const { config } = store.getState();
  console.log('mock status', config.useMock, useMock);
  return config.useMock ? Promise.resolve({
    tokenType: 'Bearer',
    accessToken: 'mockAccessToken',
    refreshToken: 'mockRefreshToken',
    expiresIn: 300,
    sessionTimeout: 360,
  } as SessionTokenResponse) : api.post(config.endpoints.token, { sso })
    .then((response: AxiosResponse) => {
      console.log('session token response');
      return {
        tokenType: response.data.tokenType,
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        // eslint-disable-next-line no-restricted-globals
        expiresIn: Number.isNaN(response.data.expiresIn)
          ? undefined : Number(response.data.expiresIn),
        sessionTimeout: Number.isNaN(response.data.sessionTimeout)
          ? undefined : Number(response.data.sessionTimeout),
      } as SessionTokenResponse;
    }).catch((error: AxiosError) => {
      if (error.response && error.response.status === 500) {
        console.warn('WARN: token retrieval failed');
        logoutUser();
      }
      console.error('session token', error);
      return {};
    });
}
