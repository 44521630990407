import { META_SET_PREFERENCES, META_CLEAR_DISMISSED_BANNER_ALERTS } from '../constants/meta';

export interface MetaStore {
  preferences?: {
    migratePreferences?: boolean;
    loadedPreferences?: boolean;
  };
  dismissedBannerAlerts?: {
    shouldClear?: boolean;
  };
}

export function setPreferencesMeta(preferencesMetadata: Partial<MetaStore['preferences']>): Action {
  return {
    type: META_SET_PREFERENCES,
    payload: {
      ...preferencesMetadata,
    },
  };
}

export function clearDismissedBannerAlerts(shouldClear: boolean): Action {
  return {
    type: META_CLEAR_DISMISSED_BANNER_ALERTS,
    payload: {
      shouldClear,
    },
  };
}
