import { store } from '../../config/configureStore';
import { getClientPortalService } from '../../config/configureApi';
import { AxiosInstance, AxiosResponse } from 'axios';
import { DateTimeService } from '@staizen/graphene';
import { getQueryParams } from '@staizen/utils/dist/URLUtils';

// initial response from a new to eW8 user
// { "dateValidated": null,
// "formExpirationDate": null,
// "formValidationResultCode": 0,
// "successMessage": null
// }

// valid submission
// {
//   "dateValidated": "2019-08-01T06:41:31.673Z",
//   "formExpirationDate": "2022-12-30T16:00:00.000Z",
//   "formValidationResultCode": 3,
//   "successMessage": null
// }

// unlinked ew8 client id
// {
// message: "No Client Id Found"
// status: "BAD_REQUEST"
// timestamp: 1605578879809
// }

// unready ew8 (with 400 status code)
// {
//   "status": "BAD_REQUEST",
//   "message": "Account number passed not exists in the system.",
//   "timestamp": 1627013056450
// }

interface GetW8StatusApiResponse {
  dateValidated: string;
  formExpirationDate: string;
  formValidationResultCode: number;
  successMessage: null | string; // an api response field from eW8 service provider endpoint e.g. Account number passed not exists in the system.
}

interface GetW8StatusResponse {
  status: Ew8FormStatus;
}

const unreadyAccountMessage = 'Account number passed not exists in the system.';

enum Ew8FormResultCode {
  VALID = 3,
  INVALID = 2
}

export enum Ew8FormStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAIL = 'fail',
  ERROR = 'error',
  UNREADY = 'unready',
}

const { DateTime } = DateTimeService;

const getMockResponse = () => {
  const queryParams = getQueryParams(window.location.search, true) as { mockEw8Status?: string };
  return new Promise((res, rej) => {
    setTimeout(() => {
      const today = DateTime.fromJSDate(new Date());
      switch (queryParams?.mockEw8Status) {
        case Ew8FormStatus.ERROR:
          rej(new Error('Mock EW8 Error thrown'));
          break;
        case Ew8FormStatus.UNREADY:
          rej(new Error(unreadyAccountMessage));
          break;
        case Ew8FormStatus.FAIL:
          res({
            dateValidated: today.toISO(),
            formExpirationDate: today.plus({ year: 1 }).toISO(),
            formValidationResultCode: Ew8FormResultCode.INVALID,
          });
          break;
        case Ew8FormStatus.PENDING:
          res({
            dateValidated: today.minus({ hours: 1 }).toISO(),
            formExpirationDate: today.plus({ year: 1 }).toISO(),
            formValidationResultCode: Ew8FormResultCode.INVALID,
          });
          break;
        case Ew8FormStatus.SUCCESS:
        default:
          res({
            dateValidated: today.toISO(), // '2020-12-09T10:25:14.283Z'
            formExpirationDate: today.plus({ year: 1 }).toISO(), // '2023-12-30T16:00:00.000Z'
            formValidationResultCode: Ew8FormResultCode.VALID, // 3
          });
          break;
      }
    }, 1000);
  });
};

const getApiResponse = (api: AxiosInstance, config: Config) => {
  return api.get(config.endpoints.w8.getPreviousSubmissionStatus)
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

const getEw8Status = async (
  eW8LinkCreatedOrW8ExpiryDate?: DateTimeService.DateTime, // no link created date when polling is done without ew8 form submission in progress
): Promise<GetW8StatusResponse> => {
  const { config } = store.getState();
  const api = getClientPortalService();
  try {
    const {
      dateValidated = null,
      formExpirationDate = null,
      formValidationResultCode = null,
    } = await (config.useMock
      ? getMockResponse()
      : getApiResponse(api, config)
    ) as GetW8StatusApiResponse;
    const ew8SubmissionDateTime = DateTime.fromISO(dateValidated);
    /*
      the ew8Status becomes relevant in three scenarios:
      1) ew8LinkGenerated, ew8Form submitted afterwards // relevant to ew8
      2) ew8Form submitted after ew8Expiry, we can assume that the new submission is relevant // relevant to w8
      3) undeclared w8 (no expiry) // relevant to w8
      NOTE: if the ew8 submitted datetime is before expiry, it is likely that the ew8Form was for the previous w8 submission
    */
    const isRelevantEw8Date = ew8SubmissionDateTime.isValid
      && ew8SubmissionDateTime > eW8LinkCreatedOrW8ExpiryDate;
    // if no date given for comparison, assume w8 as undeclared
    if (!eW8LinkCreatedOrW8ExpiryDate || isRelevantEw8Date) {
      // if true assume that the resultCode returned is from a related ew8Link generated
      const isFormExpirationDateValid = DateTime.fromISO(formExpirationDate).isValid;
      console.log('ew8date is valid', isFormExpirationDateValid); // Remove after test in uat
      return { status: formValidationResultCode === Ew8FormResultCode.VALID
        && isFormExpirationDateValid ? Ew8FormStatus.SUCCESS : Ew8FormStatus.FAIL };
    }
    return { status: Ew8FormStatus.PENDING };
  } catch (e) {
    console.error(e);
    const isUnready = (e as any)?.message === unreadyAccountMessage // for mock response
      || (e as any)?.response?.data?.message === unreadyAccountMessage; // for api response
    if (isUnready) {
      // Handle error from both mock and axios
      return { status: Ew8FormStatus.UNREADY };
    }
    return { status: Ew8FormStatus.ERROR };
  }
};

export default getEw8Status;
