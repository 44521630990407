import { AxiosInstance, AxiosError } from 'axios';
import { getClientPortalService } from '../../config/configureApi';
import { store } from '../../config/configureStore';
import { logoutUser } from './logoutUser';

// returns 200 if auth token not present
export function pingSessionAlive() {
  const api: AxiosInstance = getClientPortalService();

  const { config } = store.getState();
  return config.useMock
    ? new Promise((res) => setTimeout(res, 1000))
    : api.get(config.endpoints.sessionCheck).catch((error: AxiosError) => {
      console.error('error', error);
      if (!error.response) { // no connection
        return false;
      }
      if (error.response.status === 500) { // invalid token will return 500 instead of 409
        logoutUser();
      }
    });
}
