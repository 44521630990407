import { store } from '../../../config/configureStore';
import { getClientPortalService } from '../../../config/configureApi';
import { AxiosError } from 'axios';
import { OTPErrorResponse, OTPError } from '../../auth/types';

interface RemoveBankAccountReqParams {
  bankAccountId: string;
  twoFAData: string;
  challenge: string;
}

// Response shape of received data
// interface RemoveBankAccountResponse {
//   message: string;
// }

export interface TransformedRemoveBankAccountResponse {
  success: boolean;
  error?: string;
  errorCode?: string;
}

export enum ErrorResponse {
  PENDING_WITHDRAWAL = 'Your Bank Account cannot delete as you have pending withdrawal request'
}

export enum Error {
  PENDING_WITHDRAWAL = 'PENDING_WITHDRAWAL',
  GENERIC_ERROR = 'REJECTED',
}

const removeBankAccount = async (
  params: RemoveBankAccountReqParams,
): Promise<TransformedRemoveBankAccountResponse> => {
  const { config } = store.getState();
  const api = getClientPortalService();
  if (config.useMock) {
    // Timeout for delay
    await new Promise((res) => setTimeout(res, 1000));

    const { twoFAData } = params;

    // Done with assumption that success === true whenever OTP passes, and reason filled up if removal fails
    if (twoFAData === '000000') {
      return { success: true };
    }

    if (twoFAData === '111111') {
      return { success: true, error: Error.GENERIC_ERROR };
    }

    if (twoFAData === '222222') {
      return { success: true, error: Error.PENDING_WITHDRAWAL, errorCode: '400' };
    }

    if (twoFAData === '333333') {
      return { success: false, error: OTPError.CHALLENGE_CANCELLED, errorCode: '400' };
    }

    return {
      success: false,
      error: OTPError.INVALID_OTP,
      errorCode: '400',
    };
  }

  const bankAccountDeleteRequest = {
    bankAccountId: params.bankAccountId,
    challenge: params.challenge,
    twoFAData: params.twoFAData,
  };

  const response = await api
    .post(config.endpoints.bankAccount.createRemoveTicket, bankAccountDeleteRequest)
    .then(() => {
      // http 200 successful
      // {
      //   "status": "OK",
      //   "message": "Your Bank Account deleted successfully",
      //   "timeStamp": 1599557188911
      // }
      return {
        success: true,
      };
    }).catch((error: AxiosError) => {
      // TODO handle internet connection down
      // apiResponse.data.message === 'Request rejected'
      const { response: apiResponse } = error;
      const errorCode = String(apiResponse.status);
      let errorStatus: string = Error.GENERIC_ERROR;
      let success = true;
      if (apiResponse && apiResponse.status === 400) {
        if (apiResponse.data && apiResponse.data.message === OTPErrorResponse.INVALID_OTP) {
          success = false;
          errorStatus = OTPError.INVALID_OTP;
        }
        if (apiResponse.data && apiResponse.data.message === OTPErrorResponse.CHALLENGE_CANCELLED) {
          success = false;
          errorStatus = OTPError.CHALLENGE_CANCELLED;
        }
        if (apiResponse.data && apiResponse.data.message === ErrorResponse.PENDING_WITHDRAWAL) {
          errorStatus = Error.PENDING_WITHDRAWAL;
        }
      }
      return {
        success,
        error: errorStatus,
        errorCode,
      };
    });

  return response;
};
export default removeBankAccount;
