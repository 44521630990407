/* eslint-disable no-restricted-syntax */
import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getBasePath, isLocalURL, setQueryParams, getQueryParams, isExternalURL } from '@staizen/utils/dist/URLUtils';
import { setSelectedUrl } from '../../actions/creators/routing';
import { handleContactLink, openNewTab, openExternalUrl } from '../../utils/link';
import { logoutUser } from '../../api/auth';
import { LINKS } from '../../config/configureLinks';

/*
 Handles link clicks from web components to prevent react app from reloading itself
*/

const ACCEPTED_PROTOCOLS = [
  'http:',
  'https:',
  'capacitor:', // protocol in iOS is changed
];

const CONTACT_PROTOCOLS = [
  'mailto:',
  'tel:',
];

const ClickInterceptor = (props: any) => {
  const { children } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedUrl(
      window.location.pathname,
    ));

    window.addEventListener('click', onLinkClicked);
    history.listen(onHistoryChange);
    return () => {
      window.removeEventListener('click', onLinkClicked);
    };
  }, []);

  const onHistoryChange = () => {
    dispatch(setSelectedUrl(
      window.location.pathname,
    ));
  };

  /*
   * Link is valid if it is not react rendered, an anchor element with href and, target attribute is not a popup.
   * We don't want to handle Links generated by React as it is already being handled.
   */
  const isLinkValid = (link: HTMLLinkElement) => {
    return (
      !link.dataset.react
      && link.tagName === 'A'
      && link.hasAttribute('href')
      && link.target !== '_blank'
    );
  };

  const getLeftClickedLink = (e: MouseEvent): EventTarget | null => {
    const notLeftClick = e.button !== 0 && e.type === 'click';

    if (notLeftClick) {
      return null;
    }

    if ((e as any as TouchEvent).touches) {
      return null;
    }

    return (e.target as HTMLElement).closest('a');
  };

  const navigateToURL = useCallback((url: string) => {
    if (!url) {
      history.push(LINKS.ROOT);
      return;
    }

    const local = isLocalURL(url, true);
    if (local) {
      history.push(url);
      return;
    }
    console.warn('Router: No match for url:', local, url);
    window.location.href = url;
  }, [history]);

  const isProtocolValid = (link: URL) => {
    return ACCEPTED_PROTOCOLS.includes(link.protocol);
  };

  const isContactLink = (link: URL) => {
    return CONTACT_PROTOCOLS.includes(link.protocol);
  };

  const isLogoutURL = (link: URL) => {
    return link.href.endsWith(LINKS.LOGOUT);
  };

  const isPdf = (link: URL) => {
    return link && link.href.endsWith('.pdf');
  };

  const onLinkClicked = useCallback((e: MouseEvent | any) => {
    const link = getLeftClickedLink(e) as HTMLLinkElement;

    if (isPdf(link as any)) {
      e.preventDefault();
      e.stopPropagation();
      openNewTab(link.href);
      return;
    }

    if (!link || !isLinkValid(link)) {
      return;
    }

    if (isContactLink(link as any)) {
      handleContactLink(link.href);
    }

    if (!isProtocolValid(link as any)) {
      return;
    }

    if (isExternalURL(link.href, false)) { // open all external links in a new tab
      e.preventDefault();
      e.stopPropagation();
      openExternalUrl(link.href, link.target);
      return;
    }

    if (isLogoutURL(link as any)) {
      logoutUser();
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    const mockParams: any = getQueryParams(window.location.href, false);
    for (const key in mockParams) { // Object.fromEntries not supported by IE
      if (!key.startsWith('mock') && key !== 'alpha') delete mockParams[key];
    }
    const params = { ...mockParams, ...getQueryParams(link.href, false) };
    const baseLink = getBasePath(setQueryParams(link.href, params, false));
    navigateToURL(baseLink);
  }, [navigateToURL]);

  return (<>{children}</>);
};

export default ClickInterceptor;
