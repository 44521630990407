import React, { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { faqQuestionRouteExpression } from '../../config/configureRoutes';

const ScrollToTop = () => {
  const location = useLocation();

  /* Prevents faq from scrolling back up */
  const matchFaqRoute = useRouteMatch(faqQuestionRouteExpression);

  useEffect(() => {
    if (matchFaqRoute) return;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  return <></>;
};

export default ScrollToTop;
