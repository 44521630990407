import merge from 'lodash/merge';
import { combineReducers, Reducer } from 'redux';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // localstorage
import sessionStorage from 'redux-persist/lib/storage/session';
import { MENU_SET_ITEMS } from '../actions/constants/menu';
import { AUTH_SET_TOKEN, AUTH_UPDATE_TOKEN } from '../actions/constants/auth';
import { ROUTING_SET_PAGE_DATA, ROUTING_SET_SELECTED_URL } from '../actions/constants/routing';
import { SAXO_SET_STATE, SAXO_UPDATE_CURRENCY_ACCOUNTS } from '../actions/constants/saxo';
import {
  USER_COACH_MARK_UPDATE_IS_READ, USER_COACH_MARK_UPDATE_IS_STARTED, USER_SET_BANK_ACCOUNTS,
  USER_SET_PARTICULARS, USER_SET_PREFERENCES, USER_SET_TICKETS, USER_UPDATE_BANK_ACCOUNTS,
  USER_UPDATE_DISMISSED_PROMPTS, USER_ADD_DISMISSED_BANNER_ALERTS,
  USER_SET_DISMISSED_BANNER_ALERTS, USER_UPDATE_PARTICULARS, USER_UPDATE_TICKETS,
  USER_SET_DASHBOARDS, USER_SET_ALL_PREFERENCES, USER_SET_ANNOUNCEMENTS,
} from '../actions/constants/user';
import { AuthStore } from '../actions/creators/auth';
import { PageDataStore } from '../actions/creators/routing';
import { SaxoStore } from '../actions/creators/saxo';
import { PreferenceType, UserStore } from '../actions/creators/user';
import { MenuStore } from './configureMenu';
import { MetaStore } from '../actions/creators/meta';
import { META_CLEAR_DISMISSED_BANNER_ALERTS, META_SET_PREFERENCES } from '../actions/constants/meta';
import {
  PORTFOLIO_SET_DETAIL,
  PORTFOLIO_SET_OVERVIEW,
  PORTFOLIO_UPDATE_DETAIL,
  PORTFOLIO_UPDATE_OVERVIEW,
  PORTFOLIO_SET_PRIVATE_WEALTH,
  PORTFOLIO_SET_PRIVATE_WEALTH_DETAIL,
  PORTFOLIO_UPDATE_PRIVATE_WEALTH,
  PORTFOLIO_UPDATE_PRIVATE_WEALTH_DETAIL,
} from '../actions/constants/portfolio';
import { PortfolioStore } from '../actions/creators/portfolio';
import { RewardsStore } from '../actions/creators/rewards';
import { REWARDS_SET_REDEEMABLE, REWARDS_SET_TYPES, REWARDS_UPDATE_REDEEMABLE, REWARDS_UPDATE_TYPES } from '../actions/constants/rewards';

const config = (state: object = null): object => state;
const menu: Reducer<MenuStore> = (state = null, action) => {
  switch (action.type) {
    case MENU_SET_ITEMS:
      return { ...state, ...action.payload };
    case ROUTING_SET_SELECTED_URL:
      return { ...state, ...action.payload };
    case USER_SET_PARTICULARS: {
      const name = action.payload.data?.name;
      const { profile } = state;
      const nextProfile = { ...profile, title: name };
      return { ...state, profile: nextProfile };
    }
    default:
      return state;
  }
};

const pageData: Reducer<PageDataStore> = (state: object = null, action) => {
  switch (action.type) {
    case ROUTING_SET_PAGE_DATA:
      return { ...action.payload };
    default:
      return state;
  }
};

const auth: Reducer<AuthStore> = (
  state = { data: {
    tokenType: undefined,
    accessToken: undefined,
    refreshToken: undefined,
    sessionTimeout: undefined,
  },
  ui: {} },
  action,
) => {
  switch (action.type) {
    case AUTH_SET_TOKEN:
      return { ...state, ...action.payload };
    case AUTH_UPDATE_TOKEN:
      return {
        ...state,
        data: {
          ...action.payload.data,
          sessionTimeout: state.data?.sessionTimeout,
        },
      };
    default:
      return state;
  }
};

const saxo: Reducer<SaxoStore> = (state = {
  auth: undefined,
  currencyAccounts: undefined,
  accounts: undefined,
  clientKey: undefined,
}, action) => {
  switch (action.type) {
    case SAXO_SET_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case SAXO_UPDATE_CURRENCY_ACCOUNTS:
      return {
        ...state,
        currencyAccounts: { ...state?.currencyAccounts, ...action.payload?.currencyAccounts }, // to prevent existing data from overridden if only partial data is provided
      };
    default:
      return state;
  }
};

const user: Reducer<UserStore> = (state = null, action) => {
  switch (action.type) {
    case USER_UPDATE_BANK_ACCOUNTS:
      return { ...state, bankAccounts: { ...state.bankAccounts, ...action.payload } };
    case USER_SET_BANK_ACCOUNTS:
      return { ...state, bankAccounts: action.payload };
    case USER_UPDATE_DISMISSED_PROMPTS: {
      const { dismissedPrompts = {} } = state || {};
      return { ...state, dismissedPrompts: { ...dismissedPrompts, ...action.payload } };
    }
    case USER_ADD_DISMISSED_BANNER_ALERTS: {
      const { dismissedBannerAlerts = [] } = state || {};
      if (dismissedBannerAlerts.indexOf(action.payload) === -1) { // additional check to prevent double adds
        return { ...state, dismissedBannerAlerts: [...dismissedBannerAlerts, action.payload] };
      }
      return state;
    }
    case USER_SET_DISMISSED_BANNER_ALERTS: {
      return { ...state, dismissedBannerAlerts: action.payload };
    }
    case USER_SET_ALL_PREFERENCES:
      // sets the data for potentially - dismissedPrompts, preferences, coachMark, dashboards
      return {
        ...state,
        ...action.payload,
      };
    case USER_SET_PREFERENCES: // User Preferences
      return { ...state, preferences: { ...action.payload } };
    case USER_SET_ANNOUNCEMENTS:
      return { ...state, announcements: { ...action.payload } };
    case USER_UPDATE_PARTICULARS: // User Particulars
      return { ...state, particulars: { ...state?.particulars, ...action.payload } };
    case USER_SET_PARTICULARS: // User Particulars
      return { ...state, particulars: action.payload };
    case USER_SET_TICKETS:
      return { ...state, tickets: { ...action.payload } };
    case USER_UPDATE_TICKETS:
      return { ...state, tickets: { ...state.tickets, ...action.payload } };
    case USER_COACH_MARK_UPDATE_IS_READ:
      return {
        ...state,
        coachMark: {
          ...state.coachMark,
          isRead: { ...merge(state.coachMark?.isRead ?? {}, action.payload.isRead) },
        },
      };
    case USER_COACH_MARK_UPDATE_IS_STARTED:
      return { ...state, coachMark: { ...state.coachMark, isStarted: action.payload.isStarted } };
    case USER_SET_DASHBOARDS:
      return { ...state, dashboards: action.payload };
    default:
      return state;
  }
};

const meta: Reducer<MetaStore> = (state = null, action) => {
  switch (action.type) {
    case META_SET_PREFERENCES:
      return { ...state, preferences: action.payload };
    case META_CLEAR_DISMISSED_BANNER_ALERTS:
      return { ...state, dismissedBannerAlerts: action.payload };
    default:
      return state;
  }
};

const portfolio: Reducer<PortfolioStore> = (state = null, action) => {
  switch (action.type) {
    case PORTFOLIO_UPDATE_OVERVIEW:
      return { ...state, overview: { ...state?.overview, ...action.payload } };
    case PORTFOLIO_SET_OVERVIEW:
      return { ...state, overview: action.payload };
    case PORTFOLIO_UPDATE_DETAIL:
      return { ...state, detail: { ...state?.detail, ...action.payload } };
    case PORTFOLIO_SET_DETAIL:
      return { ...state, detail: action.payload };
    case PORTFOLIO_UPDATE_PRIVATE_WEALTH:
      return { ...state, privateWealth: { ...state?.privateWealth, ...action.payload } };
    case PORTFOLIO_SET_PRIVATE_WEALTH:
      return { ...state, privateWealth: action.payload };
    case PORTFOLIO_UPDATE_PRIVATE_WEALTH_DETAIL:
      return {
        ...state,
        privateWealthDetail: {
          ...state?.privateWealthDetail,
          ...action.payload,
        },
      };
    case PORTFOLIO_SET_PRIVATE_WEALTH_DETAIL:
      return { ...state, privateWealthDetail: action.payload };
    default:
      return state;
  }
};

const rewards: Reducer<RewardsStore> = (state = null, action) => {
  switch (action.type) {
    case REWARDS_UPDATE_REDEEMABLE:
      return { ...state, redeemable: { ...state?.redeemable, ...action.payload } };
    case REWARDS_SET_REDEEMABLE:
      return { ...state, redeemable: action.payload };
    case REWARDS_UPDATE_TYPES:
      return { ...state, types: { ...state?.types, ...action.payload } };
    case REWARDS_SET_TYPES:
      return { ...state, types: action.payload };
    default:
      return state;
  }
};

const authPersistConfig = {
  key: 'auth',
  storage: sessionStorage, // to maintain tokens when tab is refreshed
  timeout: 0,
  stateReconciler: (loadedFromStorageState: any, currentAppState: any) => {
    if (currentAppState.data?.accessToken
      && loadedFromStorageState.data?.accessToken !== currentAppState.data?.accessToken
    ) {
      return currentAppState;
    }
    return loadedFromStorageState;
  },
};

export const createRootReducer = (userStoreId?: string): Reducer => {
  const userTransform = createTransform(
    null,
    (state, key) => {
      if (key === PreferenceType.CoachMark) {
        // Ignore `isStarted` value persisted in coach mark
        const { isStarted, ...persistedCoachMark } = state as UserStore[PreferenceType.CoachMark];
        return persistedCoachMark;
      }
      return state;
    },
  );
  const userStore = userStoreId ? persistReducer( // TODO - eventually use pouchDB for compatibility and multi-tab persistence
    {
      key: `prosperus-user-${userStoreId}`,
      storage,
      timeout: 0,
      blacklist: ['particulars', 'bankAccounts', 'tickets'], // blacklisted items contain personal info
      transforms: [userTransform],
      stateReconciler: (loadedFromStorageState: any, currentAppState: any) => {
        return Object.assign(loadedFromStorageState, currentAppState);
      },
    },
    user,
  ) : null;
  return combineReducers({
    config,
    menu,
    pageData,
    user: userStore,
    auth: persistReducer(authPersistConfig, auth),
    saxo,
    meta,
    portfolio,
    rewards,
  });
};
