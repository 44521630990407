/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, AxiosInstance } from 'axios';
import { store } from './configureStore';
import { updateToken } from '../actions/creators/auth';
import { logoutUser, refreshSessionToken } from '../api/auth';
import { DateTimeService } from '@staizen/graphene';
import { SessionTokenResponse } from '../api/auth/types';

const fetchRefreshToken = (expiresIn: number, refreshToken: string) => {
  const refreshInterval = (Math.max(expiresIn - 60, 0)) * 1000; // refresh earlier than expiry and convert to ms from seconds
  setTimeout((_) => { // clear the token so it can be renewed
    refreshSessionToken(refreshToken).then((token: SessionTokenResponse | void) => {
      if (token) {
        store.dispatch( // update store for other requests to use new token
          updateToken({ data: { ...token }, ui: { isFetching: false, isError: false } }),
        );
        fetchRefreshToken(token.expiresIn, token.refreshToken); // TEST - change to test refresh token expiry, change inside setToken too
      }
    });
  }, refreshInterval);
};

function configureClientPortalApi() {
  let hasRefreshCycleStarted = false; // use a Promise<any> if required to block while waiting for token to return
  const api: AxiosInstance = axios.create({
    // headers: { 'Access-Control-Allow-Origin': '*' }
    // pass browser cookies on request headers
    withCredentials: process.env.REACT_APP_ENV === 'moc',
  });
  api.interceptors.request.use(async (requestConfig: AxiosRequestConfig) => {
    // Set auth tokens
    const { auth: { data: {
      tokenType = undefined,
      refreshToken = undefined,
      accessToken = undefined,
      expiresIn = undefined,
      tokenExpiryDateTime = undefined,
    } } } = store.getState();

    // if its a get session token call dont send the old expired token in Authorization header otherwise 409
    const isSsoTokenPresent = requestConfig.data && requestConfig.data.sso;
    if (isSsoTokenPresent || requestConfig.params?.bypassInterceptor) {
      if (requestConfig.params?.bypassInterceptor) {
        delete requestConfig.params.bypassInterceptor;
      }
      return requestConfig;
    }

    if (!hasRefreshCycleStarted && refreshToken) { // start refreshing the token at intervals after retrieving the first auth token from access token endpoint
      // attempt to calculate time from
      const { DateTime } = DateTimeService;
      const currentTime = DateTime.fromJSDate(new Date());
      const refreshTime = DateTime.fromISO(tokenExpiryDateTime);
      const timeLeft = refreshTime.isValid ? Math.round(Math.max(refreshTime.diff(currentTime, 'seconds').seconds, 0)) : null; // if user refreshed get remaining expiry time
      console.log('TIME LEFT', timeLeft);
      try {
        const calculatedExpiryTime = Math.min(timeLeft, expiresIn);
        console.log('refresh is valid', refreshTime.isValid, tokenExpiryDateTime, timeLeft, expiresIn, refreshTime, currentTime, refreshTime.diff(currentTime, 'seconds').seconds);
        console.log(calculatedExpiryTime);
        fetchRefreshToken(calculatedExpiryTime, refreshToken); // if timeLeft is null, expiresIn is used - TEST - change value to test
        hasRefreshCycleStarted = true;
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    }
    if (accessToken) {
      requestConfig.headers.Authorization = `${tokenType} ${accessToken}`;
    }
    return requestConfig;
  });

  api.interceptors.response.use((response: AxiosResponse) => {
    return response;
  }, (error: AxiosError) => {
    console.log('portal api Endpoint Error', error);
    if (!error.response) {
      // No error response, possibly rejected by client's browser (CORS)
      // TODO - possibly show a something went wrong page here
    } else if (error.response.status === 401 || error.response.status === 409) { // unauthenticated / session expired / concurrent session
      // TODO - invalid format token should be handled with a http error should be 401 not 500; BE returning 500
      logoutUser();
    } else if (error.response.status === 500) { // getSubmissionStatus returns 500, getConcurrentLogin returns 409 (valid token, stale login) or 500 (invalid token)
      console.warn('Error 500: ', error);
    }
    return Promise.reject(error);
  });
  return api;
}

let clientPortal: null | AxiosInstance = null;
export const getClientPortalService = (): AxiosInstance => {
  clientPortal = clientPortal || configureClientPortalApi();
  return clientPortal;
};
