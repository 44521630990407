import { registerLocaleLoader } from '@staizen/graphene';
import { css } from 'emotion';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setPageData } from '../../actions/creators/routing';
import { smDown } from '../../hooks/useBreakpoints';
import retry from '../../utils/retry';
import { TAG } from './constants';

registerLocaleLoader(TAG, (locale: string) => retry(() => import(`./locale.${locale}.json`)));

const styles = {
  container: css({
  }),
  heading: css({
    font: 'var(--stz-typo-ui-title-xl)',
    color: ' var(--stz-color-contrast-high)',
  }),
  subheading: css({
    font: 'var(--stz-typo-ui-body-m)',
    color: ' var(--stz-color-contrast-medium)',
  }),
  list: css({
    marginTop: 'var(--stz-space-s)',
    paddingLeft: '0',
    listStyle: 'none',
  }),
  link: css({
    marginLeft: 'var(--stz-space-s)',
  }),
  errorCode: css({
    position: 'absolute',
    bottom: '267px',
    right: '0',
    fontSize: '500px',
    [`@media ${smDown}`]: {
      bottom: '72px',
      fontSize: '150px',
    },
    fontWeight: 700,
    opacity: '0.04',
    userSelect: 'none',
    webkitTouchCallout: 'none',
    outline: 'none',
  }),
  content: css({
    position: 'absolute',
    top: '40%',
    left: '45%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '660px',
    zIndex: 1,
    [`@media ${smDown}`]: {
    },
  }),
};

interface ErrorProps {
  code: string;
  links: Link[];
  title: string;
}

interface Link {
  title: string;
  url: string;
}

export const Error: React.FC<ErrorProps> = (props: ErrorProps) => {
  const { code = '404', title: pageTitle = '', links = [] } = props;
  const { t } = useTranslation(TAG);
  const dispatch = useDispatch();

  const renderItem = useCallback((menuTitle: string, url: string) => {
    // assumption list of useful links will not contain duplicates
    return (
      <li key={url}>
        -
        <a className={styles.link} href={url}>{menuTitle}</a>
      </li>
    );
  }, []);

  useEffect(() => {
    dispatch(setPageData({ title: pageTitle }));
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h3 className={styles.heading}>{t('content.heading')}</h3>
        <span className={styles.subheading}>{t('content.subheading')}</span>
        <ul className={styles.list}>
          {links.map(({ title, url }) => {
            return renderItem(title, url);
          })}
        </ul>
      </div>
      <span tabIndex={-1} className={styles.errorCode}>{code}</span>
    </div>
  );
};

export default Error;
