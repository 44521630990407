import { DateTimeService } from '@staizen/graphene';
import { TicketStatus, TicketStatusHistory, TicketStatusHistoryItem } from '../types';
import { AxiosError, AxiosInstance } from 'axios';
import { store } from '../../../config/configureStore';
import { getClientPortalService } from '../../../config/configureApi';
import { CkacarTicketStatus } from '../../products/types';

const { DateTime } = DateTimeService;

interface CkacarRequestHistoryItem {
  requestUID: string;
  requestStatus: string | TicketStatus;
  createdTime: string;
  userID: string;
}

// Note: eventually if more details are requested the response will likely take a shape like this
// interface Response {
//   requestUID: string;
//   userID: string;
//   requestStatus: RequestStatus;
//   createdTime: string;
//   // lastUpdatedTime: string | null;
//   ckacarRequestHistory: CkacarRequestHistoryItem[];
// }

const getMockResponse = (
  id: string,
): Promise<CkacarRequestHistoryItem[]> => (
  new Promise((res) => {
    setTimeout(() => {
      res(
        [
          {
            createdTime: '2022-02-08T04:38:44.880Z',
            requestStatus: CkacarTicketStatus.Approved,
            requestUID: id,
            userID: 'ernest.cai',
          },
          {
            createdTime: '2022-02-08T04:32:44.880Z',
            requestStatus: CkacarTicketStatus.Unsuccessful,
            requestUID: id,
            userID: 'ernest.cai',
          },
          {
            createdTime: '2022-02-08T04:31:44.880Z',
            requestStatus: CkacarTicketStatus.NullVoid,
            requestUID: id,
            userID: 'ernest.cai',
          },
          {
            createdTime: '2022-02-08T04:30:44.880Z',
            requestStatus: CkacarTicketStatus.Rejected,
            requestUID: id,
            userID: 'ernest.cai',
          },
          {
            createdTime: '2022-02-08T04:28:44.880Z',
            requestStatus: CkacarTicketStatus.Pending,
            requestUID: id,
            userID: 'ernest.cai',
          },
        ],
      );
    }, 1000);
  })
);

const getApiResponse = (
  id: string,
  api: AxiosInstance, config: Config,
): Promise<CkacarRequestHistoryItem[]> => (
  api.get(config.endpoints.cka.readTicket, {
    params: {
      requestId: id,
    },
  }).then((response: any) => {
    return response.data;
  }).catch((error: AxiosError) => {
    console.error(error);
    return [];
  })
);

type getCkacarTicketStatusHistory = (
  id: string,
) => Promise<TicketStatusHistory>
const getCkacarTicketStatusHistory: getCkacarTicketStatusHistory = async (id) => {
  const { config } = store.getState();
  const api = getClientPortalService();
  const response: CkacarRequestHistoryItem[] = await (config.useMock
    ? getMockResponse(id)
    : getApiResponse(id, api, config)
  );

  return {
    id,
    type: 'Ckacar',
    statusHistory: response.map((item) => {
      return {
        transactionId: item.requestUID,
        status: item.requestStatus,
        createdTime: DateTime.fromISO(item.createdTime),
      } as TicketStatusHistoryItem;
    }),
    details: { // No details chosen by business to return
    },
  } as TicketStatusHistory;
};

export default getCkacarTicketStatusHistory;
