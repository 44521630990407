import { registerLocaleLoader } from '@staizen/graphene';
import { StzCoachMark } from '@staizen/graphene-react';
import { css } from 'emotion';
import { sortBy } from 'lodash';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUserCoachMarkIsReadPathKey, saveAndUpdateUserCoachMarkIsRead, updateUserCoachMarkIsStarted } from '../../../../actions/creators/user';
import { lgUp, mdOnly, smDown, useBreakpoint } from '../../../../hooks/useBreakpoints';
import retry from '../../../../utils/retry';
import { COACH_MARK_ID, COACH_MARK_STEPS_META, DEBOUNCE_DELAY, TAG } from './constants';
import { useHasCoachMarks } from '../../../../hooks/useHasCoachMarks';
import { LINKS } from '../../../../config/configureLinks';

// Main Coach Mark element of entire web app.
// Should only have *1* main coach mark element across web app to ensure that coach marks are seamless when browser is resized.

registerLocaleLoader(TAG, (locale: string) => retry(() => import(`./locale.${locale}.json`)));

const getIsCoachMarkTargetMenuItem = (target: string) => target && target.includes('stz-header-menu');

const styles = {
  base: css({
    height: 24, // Prevent header from getting extra height
    [`@media ${lgUp}`]: {
      '--stz-coach-mark-tooltip-content-max-width': '520px',
    },
    [`@media ${mdOnly}`]: {
      '--stz-coach-mark-tooltip-content-max-width': '392px',
    },
    whiteSpace: 'pre-line',
  }),
  isInFrontOfMenu: css({
    '[data-tippy-root]': {
      [`@media ${smDown}`]: {
        zIndex: '1001 !important' as any, // Have coach mark to be behind stz-header's overlay when it needs to
      },
    },
  }),
};

const CoachMark: React.FC = () => {
  const { t } = useTranslation(TAG);
  const breakpoints = useBreakpoint();
  const { pathname } = useLocation();
  const { hasCoachMarks, setHasCoachMarks } = useHasCoachMarks();

  const [isInFrontOfMenu, setIsInFrontOfMenu] = useState(false);
  const coachMarkIsStarted = useSelector((state) => state.user?.coachMark?.isStarted);
  const coachMarkIsRead = useSelector((state) => {
    return get(state.user?.coachMark?.isRead ?? {}, getUserCoachMarkIsReadPathKey(pathname));
  });

  const hasTradingAccountLinked = useSelector((state) => {
    return state.user?.particulars?.data?.hasTradingAccountLinked;
  });

  const dispatch = useDispatch();

  const dashboards = useSelector((state) => state.user.dashboards?.data, isEqual);

  // Track when coach mark targets are rendered on page after page first load, render coach mark only after targets are ready
  const [isCoachMarkTargetsReady, setIsCoachMarkTargetsReady] = useState(false);

  // Track when dashboard is ready after dispatching, start coach mark only after dashboard render is ready.
  // If current page's coach mark is not within dashboard, this will be true immediately.
  const [isDashboardReady, setIsDashboardReady] = useState(false);

  const coachMarksMeta = useMemo(() => {
    switch (pathname) {
      case LINKS.ROOT:
      case LINKS.DASHBOARD:
        return COACH_MARK_STEPS_META.home;
      case LINKS.ACCOUNT:
      case LINKS.ACCOUNT_PROFILE:
        return COACH_MARK_STEPS_META.account;
      case LINKS.FUNDING:
      case LINKS.FUNDING_MANAGE:
        return COACH_MARK_STEPS_META.funding;
      default:
        return null;
    }
  }, [pathname]);

  const isOnDashboard = useMemo(() => Boolean(coachMarksMeta?.dashboardKey), [coachMarksMeta]);
  const [sortedCoachMarkSteps, setSortedCoachMarkSteps] = useState<any>();
  // Coach mark steps that are active i.e. rendered on page and not part of hidden dashboard widget
  const activeCoachMarkSteps = useMemo(() => {
    if (coachMarksMeta) {
      const { key, steps } = coachMarksMeta;

      // Coach mark steps with content
      const stepsWithContent = steps.map((step, index) => {
        const { id, tooltipOptions } = step;
        return {
          target: id && `#${id}`,
          title: t(`${key}.${index}.title`),
          content: t(`${key}.${index}.content`),
          tooltipOptions,
        };
      });

      if (!isOnDashboard) {
        // Coach marks not on dashboard, toggle states that are meant for making coach marks work on dashboard to prevent them from interfering.
        return stepsWithContent;
      }

      // Coach marks are within dashboard - steps have to follow dashboard widgets order
      // and may be on dashboard widget, hence not possible to target by element id and have to formulate selector dynamically.
      // Also, filter away inactive coach mark steps (i.e. those on widgets not added to dashboard)
      const dashboardWidgets = dashboards?.[0]?.widgets;
      if (dashboardWidgets?.length) {
        const dashboardSteps = dashboardWidgets.map(({ type }, dashboardWidgetIndex) => {
          const stepIndex = steps.findIndex(({ dashboard }) => type === dashboard?.widgetType);
          if (stepIndex >= 0) {
            const step = steps[stepIndex];
            const stepWithContent = stepsWithContent[stepIndex];

            // Properly point to target within dashboard widget, and also align placement according to position on dashboard
            const nextTargetString = step.dashboard.isOnHeader
              ? `.stz-dashboard-column:nth-of-type(${dashboardWidgetIndex + 1}) .stz-dashboard-widget-header-title`
              : stepWithContent.target;
            const nextTarget = [
              {
                // On layout with 2 columns
                target: nextTargetString,
                breakpoint: 'sm', // smallest breakpoint with 2 columns
                direction: 'up',
                tooltipOptions: {
                  ...step.tooltipOptions,
                  // Even-numbered index widgets are on right column hence left placement; opposite for odd-numbered index widgets;
                  placement: (dashboardWidgetIndex % 2 === 0) ? 'right' : 'left',
                },
              },
              {
                // On layout with 1 column
                target: nextTargetString,
                tooltipOptions: {
                  ...step.tooltipOptions,
                  placement: 'top-start',
                },
              },
            ];
            return {
              ...stepWithContent,
              target: nextTarget,
            };
          }
          return null;
        }).filter(Boolean);
        return dashboardSteps;
      }
    }
  }, [coachMarksMeta, isOnDashboard, dashboards]);

  // Stops coach mark and reset state if page or dashboards has changed
  useEffect(() => {
    if (coachMarkIsStarted) {
      // Stop coach marks
      const coachMark = document.querySelector(`#${COACH_MARK_ID}`) as HTMLStzCoachMarkElement;
      if (coachMark) {
        coachMark.skip({ isRead: coachMarkIsRead }); // retain original state of coach mark isRead's value
      }
      dispatch(updateUserCoachMarkIsStarted(false));

      // Temporary patch to ensure that page will be scrolled to the top if there's a page change while coach mark is activated
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 500);
    }

    // Reset state
    setSortedCoachMarkSteps(null);
    setIsInFrontOfMenu(false);
    setIsCoachMarkTargetsReady(false);
    setIsDashboardReady(false);
  }, [pathname, dashboards]);

  // Determine when coach mark targets are ready (i.e. rendered and no longer re-rendering hence debounced) upon page load
  const onCoachMarkTargetsReady = useCallback((nextSortedCoachMarkSteps) => {
    setIsCoachMarkTargetsReady(true);
    setSortedCoachMarkSteps(nextSortedCoachMarkSteps);
  }, []);
  const debouncedOnCoachMarkTargetsReadyRef = useRef(
    debounce(onCoachMarkTargetsReady, DEBOUNCE_DELAY),
  );
  const documentMutationObserver = useMemo(() => {
    return new MutationObserver(() => {
      const stepsWithOffsetTop = (activeCoachMarkSteps as any[]).map((step) => {
        const nextTarget = Array.isArray(step.target) ? step.target[0].target : step.target;
        return {
          step,
          offsetTop: (document.querySelector(nextTarget) as HTMLElement)?.offsetTop,
        };
      });
      const isAllTargetsFound = stepsWithOffsetTop.every(({ offsetTop }) => {
        return offsetTop !== null && offsetTop !== undefined;
      });
      if (isAllTargetsFound) {
        const nextSortedCoachMarkSteps = sortBy(stepsWithOffsetTop, ['offsetTop'])
          .map(({ step }) => step);
        debouncedOnCoachMarkTargetsReadyRef.current(nextSortedCoachMarkSteps);
      }
    });
  }, [activeCoachMarkSteps, isCoachMarkTargetsReady]);
  useEffect(() => {
    if (activeCoachMarkSteps?.length && !isCoachMarkTargetsReady) {
      if (isOnDashboard) {
        documentMutationObserver.observe(document, { childList: true, subtree: true });
      } else {
        setSortedCoachMarkSteps(activeCoachMarkSteps);
        setIsDashboardReady(true);
        setIsCoachMarkTargetsReady(true);
      }
    }
    // Clean up - Disconnect mutation observer
    return () => {
      documentMutationObserver.disconnect();
    };
  }, [isOnDashboard, activeCoachMarkSteps, documentMutationObserver, isCoachMarkTargetsReady]);

  // Determine when dashboard is ready (i.e. rendered and no longer re-rendering hence debounced) upon page load
  // Restart coach mark without dispatching to update store as store already has coach mark saved as started state
  const onDashboardReady = useCallback(() => {
    setIsDashboardReady(true);
    const coachMark = document.querySelector(`#${COACH_MARK_ID}`) as HTMLStzCoachMarkElement;
    coachMark.start();
  }, []);
  const debouncedOnDashBoardReadyRef = useRef(debounce(onDashboardReady, DEBOUNCE_DELAY));
  const dashboardMutationObserver = useMemo(() => {
    return new MutationObserver(() => {
      const isAllTargetsFound = (activeCoachMarkSteps as any[]).every((step) => {
        const nextTarget = Array.isArray(step.target) ? step.target[0].target : step.target;
        return document.querySelector(nextTarget);
      });
      if (isAllTargetsFound) {
        debouncedOnDashBoardReadyRef.current();
      }
    });
  }, [activeCoachMarkSteps]);
  // cancel debounced callbacks from mutationObserver if pathname changes
  useEffect(() => {
    return () => {
      debouncedOnDashBoardReadyRef.current.cancel();
      debouncedOnCoachMarkTargetsReadyRef.current.cancel();
    };
  }, [pathname]);
  // Clean up - Disconnect mutation observer
  useEffect(() => {
    return () => {
      dashboardMutationObserver.disconnect();
    };
  }, [isDashboardReady]);

  // Coach marks have been activated
  const onCoachMarkStart = useCallback(() => {
    dispatch(updateUserCoachMarkIsStarted(true));

    // Patch to restart coach mark as dashboard will re-render after each dispatch
    // Coach mark will be restarted once dashboard is done re-rendering, handled within mutation observer.
    if (isOnDashboard && !isDashboardReady) {
      const dashboardElement = document.querySelector('stz-dashboard');
      const coachMark = document.querySelector(`#${COACH_MARK_ID}`) as HTMLStzCoachMarkElement;
      coachMark.skip();
      dashboardMutationObserver.observe(
        dashboardElement,
        {
          childList: true,
          subtree: true,
          attributeFilter: ['data-prosperus-dashboard-mutation-observer'],
        },
      );
      // help start an observation for non-chrome browsers which don't wish to re-render
      dashboardElement.toggleAttribute('data-prosperus-dashboard-mutation-observer');
      return;
    }

    // Temporary patch to ensure that coach mark target is always scrolled into view upon start
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 300);
  }, [isOnDashboard, isDashboardReady, dashboardMutationObserver]);

  // Either
  // 1. Open up menu if current screen is small screen, and if current coach mark step is pointing to menu item
  // 2. Determine if coach mark be in front of menu
  const onCoachMarkStepNext = useCallback((e: Event) => {
    const { detail: { nextStepIndex } } = e as CustomEvent;
    const coachMarkTarget = sortedCoachMarkSteps[nextStepIndex]?.target;
    const headerMenu = document.querySelector('stz-header') as any;
    if (getIsCoachMarkTargetMenuItem(coachMarkTarget)) {
      if (!headerMenu.isOpen) {
        headerMenu.dispatchEvent(new Event('menuToggled'));
        onMenuToggled();
      } else if (!isInFrontOfMenu) {
        setIsInFrontOfMenu(true);
      }
    } else if (headerMenu.isOpen) {
      headerMenu.dispatchEvent(new Event('menuToggled'));
      onMenuToggled();
    } else if (isInFrontOfMenu) {
      setIsInFrontOfMenu(false);
    }
  }, [sortedCoachMarkSteps, isInFrontOfMenu]);
  useEffect(() => {
    if (coachMarkIsStarted) {
      // re-register event listener as callback has changed
      window.addEventListener('coachmarknext', onCoachMarkStepNext);
      return () => {
        window.removeEventListener('coachmarknext', onCoachMarkStepNext);
      };
    }
  }, [coachMarkIsStarted, onCoachMarkStepNext]);

  // Either
  // 1. Close menu if current screen is small screen, and if current coach mark step is not pointing to menu item
  // 2. Determine if coach mark be in front of menu
  const onCoachMarkStepPrevious = useCallback((e: Event) => {
    const { detail: { previousStepIndex } } = e as CustomEvent;
    const coachMarkTarget = sortedCoachMarkSteps[previousStepIndex]?.target;
    const headerMenu = document.querySelector('stz-header') as any;
    if (getIsCoachMarkTargetMenuItem(coachMarkTarget)) {
      if (!headerMenu.isOpen) {
        headerMenu.dispatchEvent(new Event('menuToggled'));
        onMenuToggled();
      } else if (!isInFrontOfMenu) {
        setIsInFrontOfMenu(true);
      }
    } else if (headerMenu.isOpen) {
      headerMenu.dispatchEvent(new Event('menuToggled'));
      onMenuToggled();
    } else if (isInFrontOfMenu) {
      setIsInFrontOfMenu(false);
    }
  }, [sortedCoachMarkSteps, isInFrontOfMenu]);
  useEffect(() => {
    if (coachMarkIsStarted) {
      // re-register event listener as callback has changed
      window.addEventListener('coachmarkprevious', onCoachMarkStepPrevious);
      return () => {
        window.removeEventListener('coachmarkprevious', onCoachMarkStepPrevious);
      };
    }
  }, [coachMarkIsStarted, onCoachMarkStepPrevious]);

  // Coach mark has been dismissed, perform necessary clean up
  const onCoachMarkStepDismiss = () => {
    if (!isDashboardReady) {
      // Patch to restart coach mark as dashboard will rerender after each dispatch
      // This dismiss is received as part of coach mark restart patch.
      return;
    }

    const headerMenu = document.querySelector('stz-header') as any;
    if (headerMenu.isOpen) {
      // Close header menu if it is open when coach mark stops.
      headerMenu.dispatchEvent(new Event('menuToggled'));
    }
    dispatch(saveAndUpdateUserCoachMarkIsRead(true, pathname));
    dispatch(updateUserCoachMarkIsStarted(false));
    if (isOnDashboard) {
      // Reset to default
      setIsDashboardReady(false);
    }
  };

  // Menu toggled, determine if
  // 1. Coach marks should be dismissed
  // 2. Coach mark should stay behind overlay
  const onMenuToggled = useCallback(() => {
    const headerMenu = document.querySelector('stz-header') as any;
    const coachMark = document.querySelector(`#${COACH_MARK_ID}`) as HTMLStzCoachMarkElement;
    const coachMarkTarget = sortedCoachMarkSteps?.[coachMark.currentStepIndex]?.target;
    if (getIsCoachMarkTargetMenuItem(coachMarkTarget) && !headerMenu.isOpen) {
      coachMark.skip({ isRead: coachMarkIsRead }); // retain original state of coach mark isRead's value
      dispatch(updateUserCoachMarkIsStarted(false));
    }
    if (getIsCoachMarkTargetMenuItem(coachMarkTarget) && headerMenu.isOpen) {
      setIsInFrontOfMenu(true);
    } else {
      setIsInFrontOfMenu(false);
    }
  }, [sortedCoachMarkSteps, coachMarkIsRead]);
  useEffect(() => {
    if (coachMarkIsStarted && breakpoints.smDown) {
      window.addEventListener('menuToggled', onMenuToggled);
      return () => {
        window.removeEventListener('menuToggled', onMenuToggled);
      };
    }
  }, [onMenuToggled, breakpoints.smDown, coachMarkIsStarted]);

  // Add/remove event listeners when coach marks are activated/dismissed
  useEffect(() => {
    if (coachMarkIsStarted) {
      window.addEventListener('coachmarknext', onCoachMarkStepNext);
      window.addEventListener('coachmarkprevious', onCoachMarkStepPrevious);
      window.addEventListener('coachmarkskip', onCoachMarkStepDismiss);
      window.addEventListener('coachmarkend', onCoachMarkStepDismiss);
      return () => {
        window.removeEventListener('coachmarknext', onCoachMarkStepNext);
        window.removeEventListener('coachmarkprevious', onCoachMarkStepPrevious);
        window.removeEventListener('coachmarkskip', onCoachMarkStepDismiss);
        window.removeEventListener('coachmarkend', onCoachMarkStepDismiss);
      };
    }
    window.addEventListener('coachmarkstart', onCoachMarkStart);
    return () => {
      window.removeEventListener('coachmarkstart', onCoachMarkStart);
    };
  }, [
    coachMarkIsStarted,
    onCoachMarkStart,
    onCoachMarkStepNext,
    onCoachMarkStepPrevious,
    onCoachMarkStepDismiss,
  ]);

  // Screen resized
  // Determine if header menu should remain open
  useEffect(() => {
    if (coachMarkIsStarted && breakpoints.smDown) {
      // re-register event listener as callback has changed
      const headerMenu = document.querySelector('stz-header') as any;
      const coachMark = document.querySelector(`#${COACH_MARK_ID}`) as any;
      const coachMarkTarget = sortedCoachMarkSteps?.[coachMark.currentStepIndex]?.target;
      if (getIsCoachMarkTargetMenuItem(coachMarkTarget) && !headerMenu.isOpen) {
        headerMenu.dispatchEvent(new Event('menuToggled'));
      }
    }
  }, [breakpoints.smDown]);

  useEffect(() => {
    // No coach marks if user has unlinked trading account, or no coach mark steps
    setHasCoachMarks(!(
      !hasTradingAccountLinked
      || !(sortedCoachMarkSteps?.length)
      || !isCoachMarkTargetsReady
    ));
  }, [hasTradingAccountLinked, sortedCoachMarkSteps, isCoachMarkTargetsReady]);

  return (hasCoachMarks
    && (
      <StzCoachMark
        id={COACH_MARK_ID}
        className={`${styles.base} ${isInFrontOfMenu ? styles.isInFrontOfMenu : ''}`}
        steps={sortedCoachMarkSteps}
        isRead={coachMarkIsRead}
      />
    )
  );
};

export default CoachMark;
