export const TAG = 'Dashboard';

export const COMMENTARY_LIMIT = 3;

export const DASHBOARDS_PREFS_VERSION = {
  withPortfolioOverview: 2,
  withMyRewards: 3,
  withNewArrangment: 4,
  withAnnouncement: 5,
};

export enum DASHBOARD_WIDGET_KEY {
  Applications = 'ApplicationsWidget',
  Portfolio = 'PortfolioWidget',
  Video = 'VideoWidget',
  ListFunds = 'ListFundsCardWidget',
  ListTickets = 'ListTicketsCardWidget',
  Commentary = 'CommentaryCardWidget',
  Rewards = 'RewardsWidget',
  Announcement = 'AnnouncementWidget',
  Beta = 'BetaComponent',
}
