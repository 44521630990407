import { SettingsTabId } from '../templates/Settings/constants';

const BASE_LINKS = {
  ROOT: '/',
  DASHBOARD: '/home',
  ACCOUNT: '/account',
  FUNDING: '/funding',
  ACTIONS: '/actions',
  PRODUCTS: '/products',
  INVESTMENT_INSIGHTS: '/investment-insights',
  SETTINGS: '/settings',
  HELP: '/help',
  LOGIN: '/login',
  LOGOUT: '/logout',
  PROFILE: '/profile',
  ERROR_404: '/404',
};

const ACCOUNT_LINKS = {
  ACCOUNT_PROFILE: `${BASE_LINKS.ACCOUNT}/profile`,
};

const FUNDING_LINKS = {
  FUNDING_MANAGE: `${BASE_LINKS.FUNDING}/manage`,
};

const SETTINGS_LINKS = {
  SETTINGS_APPEARANCE: `${BASE_LINKS.SETTINGS}/${SettingsTabId.Appearance}`,
  SETTINGS_SECURITY: `${BASE_LINKS.SETTINGS}/${SettingsTabId.Security}`,
};

const HELP_LINKS = {
  HELP_CONTACT_US: `${BASE_LINKS.HELP}/contactus`,
  HELP_FAQ: `${BASE_LINKS.HELP}/faq`,
  HELP_FAQ_SEARCH: `${BASE_LINKS.HELP}/faq/search`,
  HELP_FAQ_POPUPS: `${BASE_LINKS.HELP}/faq/popups`,
};

export const LINKS = {
  ...BASE_LINKS,
  ...ACCOUNT_LINKS,
  ...FUNDING_LINKS,
  ...SETTINGS_LINKS,
  ...HELP_LINKS,
};
