import { store } from '../../config/configureStore';
import { getClientPortalService } from '../../config/configureApi';
import { AxiosInstance, AxiosResponse } from 'axios';
import { SendLoginSmsOtpRequest, SendLoginSmsOtpResponse } from './types';

export async function sendLoginOtp(payload: SendLoginSmsOtpRequest) {
  const api: AxiosInstance = getClientPortalService();

  const { config } = store.getState();

  if (config.useMock) {
    return new Promise<SendLoginSmsOtpResponse>((res) => {
      setTimeout(() => {
        res({
          challenge: 'mock challenge',
          response: 'mock sendSmsOtpResponse',
          securityCharCode: 'Abcd',
          success: true,
        });
        // Use the following for erroneous response
        // res({
        //   // message: 'error.smsotp.connfailed',
        //   message: 'error.smsotp.sendfailed',
        //   status: 'SERVICE_UNAVAILABLE',
        //   timestamp: 1622189433577,
        // });
      }, 1000);
    });
  }

  return api({ method: 'post',
    url: config.endpoints.sendLoginSmsOtp,
    data: payload,
    params: { bypassInterceptor: true } })
    .then((response: AxiosResponse) => {
      return { // to check what is response for 201 created
        challenge: response.data.challenge,
        response: response.data.response,
        securityCharCode: response.data.securityCharCode,
        success: response.data.success,
      } as SendLoginSmsOtpResponse;
    });
}
