/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, AxiosInstance } from 'axios';
import { setSaxoToken } from '../actions/creators/saxo';
import { ERROR_TYPE, CLIENT_PORTAL_ERROR_TYPE } from '../api/constants';
import { getSaxoSessionToken } from '../api/auth'; // get token from client portal api
import { store } from './configureStore';

interface ErrorResponse {
  status?: number;
  error: string | ERROR_TYPE | CLIENT_PORTAL_ERROR_TYPE;
  response: any;
}

function configureSaxoApi() {
  const api: AxiosInstance = axios.create({
    // pass browser cookies on request headers
    withCredentials: process.env.REACT_APP_ENV === 'moc',
  });
  api.interceptors.request.use(async (config: AxiosRequestConfig) => {
    let { saxo: { auth = null } = {} } = store.getState();
    if (!auth || !auth.accessToken) {
      try {
        const token: any = await getSaxoSessionToken();
        auth = token;
        store.dispatch(setSaxoToken(token));
        setTimeout(() => { // clear the token so it can be renewed
          store.dispatch(setSaxoToken({}));
        }, auth.accessTokenExpiry * 1000); // convert to ms
      } catch (err) {
        console.warn(err);
        store.dispatch(setSaxoToken({})); // failed to fetch new token, void the old one too
        return Promise.reject(err);
      }
    }

    config.headers.Authorization = `${auth.tokenType} ${auth.accessToken}`;
    return config;
  }, (error: AxiosError) => {
    return Promise.reject(error);
  });

  api.interceptors.response.use((response: AxiosResponse) => {
    return response;
  }, (axiosError: AxiosError) => {
    console.warn(axiosError);
    if ((axiosError as any).error) { // api call has prefilled with an ErrorResponse
      return Promise.reject(axiosError);
    }

    const errorResponse = {
      error: ERROR_TYPE.UNKNOWN,
      response: axiosError.response || axiosError,
    } as ErrorResponse;

    // 400 -> BadRequest
    // InvalidClientId ->Indicates that the requested client id was invalid.
    // NoValidInput -> No valid input values passed.
    // InvalidRequest ->Indicates that the request is malformed.
    // 503 -> ServiceUnavailable -> Service Unavailable
    if (!axiosError.response) {
      // No error response, possibly rejected by client's browser (CORS)
      // TODO - possibly redirect to a something went wrong page here
      errorResponse.error = ERROR_TYPE.NO_CONNECTION;
    } else if (axiosError.response.status === 400 || axiosError.response.status === 503) {
      // 503 potentially can launch a retry mechanism
      errorResponse.error = ERROR_TYPE.EXCEPTION;
    } else if (axiosError.response.status === 401) {
      // 401 -> Unauthorized -> Indicates that the request was rejected because the 'Authorization' header was missing in the request or contained an invalid security token.
      errorResponse.error = ERROR_TYPE.UNAUTHENTICATED;
      store.dispatch(setSaxoToken({})); // token was rejected, prevent re-use of invalid token
    }
    return Promise.reject(errorResponse);
  });
  return api;
}

let saxo: null | AxiosInstance = null;
export const getSaxoService = (): AxiosInstance => {
  saxo = saxo || configureSaxoApi();
  return saxo;
};
