import { Capacitor } from '@capacitor/core';
import ProsperUsPlugin from '../plugins/prosperUsPlugin';
import { getI18n } from 'react-i18next';
import { Theme } from '../config/configureTheme';
import { getTheme } from './theme';
import { Browser } from '@capacitor/browser';

let i18n: any;
const t = (key: string, options?: { [key: string]: any }) => i18n.t(`app:nativeNavBar.${key}`, options);

// Domain values must be in capacitor.config.json's allowNavigation array, otherwise they will show in in-app browser instead.
const domainsWithNavBar = [
  {
    domain: 'sim.logonvalidation.net',
    hasBackLabel: true,
  },
  {
    domain: 'live.logonvalidation.net',
    hasBackLabel: true,
  },
  {
    domain: 'novum.cgs-cimb.com.sg',
    hasBackLabel: true,
  },
  {
    domain: 'build.prosperus.asia',
    hasBackLabel: true,
  },
  {
    domain: 'uat.build.prosperus.asia',
    hasBackLabel: true,
  },
  {
    domain: 'boost.prosperus.asia',
    hasBackLabel: true,
  },
  {
    domain: 'uat.boost.prosperus.asia',
    hasBackLabel: true,
  },
  {
    domain: 'ew8.cgsi.com',
    hasBackLabel: true,
  },
  {
    domain: 'stgew8.cgsi.com',
    hasBackLabel: true,
  },
  {
    domain: 'account.prosperus.asia',
  },
  {
    domain: 'uat.account.prosperus.asia',
  },
  {
    domain: 'portal.cgs-cimb.com.sg',
  },
  {
    domain: 'irs.gov',
  },
];

// Handle opening of links in native app
const openUrlNatively = (link: string) => {
  const isAllowNavigation = domainsWithNavBar.some(({ domain }) => {
    return link.includes(domain);
  });

  if (isAllowNavigation && !link.endsWith('.pdf')) { // Browser.open to handle pdfs
    // Show native nav bar so that user can return back to app
  //  showNativeNavBar(link);
    // Replace the current window.location.href instead of attempting to open it in browser which will end up opening in in-app browser
    // ;
    Browser.open({ url: link });
  }
};

export const openExternalUrl = (link: string, target = '') => {
  if (Capacitor.isNativePlatform()) {
    openUrlNatively(link);
    return;
  }

  // Open external urls in new tab by default if target is not defined
  const nextTarget = target || '_blank';
  if (nextTarget === '_blank' || Capacitor.getPlatform() !== 'web') {
    openNewTab(link);
    return;
  }

  window.open(link, nextTarget);
};

export const openNewTab = (link: string) => {
  if (Capacitor.isNativePlatform()) {
    openUrlNatively(link);
    return;
  }

  window.open(link, '_blank', 'noopener noreferrer');
};

export const handleContactLink = (link: string) => {
  if (Capacitor.getPlatform() === 'ios') {
    window.open(link, '_system', 'noopener noreferrer');
  }
  // Else, do nothing and let the system naturally handle it
};

export const showNativeNavBar = async (link: string, options: { hasBackLabel?: boolean } = {}) => {
  if (Capacitor.getPlatform() !== 'web') {
    if (!i18n) {
      i18n = getI18n();
      i18n.loadNamespaces('app');
    }

    const theme = getTheme();
    const hasBackLabel = options?.hasBackLabel
      || (domainsWithNavBar.find(({ domain }) => link.includes(domain))?.hasBackLabel);

    const statusBarFillerElements = window.document.getElementsByClassName('status-bar-filler');
    if (statusBarFillerElements?.length) {
      statusBarFillerElements[0].remove();
    }
    await ProsperUsPlugin.showNavBarOnNextPageLoad({
      backLabel: hasBackLabel ? t('back') : '',
      backgroundColor: theme === Theme.Light
        ? { r: 11, g: 49, b: 143, alpha: 1 } : { r: 41, g: 41, b: 41, alpha: 1 },
      foregroundColor: { r: 255, g: 255, b: 255, alpha: 0.87 },
    });
  }
};

/**
 * To be used instead of directly accessing window.location.origin as iOS returns a different protocol
 * @returns string
 */
export const getBaseUrl = () => {
  // Replace iOS protocol if needed
  return window.location.origin.replace('capacitor://', 'https://');
};
