import { store } from '../../config/configureStore';
import { getClientPortalService } from '../../config/configureApi';
import { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { generateSsoLink } from './generateSsoLink';
import { AuthStore, setToken } from '../../actions/creators/auth';

const authState: AuthStore = {
  data: { accessToken: undefined, refreshToken: undefined, tokenType: 'Bearer', sessionTimeout: undefined },
  ui: { isFetching: false, isError: false },
};

export function logoutUser() {
  const api: AxiosInstance = getClientPortalService();

  const { config, auth } = store.getState();

  // User is not logged in yet, do not attempt to log user out
  if (!auth?.data?.accessToken) {
    return;
  }

  if (config.useMock) {
    return new Promise((res) => {
      store.dispatch(setToken(authState)); // clear session storage
      window.location.href = generateSsoLink();
      res(true); // logout successfully
    });
  }

  return api.get(config.endpoints.logout).then((response: AxiosResponse) => {
    if (response.status === 200) {
      return true; // logout successfully
    }
    return false;
  }).catch((error: AxiosError) => {
    if (error.response && (error.response.status === 401 || error.response.status === 409)) {
      return true; // user already in logout state
    }
    console.warn('Error: Unexpected issue to logout user', error);
    return false; // user not logout sucessfully e.g. connection lost
  }).then((isSuccessful: boolean) => {
    store.dispatch(setToken(authState)); // remove session from device
    window.location.href = generateSsoLink();
    return isSuccessful; // result of the backend has acknowledged logout
  });
}
