import { store } from '../../../config/configureStore';
import { getClientPortalService } from '../../../config/configureClientPortalApi';
import { AxiosInstance, AxiosResponse } from 'axios';

interface IOSVersionResponse {
  data?: {
    minimumVersion: string;
    latestVersion: string;
  };
  error?: boolean;
}

const mockMinVersionIOS = {
  minimumVersion: '1.2.3',
  latestVersion: '1.3.0',
};

export const getMinVersionIOS = async (): Promise<IOSVersionResponse> => {
  const api: AxiosInstance = getClientPortalService();
  const { config } = store.getState();

  try {
    const response = !config.useMock
      ? await api.get(config.endpoints.minVersionIOS)
          .then((apiResponse: AxiosResponse) => {
            return apiResponse as IOSVersionResponse;
          })
          .catch((error: any) => {
            if (error?.response?.status === 400) {
              return {
                data: { minimumVersion: '', latestVersion: '' },
                hasError: false,
              };
            }
            return {
              data: { minimumVersion: '', latestVersion: '' },
              hasError: true,
            };
          })
      : ((await new Promise((res) => {
          setTimeout(() => {
            res({ data: mockMinVersionIOS });
          }, 1000);
        })) as { data: { minimumVersion: string; latestVersion: string } });

    return { data: response.data };
  } catch (error) {
    console.error(error);
    return { error: true };
  }
};
