import { samlResponse } from './mockSamlResponse';
import { store } from '../../config/configureStore';
import { getClientPortalService } from '../../config/configureApi';
import { AxiosResponse, AxiosInstance } from 'axios';

export enum AppType {
  Investor = 'investor',
  TraderGo = 'traderGo'
}

export const getAppToken = (applicationType: AppType) => {
  const api: AxiosInstance = getClientPortalService();

  const { config } = store.getState();
  return api.get(config.endpoints[`${applicationType}Sso`]).then((response: AxiosResponse) => {
    return response.data;
  }).catch((error) => {
    if (config.useMock) {
      return samlResponse;
    }
    console.error(error);
  });
};
