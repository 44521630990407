import { registerLocaleLoader } from '@staizen/graphene';
import { faEdit } from '@staizen/graphene/dist/icons/regular';
import { StzButton, StzIcon } from '@staizen/graphene-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useHasDashboard from '../../../hooks/useHasDashboard';
import retry from '../../../utils/retry';
import CoachMark from './CoachMark/CoachMark';
import styles from './styles';

interface BasicHeaderProps {
  data?: {
    title: string;
    tabName?: string;
  };
}

const TAG = 'BasicHeader';

registerLocaleLoader(TAG, (locale: string) => retry(() => import(`./locale.${locale}.json`)));

const BasicHeader: React.FC<BasicHeaderProps> = (props) => {
  const { data } = props;

  const { t } = useTranslation(TAG);
  useEffect(() => {
    const pageName = data?.tabName || data?.title;
    if (pageName) {
      document.title = t('title.base', { pageName });
    } else {
      document.title = t('title.default');
    }
  }, [data?.title, data?.tabName]);

  const hasTradingAccountLinked = useSelector((state) => {
    return state.user?.particulars?.data?.hasTradingAccountLinked;
  });

  const hasDashboard = useHasDashboard();

  const onCustomiseDashboardBtnClick = () => {
    const dashboardManager = document.querySelector('stz-dashboard-manager');
    if (dashboardManager) {
      dashboardManager.toggleCustomisationDialog(true);
    }
  };

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.header}>{data?.title}</h1>
      <div className={styles.rightActionsWrapper}>
        {hasDashboard && hasTradingAccountLinked && (
          <StzButton
            className={styles.btnCustomiseDashboard}
            variant="text"
            size="small"
            onClick={onCustomiseDashboardBtnClick}
          >
            <StzIcon icon={faEdit} />
            <span>{t('actions.customise')}</span>
          </StzButton>
        )}
        <CoachMark />
      </div>
    </div>
  );
};
export default BasicHeader;
