import { store } from '../../config/configureStore';
import { getClientPortalService } from '../../config/configureApi';
import { AxiosInstance, AxiosResponse } from 'axios';
import { ValidateLoginSmsOtpRequest, ValidateLoginSmsOtpResponse } from './types';

export function validateLoginOtp(payload: ValidateLoginSmsOtpRequest) {
  const api: AxiosInstance = getClientPortalService();

  const { config } = store.getState();
  return config.useMock ? Promise.resolve({
    maskMobileNo: null,
    name: 'mock validate login otp name',
    oauthCode: 'mock sso code',
    smsOTP: null,
    ssoCallback: 'https://mock.portal.prosperus.asia',
    token: {},
    twoFAEnabled: true,
    twoFALinked: true,
    userId: payload.userId,
    verifiedOTP: true, // always true if successful otherwise undefined
  } as ValidateLoginSmsOtpResponse) : api({
    method: 'post',
    url: config.endpoints.validateLoginSmsOtp,
    data: payload,
    params: {
      bypassInterceptor: true,
    },
  }).then((response: AxiosResponse) => {
    return response.data as ValidateLoginSmsOtpResponse;
  });
}
