import { DateTimeService } from '@staizen/graphene';

export interface Ticket {
  id: string;
  type: 'Funding' | 'Withdrawal' | 'BankAccount' | 'Ckacar' | 'Rewards';
  lastUpdated: DateTimeService.DateTime;
  status: string | TicketStatus;
  details?: {
    [key: string]: any;
  };
}

export type TicketStatusHistoryItem = {
  transactionId: string;
  status: string | TicketStatus;
  createdTime: DateTimeService.DateTime;
};

export interface TicketStatusHistory {
  id: string;
  type?: TicketType;
  lastUpdatedTime?: DateTimeService.DateTime;
  createdTime?: DateTimeService.DateTime;
  status?: string | TicketStatus;
  statusHistory: TicketStatusHistoryItem[];
  details?: {
    [key: string]: string;
  };
}

export interface TicketMap {
  total?: number;
  data: { [key: string]: Ticket };
  index: string[];
  order?: string[];
  error?: boolean;
}

export enum TicketType {
  Funding = 'Funding',
  Withdrawal = 'Withdrawal',
  BankAccount = 'BankAccount',
  Ckacar = 'Ckacar',
  Rewards = 'Rewards',
}

export enum FundingType {
  UEN = 'UEN',
  QR = 'PNQR',
  FAST = 'FAST',
  FASA = 'FASA',
  TT = 'TT'
}

export enum TicketStatus {
  Approved = 'AP', // D, W, BA, CKA
  Rejected = 'RE', // D, W, BA, CKA
  Pending = 'PE', // D, W, BA, CKA
  Processing = 'PR', // D
  Expired = 'EX', // D
  ProcessingException = 'US', // D, W Unsuccessful, something went wrong in our backend processing
  BankAccountDeleted = 'DE', // BA (deleted)
  NullVoid = 'NV', // CKA, nullvoid (CkacarTicketStatus.NullVoid), form is invalid
  Unsuccessful = 'UN', // CKA, submitted ticket was marked as failed by internal system
}

export const reviewedTicketStatuses: Array<string | TicketStatus> = [
  TicketStatus.Approved,
  TicketStatus.Rejected,
];

export enum BankAccountType {
  Add = 'CR', // Create
  Remove = 'DE' // Delete
}

export { Error as RemoveBankAccountTicketError } from './bankAccount/removeBankAccountTicket';
