import { prefersDarkTheme } from '@staizen/graphene';
import { store } from '../config/configureStore';
import { Theme } from '../config/configureTheme';

export const getDefaultTheme = (): string => {
  return prefersDarkTheme().matches ? Theme.Dark : Theme.Light;
};

export const getTheme = (): string => {
  const user = store?.getState()?.user;
  const theme = user?.preferences?.theme ?? getDefaultTheme();
  return theme;
};
