import { DateTimeService } from '@staizen/graphene';
import { TicketStatus, TicketStatusHistory, TicketStatusHistoryItem } from '../types';
import { AxiosResponse } from 'axios';
import { store } from '../../../config/configureStore';
import { getClientPortalService } from '../../../config/configureApi';
import { getQueryParams } from '@staizen/utils/dist/URLUtils';
import { Currency } from '../../../templates/Funding/widgets/ListFundsCardWidget/constants';

const { DateTime } = DateTimeService;

type FundingType = 'UEN' | 'PNQR' | 'FAST' | 'TT';
type RequestStatus = 'AP' | 'PE' | 'RE' | 'PR' | 'EX' | 'US';

interface FundingRequestHistoryItem {
  requestUID: string;
  status: string | TicketStatus;
  createdTime: string;
}

interface ResponseItem {
  requestUID: string;
  userId: string;
  currency: string;
  requestStatus: RequestStatus;
  fundingType: FundingType;
  amount: string;
  referenceNo: string | null; // FAST or TT only
  createdTime: string;
  lastUpdatedTime: string | null;
  fundingRequestHistory: FundingRequestHistoryItem[];
}
// AP / Approved, PE / Pending, RE / Rejected, PR / Processing, EX / Expired, US / Unsuccessful
const getFundingTicketStatusHistory = async (id: string) => {
  const { config } = store.getState();
  const api = getClientPortalService();
  const response = await (config.useMock ? new Promise((res) => {
    const queryParams = getQueryParams(window.location.search, true) as { mockTicketStatus?: RequestStatus; mockFundingType?: FundingType; mockCurrency?: Currency };
    setTimeout(() => {
      res(
        {
          requestUID: id,
          userId: 'ernest.cai',
          currency: queryParams.mockCurrency || 'SGD',
          fundingType: queryParams.mockFundingType || 'PNQR',
          amount: '300',
          requestStatus: queryParams.mockTicketStatus || 'AP',
          referenceNo: 'fast-tt-ref-no', // FAST or TT only
          createdTime: '2020-07-10T08:58:00.000Z',
          lastUpdatedTime: '2020-07-10T08:59:00.000Z',
          fundingRequestHistory: [
            {
              requestUID: id,
              status: 'PE',
              createdTime: '2020-07-10T08:58:00.000Z',
            },
            {
              requestUID: id,
              status: 'AP',
              createdTime: '2020-07-10T08:59:00.000Z',
            },
            {
              requestUID: id,
              status: 'RE',
              createdTime: '2020-07-10T08:58:00.000Z',
            },
            {
              requestUID: id,
              status: 'US',
              createdTime: '2020-07-10T08:59:00.000Z',
            },
            {
              requestUID: id,
              status: 'EX',
              createdTime: '2020-07-10T08:59:00.000Z',
            },
          ],
        },
      );
    }, 5000);
  }) : api.get(config.endpoints.funding.readTicket, {
    params: {
      requestUID: id,
    },
  }).then((apiResponse: AxiosResponse) => {
    return apiResponse.data;
  })) as ResponseItem;

  return {
    id: response.requestUID,
    type: 'Funding',
    lastUpdatedTime: DateTime.fromISO(response.lastUpdatedTime),
    createdTime: DateTime.fromISO(response.createdTime),
    status: response.requestStatus,
    statusHistory: response.fundingRequestHistory.map((item: FundingRequestHistoryItem) => {
      return {
        transactionId: item.requestUID,
        status: item.status,
        createdTime: DateTime.fromISO(item.createdTime),
      } as TicketStatusHistoryItem;
    }),
    details: {
      userId: response.userId,
      currency: response.currency,
      fundingType: response.fundingType,
      amount: response.amount,
      referenceNo: response.referenceNo,
    },
  } as TicketStatusHistory;
};

export default getFundingTicketStatusHistory;
