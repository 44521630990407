import { css } from 'emotion';

export default {
  wrapper: {
    base: css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      minHeight: 'calc(100vh - var(--safe-area-top))',
      width: 'min(520px, 100%)',
      padding: 'var(--stz-space-l) var(--stz-space-l) var(--stz-space-m)',
      margin: 'auto',
    }),
  },
  logo: css({
    maxHeight: 100,
    maxWidth: 'min(643.45px, 100%)',
  }),
  supportText: css({
    marginTop: 'var(--stz-space-xs)',
    whiteSpace: 'pre-line',
  }),
};
