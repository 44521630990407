import { getClientPortalService } from '../../config/configureApi';

export const aggregatePagination = async (endpoint: string, params: object = {}) => {
  const recordPerPage = 100;
  const api = getClientPortalService();
  const response = await api.get(endpoint, {
    params: {
      ...params,
      recordPerPage,
    },
  });
  let { data } = response;
  const { headers: { total } } = response;
  if (!total) {
    return data;
  }
  // Map through an array that is same length as page numbers to iterate through,
  // and return an array of promises, awaiting promises to be done before returning the final data
  const totalPages = Math.max(total - 1, 0) || 0; // guard against total = 0 (empty) or undefined
  await Promise.all(Array.from(Array(totalPages)).map((_, index) => async () => {
    const pageNumber = index + 1;
    const pageResponse = await api.get(endpoint, {
      params: {
        recordPerPage,
        ...params,
        pageNumber,
      },
    });
    data = data.concat(pageResponse.data);
  }));
  return data;
};
