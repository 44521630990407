import { registerPlugin } from '@capacitor/core';

interface ProsperUsPlugin {
  showNavBarOnNextPageLoad(options: {
    backLabel?: string;
    backgroundColor: { r: number; g: number; b: number; alpha: number };
    foregroundColor: { r: number; g: number; b: number; alpha: number };
  }): Promise<null>;
}

const ProsperUsPlugin = registerPlugin<ProsperUsPlugin>('ProsperUsPlugin');

export default ProsperUsPlugin;
