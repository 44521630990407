import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { store } from '../../config/configureStore';
import { getClientPortalService } from '../../config/configureApi';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import { PreferenceType } from '../../actions/creators/user';
import { logoutUser } from '../auth';

type SaveUserPreferenceParams = {
  key: PreferenceType;
}

type SaveUserPreferenceResponse = {
  success: boolean;
  error?: string;
  errorCode?: string;
  payload?: {
    value: any;
  };
};

/* On initial save
  {
    createdTime: 1626363269123
    lastUpdatedTime: 1626363269123
    preferenceValue: "{\"theme\":\"light\"}"
    userPreferenceId: {userId: "kevinng", preferenceKey: "preferences"}
  }
*/

const saveUserPreference = async (params: SaveUserPreferenceParams) => {
  const { user } = store.getState();
  const { key } = params;
  const preSerializedValue = user[key];
  if (!isPlainObject(preSerializedValue) && !isArray(preSerializedValue)) {
    return {
      success: false,
      error: 'UNABLE_TO_SERIALIZE',
      payload: {
        preSerializedValue,
      },
    };
  }
  console.log('saving...', preSerializedValue, key);
  const preferenceValue = JSON.stringify(preSerializedValue);
  const savePreferenceResponse = await save(key, preferenceValue);
  return savePreferenceResponse;
};

// function is currently only used for testing
// reset all preferences
export const resetUserPreferences = async () => {
  await Promise.all([
    save('dashboards', null),
    save('coachMark', null),
    save('dismissedPrompts', null),
    save('preferences', null),
  ]);
  logoutUser();
  return true;
};

const save = async (key: string, value: any) => {
  const api: AxiosInstance = getClientPortalService();
  const { config } = store.getState();
  const savePreferenceResponse = await (
    !config.useMock
      ? api.post(config.endpoints.userPreferences.save, {
        preferenceKey: key,
        preferenceValue: value,
      })
        .then((apiResponse: AxiosResponse) => {
          console.log('saved...', value, key);
          return {
            success: apiResponse.data,
            payload: {
              value,
            },
          };
        }).catch((error: AxiosError) => {
          const { response: apiResponse } = error;
          const errorCode = String(apiResponse.status);
          let errorStatus = 'GENERIC_ERROR';
          const success = false;
          if (apiResponse && apiResponse.status === 400) {
            errorStatus = 'KEY_NOT_VALID';
          }
          return {
            success,
            errorCode,
            errorStatus,
            payload: {
              value,
            },
          } as SaveUserPreferenceResponse;
        })
      : new Promise<SaveUserPreferenceResponse>((res) => {
        setTimeout(() => {
          res({
            success: true,
            payload: {
              value,
            },
          });
        }, 200);
      })
  );
  return savePreferenceResponse;
};

export default saveUserPreference;
