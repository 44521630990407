import { DateTimeService } from '@staizen/graphene';
import { W8Status } from '../../actions/creators/user';
import { Ew8FormStatus, getEw8Status } from '../w8';

import {
  DATE_FORMAT,
  PRIVATE_WEALTH_ACCOUNT_TYPE,
  W8_EXPIRING_MAX_MONTHS,
} from './constants';
import { Account, UserParticulars } from './types';

const { DateTime } = DateTimeService;

export const getW8Status = (
  w8ExpiryDateTime: DateTimeService.DateTime,
  { ew8Status }: { ew8Status: Ew8FormStatus },
): W8Status => {
  const today = DateTime.fromJSDate(new Date());

  if (ew8Status === Ew8FormStatus.UNREADY) {
    return W8Status.UNREADY;
  }

  if (!w8ExpiryDateTime) {
    return ew8Status === Ew8FormStatus.SUCCESS ? W8Status.PENDING : W8Status.UNDECLARED;
  }

  const isExpired = today > w8ExpiryDateTime;
  if (isExpired) {
    return ew8Status === Ew8FormStatus.SUCCESS ? W8Status.PENDING : W8Status.EXPIRED;
  }

  // Not able to catch if W8 is pending manual verification is current W8 is still valid
  const diffInMonths = w8ExpiryDateTime.diff(today, 'months').toObject().months;
  if (diffInMonths <= W8_EXPIRING_MAX_MONTHS) {
    return W8Status.EXPIRING;
  }

  return W8Status.VALID;
};

export const transformUserParticulars = async (
  userParticulars: UserParticulars,
) => {
  const { birthday, ew8ExpiryDate1, ew8ExpiryDate2, saxoId } = userParticulars;

  const birthdayDateTime = birthday ? DateTime.fromFormat(birthday, DATE_FORMAT) : null;
  const w8ExpiryDate1DateTime = ew8ExpiryDate1 // its actually no ew8 but w8 expiry see README
    ? DateTime.fromFormat(ew8ExpiryDate1, DATE_FORMAT)
    : null;
  const w8ExpiryDate2DateTime = ew8ExpiryDate2 // its actually no ew8 but w8 expiry see README
    ? DateTime.fromFormat(ew8ExpiryDate2, DATE_FORMAT)
    : null;

  const { status: ew8Status } = await getEw8Status(w8ExpiryDate1DateTime);
  const w8Status = getW8Status(w8ExpiryDate1DateTime, { ew8Status });

  const hasTradingAccountLinked = saxoId !== null && saxoId !== undefined;

  return {
    ...userParticulars,
    birthday: birthdayDateTime,
    w8ExpiryDate1: w8ExpiryDate1DateTime,
    w8ExpiryDate2: w8ExpiryDate2DateTime,
    w8Status,
    hasTradingAccountLinked,
  };
};

export const isAccountPrivateWealth = (account: Account) => {
  return account.accountType === PRIVATE_WEALTH_ACCOUNT_TYPE;
};
