import { registerLocaleLoader } from '@staizen/graphene';
import { faChevronUp } from '@staizen/graphene/dist/icons/solid';
import { StzButton, StzHeaderMenu, StzIcon } from '@staizen/graphene-react';
import { css } from 'emotion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import retry from '../../utils/retry';
import { TAG } from './constants';

const styles = {
  base: css({
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  titleWrapper: css({
    display: 'inline-block',
    position: 'absolute',
    top: 'var(--stz-space-xs)',
    padding: 'var(--stz-space-xs)',
    height: 'var(--stz-space-l)',
    width: 'var(--stz-space-l)',
    backgroundColor: 'var(--stz-header-usersection-user-background)',
    borderRadius: '50%',
    textAlign: 'center',
  }),
  user: css({
    position: 'relative',
    color: 'var(--stz-header-usersection-content-font-color)',
    font: 'var(--stz-header-usersection-content-font-text)',
  }),
  username: css({
    display: 'block',
    marginLeft: 40,
    marginRight: 'var(--stz-space-l)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: 'var(--stz-header-usersection-username-font-color)',
    font: 'var(--stz-header-usersection-username-font-text)',
  }),
};

registerLocaleLoader(TAG, (locale: string) => retry(() => import(`./locale.${locale}.json`)));

const HeaderProfileMenu = () => {
  const { t } = useTranslation(TAG);
  const profileMenu = useSelector((state) => state?.menu?.profile || { title: '', menu: [] });
  const username = useSelector((state) => state?.user?.particulars?.data?.name || t('profileMenu.defaultTitle'));
  const selectedUrl = useSelector((state) => state?.menu?.selectedUrl || '');
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const onHeaderItemToggleClick = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  return (
    <div
      slot="widgets"
      className="stz-header-item stz-item--has-menu stz-header-usersection-item"
    >
      <div className={styles.base}>
        <div className="stz-header-link" onClick={onHeaderItemToggleClick}>
          <div className={styles.titleWrapper}>
            <div className={styles.user}>{username && username[0]}</div>
          </div>
          <span className={styles.username}>{username}</span>
        </div>
        <StzButton
          className="stz-header-item-toggle"
          preset="low-contrast"
          size="small"
          ripple={false}
          square
          onClick={onHeaderItemToggleClick}
        >
          <StzIcon
            className="stz-header-item-toggle-icon"
            icon={faChevronUp}
            rotation={isProfileMenuOpen ? null : 180}
          />
        </StzButton>
      </div>
      <StzHeaderMenu
        menu={profileMenu.menu}
        isOpen={isProfileMenuOpen}
        level={2}
        selectedUrl={selectedUrl}
      />
    </div>
  );
};

export default HeaderProfileMenu;
