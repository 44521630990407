import { TicketMap } from '../types';
import { DateTimeService } from '@staizen/graphene';
import { store } from '../../../config/configureStore';
import { aggregatePagination } from '../helper';

const { DateTime } = DateTimeService;

interface ResponseItem {
  requestUID: string;
  currency: string;
  bankName: string;
  bankAccount: string;
  swiftCode: string;
  amount: string;
  status: string;
  userId: string;
  lastUpdatedTime: string | null;
}

// AP / Approved, PE / Pending, RE / Rejected, PR / Processing, EX / Expired, US / Unsuccessful
const getWithdrawalTickets = async () => {
  const { config } = store.getState();
  const response = await (config.useMock ? new Promise((res) => {
    setTimeout(() => {
      res([
        {
          requestUID: 'with1',
          currency: 'SGD',
          amount: '1000000',
          status: 'PE',
          userId: 'kev',
          lastUpdatedTime: '2020-04-10T01:33:00.000Z',
          bankAccount: 'k bank account',
          bankName: 'DBS',
          swiftCode: 'DBSSSG',
        },
        {
          requestUID: 'with2',
          currency: 'SGD',
          amount: '1000000',
          status: 'PE',
          userId: 'kev',
          lastUpdatedTime: '2020-07-10T05:08:13.000Z',
          bankAccount: 'k bank account',
          bankName: 'CIMB',
          swiftCode: 'CIMBSSSG',
        },
      ]);
    }, 1000);
  }) : aggregatePagination(config.endpoints.withdrawal.readTickets)) as ResponseItem[];

  const tickets = response.reduce((acc: TicketMap, item) => {
    acc.index.push(item.requestUID);
    acc.data[item.requestUID] = {
      id: item.requestUID,
      type: 'Withdrawal',
      status: item.status,
      lastUpdated: DateTime.fromISO(item.lastUpdatedTime),
      details: {
        currency: item.currency,
        bankAccount: item.bankAccount,
        bankName: item.bankName,
        swiftCode: item.swiftCode,
        amount: item.amount,
        userId: item.userId,
      },
    };
    return acc;
  }, { data: {}, index: [] });
  return tickets;
};

export default getWithdrawalTickets;
