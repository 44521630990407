/* eslint-disable react/jsx-props-no-spreading */
import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { css } from 'emotion';

import Header from '../components/Header';
import BasicHeader from '../components/Demo/BasicHeader/BasicHeader';
import { smDown } from '../hooks/useBreakpoints';
import Error from '../templates/Error/Error';
import retry from '../utils/retry';
import { LINKS } from './configureLinks';
// import TestIdentityVault from '../templates/Login/BiometricAuth/TestIdentityVault';

const Account = lazy(() => retry(() => import('../templates/Account/Account')));
const Actions = lazy(() => retry(() => import('../templates/Actions/Actions')));
const Dashboard = lazy(() => retry(() => import('../templates/Dashboard/Dashboard')));
const Funding = lazy(() => retry(() => import('../templates/Funding/Funding')));
const InitialLoginPrompts = lazy(() => retry(() => import('../components/Dialogs/InitialLoginPrompts')));
const Commentary = lazy(() => retry(() => import('../templates/Commentary/Commentary')));
const Settings = lazy(() => retry(() => import('../templates/Settings/Settings')));
const Faq = lazy(() => retry(() => import('../templates/Help/Faq/index')));
const PopupFaq = lazy(() => retry(() => import('../templates/Help/Faq/PopupFaq')));
const ContactUs = lazy(() => retry(() => import('../templates/Help/ContactUs')));
const Login = lazy(() => retry(() => import('../templates/Login/Login')));
const ConnectedBasicHeader = connect((state: any) => ({ data: state.pageData }))(BasicHeader);
const Products = lazy(() => retry(() => import('../templates/Products/Products')));

const accomodateMainMenu = css`
  margin-left: 240px;
  @media ${smDown} {
    margin-left: 0;
  }
`;

export const faqQuestionRouteExpression = `${LINKS.HELP_FAQ}/:tabId/:faqId`;

const routes = (t: Function) => {
  return (
    <main>
      <Suspense fallback={(null)}>
        <Header />
      </Suspense>
      <div className={accomodateMainMenu}>
        <header>
          <Suspense fallback={(null)}>
            <Switch>
              <Route path="*" component={ConnectedBasicHeader} />
            </Switch>
          </Suspense>
        </header>
        <article>
          <Suspense fallback={(null)}>
            <InitialLoginPrompts />
            <Switch>
              {/* TestIdentityVault for debugging purposes only */}
              {/* <Route exact path={[LINKS.ROOT, '/test-identity-vault']}
                render={() => <TestIdentityVault />} /> */}
              <Route exact path={[LINKS.ROOT, LINKS.DASHBOARD]} render={(props) => <Dashboard {...props} tabName={t('pages.tabNames.dashboard')} title={t('pages.titles.dashboard')} />} />
              <Route exact path={`${LINKS.ACCOUNT}/:tabId?`} render={(props) => <Account {...props} title={t('pages.titles.account')} />} />
              <Route exact path={`${LINKS.FUNDING}/:tabId?`} render={(props) => <Funding {...props} title={t('pages.titles.funding')} />} />
              <Route exact path={LINKS.ACTIONS} render={(props) => <Actions {...props} title={t('pages.titles.actions')} />} />
              <Route exact path={LINKS.INVESTMENT_INSIGHTS} render={(props) => <Commentary {...props} title={t('pages.titles.commentary')} />} />
              <Route exact path={`${LINKS.SETTINGS}/:tabId?`} render={(props) => <Settings {...props} title={t('pages.titles.settings')} />} />
              <Route exact path={LINKS.PRODUCTS} render={(props) => <Products {...props} title={t('pages.titles.products')} />} />
              <Route exact path={LINKS.HELP} render={(props) => <Faq {...props} title={t('pages.titles.help.faq')} />} />
              <Route exact path={LINKS.HELP_FAQ_POPUPS} render={(props) => <PopupFaq {...props} title={t('pages.titles.help.faq')} />} />
              <Route exact path={`${LINKS.HELP_FAQ}/:tabId?/:faqId?`} render={(props) => <Faq {...props} title={t('pages.titles.help.faq')} />} />
              <Route exact path={LINKS.HELP_CONTACT_US} render={(props) => <ContactUs {...props} title={t('pages.titles.help.contactUs')} />} />
              <Route exact path={LINKS.LOGIN} render={(props) => <Login {...props} title={t('pages.titles.login')} />} />
              <Route
                path="/50*"
              >
                <Error
                  code="500"
                  title={t('pages.titles.errors.500')}
                  links={[
                    {
                      title: t('menu.titles.dashboard'),
                      url: LINKS.DASHBOARD,
                    },
                    {
                      title: t('menu.titles.funding'),
                      url: LINKS.FUNDING,
                    },
                    {
                      title: t('menu.titles.actions'),
                      url: LINKS.ACTIONS,
                    },
                    {
                      title: t('menu.titles.commentary'),
                      url: LINKS.INVESTMENT_INSIGHTS,
                    },
                    {
                      title: t('menu.titles.help'),
                      url: LINKS.HELP,
                    },
                  ]}
                />
              </Route>
              <Route path={['*', LINKS.ERROR_404]}>
                <Error
                  code="404"
                  title={t('pages.titles.errors.404')}
                  links={[
                    {
                      title: t('menu.titles.dashboard'),
                      url: LINKS.DASHBOARD,
                    },
                    {
                      title: t('menu.titles.funding'),
                      url: LINKS.FUNDING,
                    },
                    {
                      title: t('menu.titles.actions'),
                      url: LINKS.ACTIONS,
                    },
                    {
                      title: t('menu.titles.commentary'),
                      url: LINKS.INVESTMENT_INSIGHTS,
                    },
                    {
                      title: t('menu.titles.help'),
                      url: LINKS.HELP,
                    },
                  ]}
                />
              </Route>
            </Switch>
          </Suspense>
        </article>
      </div>
    </main>
  );
};
export default routes;
