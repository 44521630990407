import { DateTimeService } from '@staizen/graphene';
import { store } from '../../config/configureStore';
import { aggregatePagination } from '../ticket/helper';
import { TicketMap } from '../ticket/types';

const { DateTime } = DateTimeService;

interface ResponseItem {
  requestId: string;
  userId: string;
  rewardType: string;
  rewardTitle: string;
  accountNo: string;
  status: string;
  redeemAmount: number;
  lastUpdatedTime: string | null;
}

const getMyRewardsHistory = async () => {
  const { config } = store.getState();
  const response = await (config.useMock ? new Promise((res) => {
    setTimeout(() => {
      res([
        {
          requestId: 'RR00000038',
          userId: 'test',
          rewardType: 'RW002',
          rewardTitle: 'SNACK',
          accountNo: '3333321',
          status: 'PE',
          redeemAmount: 10,
          lastUpdatedTime: '2022-11-25T09:33:12.120+08:00',
        },
        {
          requestId: 'RR00000023',
          userId: 'test',
          rewardType: 'RW002',
          rewardTitle: 'SNACK',
          accountNo: '3333321',
          status: 'RE',
          redeemAmount: 20,
          lastUpdatedTime: '2022-11-24T00:17:45.517+08:00',
        },
        {
          requestId: 'RR00000020',
          userId: 'test',
          rewardType: 'RW002',
          rewardTitle: 'SNACK',
          accountNo: '3333321',
          status: 'RE',
          redeemAmount: 40,
          lastUpdatedTime: '2022-11-24T00:06:34.377+08:00',
        },
        {
          requestId: 'RR00000021',
          userId: 'test',
          rewardType: 'RW002',
          rewardTitle: 'SNACK',
          accountNo: '3333321',
          status: 'RE',
          redeemAmount: 40,
          lastUpdatedTime: '2022-11-24T00:06:24.650+08:00',
        },
        {
          requestId: 'RR00000019',
          userId: 'test',
          rewardType: 'RW002',
          rewardTitle: 'SNACK',
          accountNo: '3333321',
          status: 'RE',
          redeemAmount: 20,
          lastUpdatedTime: '2022-11-23T23:51:31.987+08:00',
        },
        {
          requestId: 'RR00000018',
          userId: 'test',
          rewardType: 'RW002',
          rewardTitle: 'SNACK',
          accountNo: '3333321',
          status: 'RE',
          redeemAmount: 40,
          lastUpdatedTime: '2022-11-23T23:51:19.110+08:00',
        },
        {
          requestId: 'RR00000017',
          userId: 'test',
          rewardType: 'RW002',
          rewardTitle: 'SNACK',
          accountNo: '3333321',
          status: 'RE',
          redeemAmount: 40,
          lastUpdatedTime: '2022-11-23T23:21:03.973+08:00',
        },
        {
          requestId: 'RR00000015',
          userId: 'test',
          rewardType: 'RW002',
          rewardTitle: 'SNACK',
          accountNo: '3333321',
          status: 'AP',
          redeemAmount: 50,
          lastUpdatedTime: '2022-11-23T23:08:04.857+08:00',
        },
        {
          requestId: 'RR00000016',
          userId: 'test',
          rewardType: 'RW002',
          rewardTitle: 'SNACK',
          accountNo: '3333321',
          status: 'AP',
          redeemAmount: 20,
          lastUpdatedTime: '2022-11-23T23:05:47.143+08:00',
        },
        {
          requestId: 'RR00000010',
          userId: 'test',
          rewardType: 'RW002',
          rewardTitle: 'SNACK',
          accountNo: '3333321',
          status: 'RE',
          redeemAmount: 60,
          lastUpdatedTime: '2022-11-23T22:57:31.000+08:00',
        },
        {
          requestId: 'RR00000011',
          userId: 'test',
          rewardType: 'RW002',
          rewardTitle: 'SNACK',
          accountNo: '3333321',
          status: 'AP',
          redeemAmount: 20,
          lastUpdatedTime: '2022-11-22T01:06:43.833+08:00',
        },
        {
          requestId: 'RR00000003',
          userId: 'test',
          rewardType: 'RW002',
          rewardTitle: 'SNACK',
          accountNo: '3333321',
          status: 'RE',
          redeemAmount: 20,
          lastUpdatedTime: '2022-11-21T23:41:22.437+08:00',
        },
        {
          requestId: 'RR00000005',
          userId: 'test',
          rewardType: 'RW002',
          rewardTitle: 'SNACK',
          accountNo: '3333321',
          status: 'AP',
          redeemAmount: 20,
          lastUpdatedTime: '2022-11-21T23:20:22.873+08:00',
        },
        {
          requestId: 'RR00000002',
          userId: 'test',
          rewardType: 'RW002',
          rewardTitle: 'SNACK',
          accountNo: '3333321',
          status: 'RE',
          redeemAmount: 40,
          lastUpdatedTime: '2022-11-21T23:19:05.987+08:00',
        },
        {
          requestId: 'RR00000008',
          userId: 'test',
          rewardType: 'RW002',
          rewardTitle: 'SNACK',
          accountNo: '3333321',
          status: 'AP',
          redeemAmount: 20,
          lastUpdatedTime: '2022-11-21T23:11:25.787+08:00',
        },
        {
          requestId: 'RR00000007',
          userId: 'test',
          rewardType: 'RW002',
          rewardTitle: 'SNACK',
          accountNo: '3333321',
          status: 'RE',
          redeemAmount: 20,
          lastUpdatedTime: '2022-11-21T22:24:14.647+08:00',
        },
        {
          requestId: 'RR00000006',
          userId: 'fultontest',
          rewardType: 'RW002',
          rewardTitle: 'SNACK',
          accountNo: '3333321',
          status: 'AP',
          redeemAmount: 20,
          lastUpdatedTime: '2022-11-19T16:17:15.637+08:00',
        },
      ]);
    }, 1000);
  }) : aggregatePagination(config.endpoints.rewards.history)) as ResponseItem[];

  const tickets = response.reduce((acc: TicketMap, item) => {
    acc.index.push(item.requestId);
    acc.data[item.requestId] = {
      id: item.requestId,
      type: 'Rewards',
      status: item.status,
      lastUpdated: DateTime.fromISO(item.lastUpdatedTime),
      details: {
        rewardType: item.rewardType,
        rewardTitle: item.rewardTitle,
        redeemAmount: item.redeemAmount,
        accountNo: item.accountNo,
      },
    };
    return acc;
  }, { data: {}, index: [] });
  return tickets;
};

export default getMyRewardsHistory;
