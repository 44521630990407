import { AxiosError, AxiosResponse } from 'axios';
import { store } from '../../config/configureStore';
import { getClientPortalService } from '../../config/configureApi';
import { SendSmsOtpResponse } from './types';

interface SendSmsOtpParams {
  mobileNo: string;
}
// resp.status === 404 cannot find mobile number to send to
// mobileNo
const sendSmsOtp = async (mobileNo: string) => {
  const { config } = store.getState();
  const api = getClientPortalService();
  // Timeout for delay
  const response = !config.useMock
    ? await (api.post(config.endpoints.sendSmsOtp, ({
      mobileNo,
    } as SendSmsOtpParams)) as any)
      .then((res: AxiosResponse) => { return res; })
      .catch((err: AxiosError) => {
        return err.response;
      })
    : await new Promise((res) => setTimeout(() => {
      res({
        data: {
          challenge: 'mock challenge',
          response: 'mock user id',
          // securityCharCode: 'abcd',
          success: true,
          // Uncomment the following for erroneous response
          // challenge: undefined,
          // securityCharCode: undefined,
          // success: undefined,
          // response: undefined,
          // message: 'error.smsotp.sendfailed',
          // status: 'SERVICE_UNAVAILABLE',
          // timestamp: 1622189433577,
        } as SendSmsOtpResponse,
        status: 200,
        statusText: 'OK',
      });
    }, 1000));

  return response;
};

export default sendSmsOtp;
