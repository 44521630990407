export interface SessionTokenResponse {
  tokenType: string;
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
}

export interface GetEncryptionKeyResponse {
  e2EESessionId: string;
  e2EERandomNum: string;
  e2EEPublicKey: string;
}

type smsOtp = {
  challenge?: string; // 'DgXPyWKXJbqTRpZiEIfXvGfuqbpUHa=='
  response?: string; // undefined or if error: e.g. 'error.smsotp.sendfailed', 'error.smsotp.connfailed'
  securityCharCode?: string; // 'sUqz'
  success?: boolean; // true or false
}
export interface AuthenticateResponse {
  userId: string;
  name: string;
  maskMobileNo?: null | string;
  smsOTP?: null | smsOtp;
  token: object;
  ssoCallback: string;
  oauthCode: string;
  twoFAEnabled: boolean;
  verifiedOTP: false; // always falsed, unused
  twoFALinked: boolean;
}

export enum OTPErrorResponse {
  INVALID_OTP = 'error.smsotp.invalid',
  CHALLENGE_CANCELLED = 'error.invalid.2fa.challenge',
}

export enum OTPError {
  INVALID_OTP = 'INVALID_OTP',
  CHALLENGE_CANCELLED = 'CHALLENGE_CANCELLED',
  BAD_REQUEST = 'BAD_REQUEST',
}

export interface SendLoginSmsOtpRequest {
  challenge: string;
  userId: string;
}

export interface SendLoginSmsOtpResponse {
  challenge?: string;
  response?: string;
  securityCharCode?: string;
  success?: true | undefined;
  message?: string; // e.g. 'error.smsotp.sendfailed', 'error.smsotp.connfailed'
  status?: string;
  timestamp?: number;
}

export interface SendSmsOtpResponse {
  challenge?: string;
  response?: string;
  securityCharCode?: string;
  success?: true | undefined; // undefined if error
  message?: string; // e.g. 'error.smsotp.sendfailed', 'error.smsotp.connfailed'
  status?: string;
  timestamp?: number;
}

export interface ValidateLoginSmsOtpRequest {
  challenge: string;
  isUsed: boolean;
  twoFaData: string;
  userId: string;
  whiteListDomain: string;
}

export interface ValidateLoginSmsOtpResponse {
  maskMobileNo: null;
  name: string;
  oauthCode: string;
  smsOTP: null;
  ssoCallback: string ;// "https://dev.portal.prosperus.asia"
  token: object; // redundant
  twoFAEnabled: boolean;
  twoFALinked: boolean;
  userId: string; // "kevinng"
  verifiedOTP: true | undefined; // returns error object if undefined
  message?: string; // e.g. 'error.invalid.2fa.challenge'
}
