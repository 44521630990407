import { AUTH_SET_TOKEN, AUTH_UPDATE_TOKEN } from '../constants/auth';
import { DateTimeService } from '@staizen/graphene';
import { getSessionToken } from '../../api/auth';

export interface AuthStore extends UiState {
  data: {
    tokenType: string;
    accessToken: string;
    refreshToken: string;
    sessionTimeout: number;
  };
  _persist?: {
    rehydrated: boolean;
  };
}

const { DateTime } = DateTimeService;

export function setToken(token: any): Action {
  // NOTE - we can also calculate as a % of expiresIn here if required
  // eslint-disable-next-line no-param-reassign
  token.data.tokenExpiryDateTime = DateTime.fromJSDate(new Date())
    .plus({ seconds: token.data?.expiresIn })
    .toISO();
  return {
    type: AUTH_SET_TOKEN,
    payload: {
      ...token,
    },
  };
}

export function updateToken(token: any): Action {
  // NOTE - we can also calculate as a % of expiresIn here if required
  // eslint-disable-next-line no-param-reassign
  token.data.tokenExpiryDateTime = DateTime.fromJSDate(new Date())
    .plus({ seconds: token.data?.expiresIn })
    .toISO();
  return {
    type: AUTH_UPDATE_TOKEN,
    payload: {
      ...token,
    },
  };
}

export const getToken = (sso: string, useMock = true) => (dispatch: Function) => {
  dispatch(setToken({
    data: { accessToken: undefined, refreshToken: undefined, sessionTimeout: undefined },
    ui: { isFetching: true },
  })); // clear previous token
  return getSessionToken(sso, useMock)
    .then((token: object) => {
      return dispatch(setToken({
        data: token,
        ui: { isFetching: false, isError: false },
      }));
    });
};
