import { AxiosResponse } from 'axios';
import { store } from '../../config/configureStore';
import { getClientPortalService } from '../../config/configureApi';
import { GetEncryptionKeyResponse, AuthenticateResponse } from './types';

interface AuthenticatePayload {
  adDomain: string;
  e2EEPublicKey: string;
  e2eeRandom: string;
  e2eeSession: string;
  password: string;
  sessionValue?: string;
  userId: string;
  whiteListDomain: string;
}

export const getEncryptionKey = async () => {
  const { config } = store.getState();
  const api = getClientPortalService();
  const data: GetEncryptionKeyResponse = await api({
    method: 'get',
    url: config.endpoints.login,
    params: {
      bypassInterceptor: true,
    },
  }).then((response: AxiosResponse) => {
    return response.data;
  });

  return data;
};

// const addEnquiry = async (params: AddEnquiryParams): Promise<TransformedAddEnquiryResponse> => {
export const authenticate = async (data: AuthenticatePayload): Promise<AuthenticateResponse> => {
  const { config } = store.getState();
  const api = getClientPortalService();

  return config.useMock
    ? Promise.resolve({
      userId: 'kevinng',
      name: null,
      maskMobileNo: '1234',
      smsOTP: {
        securityCharCode: 'ThgO',
        challenge: 'raVearutnwycVLnEEUFmWTjdzerSKG==',
        response: '',
        success: true,
        // Uncomment the following to mock erroneous response
        // success: undefined,
        // response: 'error.smsotp.sendfailed',
      },
      token: null,
      ssoCallback: null,
      oauthCode: null,
      twoFALinked: true,
      twoFAEnabled: true,
      verifiedOTP: false,
    } as any)
    : api({
      method: 'post',
      url: config.endpoints.login,
      params: {
        bypassInterceptor: true,
      },
      data,
    }).then((response: AxiosResponse) => {
      return response.data;
    });
};
