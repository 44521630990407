import React from 'react';
import { useStore } from 'react-redux';
import { faDownload } from '@staizen/graphene/dist/icons/regular';
import { registerLocaleLoader } from '@staizen/graphene';
import { StzStatus, StzButton, StzIcon } from '@staizen/graphene-react';
import { PreNormalizedIcon } from '@staizen/graphene/dist/types/utils/icons';
import retry from '../../utils/retry';
import { useTranslation } from 'react-i18next';
import { TAG } from './constants';
import { styles } from './styles';
import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';

registerLocaleLoader(TAG, (locale: string) => retry(() => import(`./locale.${locale}.json`)));

interface NonRequiredUpdateProps {
  onDismiss: () => void;
}


const NonRequiredUpdate: React.FC<NonRequiredUpdateProps> = ({ onDismiss }) => {
  const { t } = useTranslation(TAG);
  const store = useStore();
  const openStore = async (): Promise<void> => {
    try {
      const { config } = store.getState();
      const info = await Device.getInfo();
      const platform = info.platform.toLowerCase(); // Get the platform name ( "android", "ios")

      const storeUrls = {
        android: config.links.playStoreApp,
        ios: config.links.appStoreApp,
      };

      return platform === 'android' ? await Browser.open({ url: storeUrls.android }) : await Browser.open({ url: storeUrls.ios });
    } catch (error) {
      console.error('Error opening store URL:', error);
    }
  };

  return (
    <div className={styles.wrapper.base}>
      <StzStatus
        inline
        layout="vertical"
        background="b"
        size="medium"
        subject={t('nonRequiredUpdate.title')}
        className={styles.stzStatus}
      >
        <StzIcon slot="logo" icon={faDownload as PreNormalizedIcon} />
        <span slot="support-text" className={styles.supportText}>
          {t('nonRequiredUpdate.content')}
        </span>
        <StzButton slot="cta-1" variant="outlined" size="small" onClick={onDismiss}>
          <span>{t('nonRequiredUpdate.buttons.cancel')}</span>
        </StzButton>
        <StzButton slot="cta-1" size="small" onClick={openStore}>
          <span>{t('nonRequiredUpdate.buttons.confirm')}</span>
        </StzButton>
      </StzStatus>
    </div>
    );
};

export default NonRequiredUpdate;
