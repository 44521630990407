import { css } from 'emotion';

export const styles = {
  wrapper: {
    base: css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      minHeight: 'calc(100vh - var(--safe-area-top))',
      width: 'min(420px, 100%)',
      padding: 'var(--stz-space-l) var(--stz-space-l) var(--stz-space-m)',
      margin: 'auto',
    }),
  },
  stzStatus: css({
    '.stz-status-content': css({
      '--stz-status-padding': 0,
    }),
    '.stz-status-cta-group': css({
      width: '100%',
      textAlign: 'right',
      marginTop: 'var(--stz-space-xl)',
    }),
  }),
  supportText: css({
    marginTop: 'calc(var(--stz-font-size-m) * -1)',
    paddingTop: 'var(--stz-space-m)',
    whiteSpace: 'pre-line',
  }),
};
