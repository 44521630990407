import { prefersDarkTheme, setTheme, generateTheme } from '@staizen/graphene';
import { dark } from './configureDarkTheme';
import { light } from './configureLightTheme';
import { store } from './configureStore';

export enum Theme {
  Dark = 'dark',
  Light = 'light'
}

generateTheme(Theme.Dark, dark);
generateTheme(Theme.Light, light);

// setting the default theme based on user browser's darkMode preferences
// checking early to prevent flashes
const onTheme: any = (q: MediaQueryList) => {
  if (store) {
    const { user } = store.getState();
    const theme = user?.preferences?.theme;
    if (theme === undefined) {
      setTheme(q.matches ? Theme.Dark : Theme.Light);
    }
    return;
  }
  setTheme(q.matches ? Theme.Dark : Theme.Light);
};

const mq: MediaQueryList = prefersDarkTheme();
onTheme(mq);

if (mq.addEventListener) {
  mq.addEventListener('change', onTheme);
} else {
  // Fallback for when MediaQueryList.addEventListener is not defined
  mq.addListener(onTheme);
}
