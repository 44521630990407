import { ROUTING_SET_SELECTED_URL, ROUTING_SET_PAGE_DATA } from '../constants/routing';

export type PageDataStore = {
  [key: string]: string | number | object;
};

export function setSelectedUrl(localUrl: string): Action {
  return {
    type: ROUTING_SET_SELECTED_URL,
    payload: {
      selectedUrl: localUrl,
    },
  };
}

export function setPageData(data: PageDataStore): Action {
  return {
    type: ROUTING_SET_PAGE_DATA,
    payload: {
      ...data,
    },
  };
}
