import { AxiosInstance, AxiosError, AxiosResponse } from 'axios';
import { getClientPortalService } from '../../config/configureApi';
import { store } from '../../config/configureStore';
import { SessionTokenResponse } from './types';

export function refreshSessionToken(refreshToken: string) {
  const api: AxiosInstance = getClientPortalService();

  const { config } = store.getState();
  return config.useMock
    ? Promise.resolve({
      tokenType: 'Bearer',
      accessToken: 'mockAccessToken',
      refreshToken: 'mockRefreshToken',
      expiresIn: 300,
    } as SessionTokenResponse)
    : api.post(config.endpoints.refreshToken, { refreshToken })
      .then((response: AxiosResponse) => {
        return {
          tokenType: response.data.tokenType,
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken,
          // eslint-disable-next-line no-restricted-globals
          expiresIn: isNaN(response.data.expiresIn) ? undefined : Number(response.data.expiresIn),
        } as SessionTokenResponse;
      }).catch((error: AxiosError) => {
        console.warn('WARN: token refresh failed', error);
      });
}
