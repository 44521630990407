import { StzDialog } from '@staizen/graphene-react';
import { css } from 'emotion';
import React from 'react';

import { useBreakpoint } from '../../hooks/useBreakpoints';

interface DialogProps extends React.ComponentProps<typeof StzDialog> {
  hasStatusIndicator?: boolean;
}

const styles = {
  dialog: css({
    '--stz-dialog-min-height': 'min(80vh, 526px)', // Arbitrary value to to show status indicator with spaces around it
    '.stz-dialog-body': {
      position: 'relative',
      height: '100%',
    },
  }),
};

const Dialog: React.FC<DialogProps> = (props) => {
  const { className, hasStatusIndicator, ...rest } = props;
  const breakpoints = useBreakpoint();

  return (
    <StzDialog
      isFullscreen={breakpoints.xsDown}
      isModal
      className={`${className ?? ''} ${hasStatusIndicator ? styles.dialog : ''}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

export default Dialog;
