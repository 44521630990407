import { DateTimeService } from '@staizen/graphene';
import { AxiosResponse } from 'axios';
import { getClientPortalService } from '../../../config/configureApi';
import { store } from '../../../config/configureStore';
import { BankAccountType, TicketStatusHistory, TicketStatusHistoryItem } from '../types';

const { DateTime } = DateTimeService;

// Fields which are sent but we might not need
//   "requestId": 0,
//   "country": "SG",
//   "lastUpdatedBy": "k  yawzin",
//   "proofDoc": null, // dont have to return to frontend -> is returning the filename/upload path
//   "bankAccountRequestStatus": [ : "createdBy": "kyawzin",
interface BankAccountRequestStatusResponseItem {
  requestUID: string;
  requestStatus: string;
  createdTime: string;
}

interface ResponseItem {
  requestUID: string;
  userId: string;
  requestStatus: 'AP' | 'PE' | 'RE' | 'PR' | 'EX' | 'US'; // requestStatus
  amount: string;
  createdTime: string; // DateTimeService.DateTime;
  lastUpdatedTime: string; // DateTimeService.DateTime;
  bankAccountRequestStatus: BankAccountRequestStatusResponseItem[];
  bankName: string;
  bankAccNo: string;
  bankSwiftCode: string;
  requestType: BankAccountType;
}

// AP / Approved, PE / Pending, RE / Rejected, PR / Processing, EX / Expired, US / Unsuccessful
const getBankAccountTicketStatusHistory = async (id: string) => {
  const { config } = store.getState();
  const api = getClientPortalService();

  const response = await (config.useMock ? new Promise((res) => {
    setTimeout(() => {
      res(
        {
          requestUID: id,
          userId: 'ernest.cai',
          amount: '300',
          requestStatus: 'AP', // requestStatus
          createdTime: '2020-07-10T08:58:00.000Z',
          lastUpdatedTime: '2020-07-10T08:59:00.000Z',
          bankAccountRequestStatus: [
            {
              requestUID: id,
              requestStatus: 'PE',
              createdTime: '2020-07-10T08:58:00.000Z',
            },
            {
              requestUID: id,
              requestStatus: 'AP',
              createdTime: '2020-07-10T08:59:00.000Z',
            },
            {
              requestUID: id,
              requestStatus: 'RE',
              createdTime: '2020-07-10T08:59:00.000Z',
            },
            {
              requestUID: id,
              requestStatus: 'DE',
              createdTime: '2020-07-10T08:59:00.000Z',
            },
          ],
          bankName: 'DBS',
          bankAccNo: '123456789',
          bankSwiftCode: 'DBSSSG',
          requestType: id === 'ba2' ? BankAccountType.Remove : BankAccountType.Add,
        },
      );
    }, 1000);
  }) : api.get(config.endpoints.bankAccount.readTicket, {
    params: {
      requestUID: id,
    },
  }).then((apiResponse: AxiosResponse) => {
    return apiResponse.data;
  })) as ResponseItem;

  // TODO - Error handling, return empty details here or unable to load details here
  return {
    id: response.requestUID,
    type: 'BankAccount',
    status: response.requestStatus,
    createdTime: DateTime.fromISO(response.createdTime),
    lastUpdatedTime: DateTime.fromISO(response.lastUpdatedTime),
    statusHistory: response.bankAccountRequestStatus.map((item) => {
      return {
        transactionId: item.requestUID,
        status: item.requestStatus,
        createdTime: DateTime.fromISO(item.createdTime),
      } as TicketStatusHistoryItem;
    }),
    details: {
      userId: response.userId,
      amount: response.amount,
      bankName: response.bankName,
      bankAccount: response.bankAccNo,
      swiftCode: response.bankSwiftCode,
      requestType: response.requestType,
    },
  } as TicketStatusHistory;
};

export default getBankAccountTicketStatusHistory;
