import { store } from '../config/configureStore';

const getIsLoadingNonFallbackLocale = (error: Error) => {
  const errorMessage = error.message;
  return errorMessage.includes('locale') && !errorMessage.includes(store.getState().config.fallbackLocale);
};

// Referenced from https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5, rewritten with async/await
const retry = async (fn: () => Promise<any>, retriesLeft = 5, interval = 1000) => {
  for (let retriesLeftCount = retriesLeft; retriesLeftCount > 0; retriesLeftCount -= 1) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const result = await fn();
      return result;
    } catch (error) {
      console.error(error);
      if (getIsLoadingNonFallbackLocale(error as Error)) {
        // Do not retry if loading a non-fallback locale file as it will slow the app down if a component has not been translated
        return;
      }
      // Maximum retries reached
      if (retriesLeftCount === 1) {
        window.location.reload();
        return;
      }
    }

    // Prepare for next retry attempt
    // eslint-disable-next-line no-await-in-loop
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, interval);
    });
  }
};

export default retry;
