/* eslint-disable operator-linebreak */
import { AxiosInstance, AxiosError, AxiosResponse } from 'axios';
import { store } from '../../config/configureStore';
import { getSaxoService } from '../../config/configureApi';
import { Balance } from './types';

interface BalanceResponse {
  [key: string]: any;
}

export async function getBalance(clientKey: string, accountKey?: string) {
  const api: AxiosInstance = getSaxoService();
  const { config } = store.getState();
  // eslint-disable-next-line no-nested-ternary
  const response = await (config.useMock
    ? (!accountKey ? mockAggregateBalanceResponse : mockBalanceResponses[accountKey])
    : api.get(
      config.endpoints.saxo.balances,
      { params: { ClientKey: clientKey, AccountKey: accountKey } },
    ).then((apiResponse: AxiosResponse) => {
      return apiResponse.data as BalanceResponse;
    }).catch((error: AxiosError) => {
      console.error(error);
    }));
  return {
    cashBalance: response.CashBalance,
    currency: response.Currency,
    currencyDecimals: response.CurrencyDecimals,
  } as Balance;
}

const mockBalanceResponses: { [key: string]: BalanceResponse } = {
  ak1: {
    CalculationReliability: 'Ok',
    CashAvailableForTrading: 1000000,
    CashBalance: 1000000,
    ChangesScheduled: false,
    ClosedPositionsCount: 0,
    CollateralAvailable: 1000000,
    CollateralCreditValue: {
      Line: 1000000,
      UtilizationPct: 0,
    },
    CostToClosePositions: 0,
    Currency: 'SGD',
    CurrencyDecimals: 2,
    InitialMargin: {
      CollateralAvailable: 1000000,
      CollateralCreditValue: {
        Line: 1000000,
        UtilizationPct: 0,
      },
      MarginAvailable: 1000000,
      MarginCollateralNotAvailable: 0,
      MarginUsedByCurrentPositions: 0,
      MarginUtilizationPct: 0,
      NetEquityForMargin: 1000000,
    },
    IsPortfolioMarginModelSimple: true,
    MarginAvailableForTrading: 1000000,
    MarginCollateralNotAvailable: 0,
    MarginExposureCoveragePct: 0,
    MarginNetExposure: 0,
    MarginUsedByCurrentPositions: 0,
    MarginUtilizationPct: 0,
    NetEquityForMargin: 1000000,
    NetPositionsCount: 0,
    NonMarginPositionsValue: 0,
    OpenPositionsCount: 0,
    OptionPremiumsMarketValue: 0,
    OrdersCount: 0,
    OtherCollateral: 0,
    SettlementValue: 0,
    TotalValue: 1000000,
    TransactionsNotBooked: 0,
    UnrealizedMarginClosedProfitLoss: 0,
    UnrealizedMarginOpenProfitLoss: 0,
    UnrealizedMarginProfitLoss: 0,
    UnrealizedPositionsValue: 0,
  },
  ak2: {
    CalculationReliability: 'Ok',
    CashAvailableForTrading: 1000000,
    CashBalance: 1000000,
    ChangesScheduled: false,
    ClosedPositionsCount: 0,
    CollateralAvailable: 1000000,
    CollateralCreditValue: {
      Line: 1000000,
      UtilizationPct: 0,
    },
    CostToClosePositions: 0,
    Currency: 'USD',
    CurrencyDecimals: 2,
    InitialMargin: {
      CollateralAvailable: 1000000,
      CollateralCreditValue: {
        Line: 1000000,
        UtilizationPct: 0,
      },
      MarginAvailable: 1000000,
      MarginCollateralNotAvailable: 0,
      MarginUsedByCurrentPositions: 0,
      MarginUtilizationPct: 0,
      NetEquityForMargin: 1000000,
    },
    IsPortfolioMarginModelSimple: true,
    MarginAvailableForTrading: 1000000,
    MarginCollateralNotAvailable: 0,
    MarginExposureCoveragePct: 0,
    MarginNetExposure: 0,
    MarginUsedByCurrentPositions: 0,
    MarginUtilizationPct: 0,
    NetEquityForMargin: 1000000,
    NetPositionsCount: 0,
    NonMarginPositionsValue: 0,
    OpenPositionsCount: 0,
    OptionPremiumsMarketValue: 0,
    OrdersCount: 0,
    OtherCollateral: 0,
    SettlementValue: 0,
    TotalValue: 1000000,
    TransactionsNotBooked: 0,
    UnrealizedMarginClosedProfitLoss: 0,
    UnrealizedMarginOpenProfitLoss: 0,
    UnrealizedMarginProfitLoss: 0,
    UnrealizedPositionsValue: 0,
  },
  ak3: {
    CalculationReliability: 'Ok',
    CashAvailableForTrading: 1000000,
    CashBalance: 1000000,
    ChangesScheduled: false,
    ClosedPositionsCount: 0,
    CollateralAvailable: 1000000,
    CollateralCreditValue: {
      Line: 1000000,
      UtilizationPct: 0,
    },
    CostToClosePositions: 0,
    Currency: 'GBP',
    CurrencyDecimals: 2,
    InitialMargin: {
      CollateralAvailable: 1000000,
      CollateralCreditValue: {
        Line: 1000000,
        UtilizationPct: 0,
      },
      MarginAvailable: 1000000,
      MarginCollateralNotAvailable: 0,
      MarginUsedByCurrentPositions: 0,
      MarginUtilizationPct: 0,
      NetEquityForMargin: 1000000,
    },
    IsPortfolioMarginModelSimple: true,
    MarginAvailableForTrading: 1000000,
    MarginCollateralNotAvailable: 0,
    MarginExposureCoveragePct: 0,
    MarginNetExposure: 0,
    MarginUsedByCurrentPositions: 0,
    MarginUtilizationPct: 0,
    NetEquityForMargin: 1000000,
    NetPositionsCount: 0,
    NonMarginPositionsValue: 0,
    OpenPositionsCount: 0,
    OptionPremiumsMarketValue: 0,
    OrdersCount: 0,
    OtherCollateral: 0,
    SettlementValue: 0,
    TotalValue: 1000000,
    TransactionsNotBooked: 0,
    UnrealizedMarginClosedProfitLoss: 0,
    UnrealizedMarginOpenProfitLoss: 0,
    UnrealizedMarginProfitLoss: 0,
    UnrealizedPositionsValue: 0,
  },
  ak4: {
    CalculationReliability: 'Ok',
    CashAvailableForTrading: 1000000,
    CashBalance: 1000000,
    ChangesScheduled: false,
    ClosedPositionsCount: 0,
    CollateralAvailable: 1000000,
    CollateralCreditValue: {
      Line: 1000000,
      UtilizationPct: 0,
    },
    CostToClosePositions: 0,
    Currency: 'AUD',
    CurrencyDecimals: 2,
    InitialMargin: {
      CollateralAvailable: 1000000,
      CollateralCreditValue: {
        Line: 1000000,
        UtilizationPct: 0,
      },
      MarginAvailable: 1000000,
      MarginCollateralNotAvailable: 0,
      MarginUsedByCurrentPositions: 0,
      MarginUtilizationPct: 0,
      NetEquityForMargin: 1000000,
    },
    IsPortfolioMarginModelSimple: true,
    MarginAvailableForTrading: 1000000,
    MarginCollateralNotAvailable: 0,
    MarginExposureCoveragePct: 0,
    MarginNetExposure: 0,
    MarginUsedByCurrentPositions: 0,
    MarginUtilizationPct: 0,
    NetEquityForMargin: 1000000,
    NetPositionsCount: 0,
    NonMarginPositionsValue: 0,
    OpenPositionsCount: 0,
    OptionPremiumsMarketValue: 0,
    OrdersCount: 0,
    OtherCollateral: 0,
    SettlementValue: 0,
    TotalValue: 1000000,
    TransactionsNotBooked: 0,
    UnrealizedMarginClosedProfitLoss: 0,
    UnrealizedMarginOpenProfitLoss: 0,
    UnrealizedMarginProfitLoss: 0,
    UnrealizedPositionsValue: 0,
  },
  ak5: {
    CalculationReliability: 'Ok',
    CashAvailableForTrading: 1000000,
    CashBalance: 1000000,
    ChangesScheduled: false,
    ClosedPositionsCount: 0,
    CollateralAvailable: 1000000,
    CollateralCreditValue: {
      Line: 1000000,
      UtilizationPct: 0,
    },
    CostToClosePositions: 0,
    Currency: 'HKD',
    CurrencyDecimals: 2,
    InitialMargin: {
      CollateralAvailable: 1000000,
      CollateralCreditValue: {
        Line: 1000000,
        UtilizationPct: 0,
      },
      MarginAvailable: 1000000,
      MarginCollateralNotAvailable: 0,
      MarginUsedByCurrentPositions: 0,
      MarginUtilizationPct: 0,
      NetEquityForMargin: 1000000,
    },
    IsPortfolioMarginModelSimple: true,
    MarginAvailableForTrading: 1000000,
    MarginCollateralNotAvailable: 0,
    MarginExposureCoveragePct: 0,
    MarginNetExposure: 0,
    MarginUsedByCurrentPositions: 0,
    MarginUtilizationPct: 0,
    NetEquityForMargin: 1000000,
    NetPositionsCount: 0,
    NonMarginPositionsValue: 0,
    OpenPositionsCount: 0,
    OptionPremiumsMarketValue: 0,
    OrdersCount: 0,
    OtherCollateral: 0,
    SettlementValue: 0,
    TotalValue: 1000000,
    TransactionsNotBooked: 0,
    UnrealizedMarginClosedProfitLoss: 0,
    UnrealizedMarginOpenProfitLoss: 0,
    UnrealizedMarginProfitLoss: 0,
    UnrealizedPositionsValue: 0,
  },
};

const mockAggregateBalanceResponse: BalanceResponse = {
  CalculationReliability: 'Ok',
  CashBalance: 1000000,
  ChangesScheduled: false,
  ClosedPositionsCount: 0,
  CollateralAvailable: 1000000,
  CollateralCreditValue: {
    Line: 1000000,
    UtilizationPct: 0,
  },
  CostToClosePositions: 0,
  Currency: 'SGD',
  CurrencyDecimals: 2,
  InitialMargin: {
    CollateralAvailable: 1000000,
    CollateralCreditValue: {
      Line: 1000000,
      UtilizationPct: 0,
    },
    MarginAvailable: 1000000,
    MarginCollateralNotAvailable: 0,
    MarginUsedByCurrentPositions: 0,
    MarginUtilizationPct: 0,
    NetEquityForMargin: 1000000,
  },
  IsPortfolioMarginModelSimple: true,
  MarginAvailableForTrading: 1000000,
  MarginCollateralNotAvailable: 0,
  MarginExposureCoveragePct: 0,
  MarginNetExposure: 0,
  MarginUsedByCurrentPositions: 0,
  MarginUtilizationPct: 0,
  NetEquityForMargin: 1000000,
  NetPositionsCount: 0,
  NonMarginPositionsValue: 0,
  OpenPositionsCount: 0,
  OptionPremiumsMarketValue: 0,
  OrdersCount: 0,
  OtherCollateral: 0,
  SettlementValue: 0,
  TotalValue: 1000000,
  TransactionsNotBooked: 0,
  UnrealizedMarginClosedProfitLoss: 0,
  UnrealizedMarginOpenProfitLoss: 0,
  UnrealizedMarginProfitLoss: 0,
  UnrealizedPositionsValue: 0,
} as any;
