export enum ERROR_TYPE {
  NO_CONNECTION = 'NO_CONNECTION', // client's browser cannot connect to our API
  UNAUTHENTICATED = 'UNAUTHENTICATED', // typically 401, no access to the called api
  EXCEPTION = 'EXCEPTION', // unhandled exception
  UNKNOWN = 'UNKNOWN' // we did not cater for this, if it appears in production it is probably a bug
}

export enum CLIENT_PORTAL_ERROR_TYPE {
  SAXO_USER_TOKEN_FAIL = '500_GENERIC_SAXO_USER_TOKEN_FAIL', // BE unhandled exception
  SAXO_USER_NOT_FOUND = '403', // user does not have a linked saxo account
  CONCURRENT_SESSION = '409', // user session is invalid, logged in elsewhere
}
