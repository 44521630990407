/* eslint-disable operator-linebreak */
import { store } from '../../../config/configureStore';
import { getQueryParams } from '@staizen/utils/dist/URLUtils';
import { getClientKey } from '../getClientKey';
import { getAccounts } from '../getAccounts';
import { getBalance } from '../getBalance';
import { CurrencyAccount } from '../types';
import { getAvailableWithdrawals } from '../getAvailableWithdrawals';

const getMockAccountKeys = () => new Promise((res) => {
  setTimeout(() => { // mock account keys should match account keys for mock balance and withdrawal limit responses
    res([{ AccountKey: 'ak1' }, { AccountKey: 'ak2' }, { AccountKey: 'ak3' }, { AccountKey: 'ak4' }, { AccountKey: 'ak5' }]);
  }, 1000);
});

export function getCurrencyAccount(clientKey: string, accountKey?: string) {
  return Promise.all([
    getBalance(clientKey, accountKey),
    getAvailableWithdrawals(clientKey, accountKey),
  ]).then((ticketResponses) => {
    return {
      ...ticketResponses[0],
      ...ticketResponses[1],
    } as CurrencyAccount;
  });
}

export async function getCurrencyAccounts() {
  const { config } = store.getState();
  const queryParams = getQueryParams(window.location.search, true) as { mockCurrencyAccountsError?: string };
  if (config.useMock && queryParams.mockCurrencyAccountsError === 'true') {
    return { error: true };
  }
  const clientKey = await getClientKey();
  const accounts = await (config.useMock ? getMockAccountKeys() : getAccounts());

  if (clientKey.error || accounts.error) {
    return { error: clientKey.error || accounts.error };
  }
  const aggregateAccount = await getBalance(clientKey);
  const currencyAccounts: CurrencyAccount[] = await Promise.all(accounts.map((account: any) => {
    return getCurrencyAccount(clientKey, account.AccountKey);
  }));

  return { total: aggregateAccount, groups: currencyAccounts };
}
