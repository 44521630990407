// Adapted from source: https://www.w3schools.com/js/js_cookies.asp
export const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    // eslint-disable-next-line
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    // eslint-disable-next-line
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

// Adapted from source: https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie#A_little_framework.3A_a_complete_cookies_reader.2Fwriter_with_full_unicode_support
export const deleteCookie = (key, path = '/', domain = `.${window.location.hostname.split('.').slice(1).join('.')}`) => {
  // eslint-disable-next-line indent
  document.cookie = `${encodeURIComponent(key)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${domain ? `; domain=${domain}` : ''}${path ? `; path=${path}` : ''}`;
};
