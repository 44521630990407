import { Capacitor } from '@capacitor/core';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { Keyboard } from '@capacitor/keyboard';

const DEBOUNCE_DELAY = 50;

// Offset to ensure that element is within visible view (could be blocked by header or footer)
const OFFSET_TOP = 115;
const OFFSET_BOTTOM = 50;

const getIsElementInViewport = (el: Element) => {
  const rect = el.getBoundingClientRect();

  const maxBottom = (window.innerHeight || document.documentElement.clientHeight) - OFFSET_BOTTOM;
  const maxRight = window.innerWidth || document.documentElement.clientWidth;

  return (
    rect.top >= OFFSET_TOP
    && rect.left >= 0
    && rect.bottom <= maxBottom
    && rect.right <= maxRight
  );
};

const getIsValidActiveElement = (el: Element) => {
  return el.tagName && el.tagName !== 'body';
};

const useVirtualKeyboardListener = () => {
  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      Keyboard.addListener('keyboardDidShow', debouncedOnKeyboardShow);
      return () => {
        Keyboard.removeAllListeners();
      };
    }
  }, []);

  const onKeyboardShow = () => {
    const { activeElement } = document;
    if (getIsValidActiveElement(activeElement) && !getIsElementInViewport(activeElement)) {
      // Ensure that focused input element will not be hidden behind keyboard when keyboard shows by scrolling element into view
      activeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };
  // Debounce to ensure that input is already at its final position before determining scroll is needed
  const debouncedOnKeyboardShow = debounce(onKeyboardShow, DEBOUNCE_DELAY);
};

export default useVirtualKeyboardListener;
