import project from '../../package.json';
import { LINKS } from './configureLinks';

interface MenuItem {
  title: string;
  href: string;
  menu?: MenuItem[];
}

export interface MenuStore {
  profile?: {
    title: string;
    href: string;
    menu: MenuItem[];
  };
  menu: MenuItem[];
  revision?: {
    version: string;
    commit: string;
  };
  selectedUrl?: string;
}

export const generateMenu = (
  t: Function = null,
): MenuStore => {
  const profile = {
    title: undefined as any,
    href: LINKS.PROFILE,
    menu: [
      {
        title: t('account'),
        href: LINKS.ACCOUNT,
      },
      {
        title: t('settings'),
        href: LINKS.SETTINGS,
      },
      {
        title: t('logout'),
        href: LINKS.LOGOUT,
      },
    ],
  };
  const menu = [
    // {
    //   title: 'Identity Vault',
    //   href: '/test-identity-vault',
    // },
    {
      title: t('dashboard'),
      href: LINKS.DASHBOARD,
    },
    {
      title: t('funding'),
      href: LINKS.FUNDING,
    },
    {
      title: t('actions'),
      href: LINKS.ACTIONS,
    },
    {
      title: t('products'),
      href: LINKS.PRODUCTS,
    },
    {
      title: t('commentary'),
      href: LINKS.INVESTMENT_INSIGHTS,
    },
    {
      title: t('help'),
      href: LINKS.HELP,
      menu: [
        { title: t('faq'), href: LINKS.HELP_FAQ },
        { title: t('contact'), href: LINKS.HELP_CONTACT_US },
      ],
    },
  ];
  const revision = {
    version: `v${project.version}`,
    commit: `${process.env.REACT_APP_GIT_SHA || ''}`,
  };

  return {
    profile,
    menu,
    revision,
  };
};
