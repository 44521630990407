import { store } from '../../../config/configureStore';
import { getClientPortalService } from '../../../config/configureApi';
import { AxiosError, AxiosInstance } from 'axios';
import { CkacarTicketStatus } from '../../products/types';
import { TicketMap } from '../types';
import { DateTimeService } from '@staizen/graphene';

const { DateTime } = DateTimeService;

export interface GetCkacarTicketsParams {
  requestStatus?: CkacarTicketStatus; // currently can only request a single status
}

interface ResponseItem {
  createdTime: string;
  lastUpdatedTime: string;
  requestStatus: CkacarTicketStatus;
  requestUID: string;
  userID: string;
}

const getMockResponse = (
  params: GetCkacarTicketsParams,
): Promise<ResponseItem[]> => (
  new Promise((res) => {
    setTimeout(() => {
      res(
        [
          {
            createdTime: '2022-02-08T04:38:44.880Z',
            lastUpdatedTime: '2022-02-08T04:38:44.880Z',
            requestStatus: CkacarTicketStatus.NullVoid,
            requestUID: 'CKACKR00000130s',
            userID: 'ernest.cai',
          },
          {
            createdTime: '2022-02-08T04:38:44.880Z',
            lastUpdatedTime: '2022-02-08T04:38:44.880Z',
            requestStatus: CkacarTicketStatus.Unsuccessful,
            requestUID: 'CKACKR00000129s',
            userID: 'ernest.cai',
          },
          {
            createdTime: '2022-02-08T04:38:44.880Z',
            lastUpdatedTime: '2022-02-08T04:38:44.880Z',
            requestStatus: CkacarTicketStatus.Rejected,
            requestUID: 'CKACKR00000128s',
            userID: 'ernest.cai',
          },
          {
            createdTime: '2022-02-08T04:38:44.880Z',
            lastUpdatedTime: '2022-02-08T04:38:44.880Z',
            requestStatus: CkacarTicketStatus.Pending,
            requestUID: 'CKACKR00000126s',
            userID: 'ernest.cai',
          },
          {
            createdTime: '2022-02-08T04:38:44.880Z',
            lastUpdatedTime: '2022-02-08T04:38:44.880Z',
            requestStatus: CkacarTicketStatus.Approved,
            requestUID: 'CKACKR00000127s',
            userID: 'ernest.cai',
          },
        ].filter((ticket: ResponseItem) => {
          const { requestStatus } = params;
          return !requestStatus ? true : ticket.requestStatus === requestStatus;
        }),
      );
    }, 1000);
  })
);

const getApiResponse = (
  params: GetCkacarTicketsParams = {},
  api: AxiosInstance, config: Config,
): Promise<ResponseItem[]> => (
  api.get(config.endpoints.cka.readTickets, {
    params: {
      requestStatus: params.requestStatus,
    },
  }).then((response: any) => {
    const emptyTickets = response.status === 204;
    if (emptyTickets) {
      return [];
    }
    return response.data;
  }).catch((error: AxiosError) => {
    console.error(error);
    const { response } = error;
    const errorCode = String(response?.status);
    if (response && response.status === 400 && response.data) {
      if (response.data.message) {
        console.error(response.data.message);
        if (response.data.message === 'No Record Found') {
          return []; // emptyTickets
        }
      } else {
        console.error('ERROR: Fail to get all cka car tickets, error code: ', errorCode);
      }
    }
    return [];
  })
);

type getCkacarTickets = (
  params?: GetCkacarTicketsParams,
) => Promise<TicketMap>
const getCkacarTickets: getCkacarTickets = async (params = {}) => {
  const { config } = store.getState();
  // Timeout for delay
  const api = getClientPortalService();
  const response: ResponseItem[] = await (config.useMock
    ? getMockResponse(params)
    : getApiResponse(params, api, config)
  );

  const tickets = response.reduce((acc: TicketMap, item: ResponseItem) => {
    acc.index.push(item.requestUID);
    acc.data[item.requestUID] = {
      id: item.requestUID,
      type: 'Ckacar',
      status: item.requestStatus,
      lastUpdated: DateTime.fromISO(item.lastUpdatedTime),
      details: {
        userId: item.userID,
        createdTime: DateTime.fromISO(item.createdTime),
      },
    };
    return acc;
  }, { data: {}, index: [] });

  tickets.order = tickets.index.sort((o1, o2) => { // sort from latest to oldest updated
    return tickets.data[o1].lastUpdated > tickets.data[o2].lastUpdated ? -1 : 1;
  });

  return tickets;
};

export default getCkacarTickets;
