import React from 'react';
import { Store } from 'redux';
import { Provider, ReactReduxContext } from 'react-redux';
import { BreakpointProvider, queries } from './useBreakpoints';

export const StoreContext = ReactReduxContext;

interface Props {
  store: Store;
  children: React.ReactNode;
}

export const ProsperUsContext: React.FC<Props> = (props: Props) => {
  const { children, store } = props;

  return (
    <BreakpointProvider queries={queries}>
      <Provider store={store}>
        {children}
      </Provider>
    </BreakpointProvider>
  );
};
