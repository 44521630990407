import { AxiosInstance, AxiosResponse } from 'axios';
import { getQueryParams } from '@staizen/utils/dist/URLUtils';
import { DateTimeService } from '@staizen/graphene';
import { W8Status } from '../../actions/creators/user';
import { store } from '../../config/configureStore';
import { getClientPortalService } from '../../config/configureApi';
import { DATE_FORMAT, PRIVATE_WEALTH_ACCOUNT_TYPE } from './constants';
import { Account, INVESTOR_TYPE, UserParticulars } from './types';
import { isAccountPrivateWealth, transformUserParticulars } from './utils';

import { logoutUser } from '../auth';

const { DateTime } = DateTimeService;
interface GetUserParticularsResponse {
  activationTime: string;
  email: string;
  lastLoginTime: string;
  lastLogoutTime: string;
  mobileNo: string;
  name: string;
  userID: string;
  accounts: Account[];
  userAttributes: {
    novum: string | undefined; // e.g."12818177"
    betaTester: string | undefined;
    generatedReferCode: string | undefined;
  };
  myrInfo?: {
    fundingEnabled: boolean;
    withdrawalEnabled: boolean;
    isResident: boolean | null;
  };
}

interface MockParams {
  mockW8Status?: string;
  mockNoTradingAccount?: string; // true, false
  mockMyrIsResident?: string; // null (unknown status pending processing), true (my resident), false (non-my resident)
  mockMyrDisabled?: string; // true, false
  mockBetaTester?: string; // true, false
  mockYoungInvestor?: string;
  mockPrivateWealth?: string; // true, false
}

const getExpiryDate = (status: string) => {
  const today = DateTime.fromJSDate(new Date());
  switch (status) {
    case W8Status.UNDECLARED:
      return null;
    case W8Status.EXPIRING:
      return today.plus({ day: 1 }).toFormat(DATE_FORMAT);
    case W8Status.EXPIRED:
      return today.minus({ day: 1 }).toFormat(DATE_FORMAT);
    default:
      return today.plus({ year: 1 }).toFormat(DATE_FORMAT);
  }
};

const getMockW8ExpiryDate = (w8Status: string) => {
  return getExpiryDate(w8Status);
};

const getMockParticulars = (
  mockW8ExpiryDate: string,
  { mockNoTradingAccount,
    mockMyrDisabled,
    mockMyrIsResident,
    mockBetaTester,
    mockYoungInvestor,
    mockPrivateWealth }: MockParams = {},
): GetUserParticularsResponse => ({
  activationTime: '2020-07-29T00:13:59.870Z',
  email: 'ernest.cai@cgs-cimb.com',
  lastLoginTime: '2020-07-29T00:13:59.870Z',
  lastLogoutTime: '2020-07-29T00:13:59.870Z',
  mobileNo: '+6598887666',
  name: 'Ernest Cai Kun Rong',
  userID: 'ernest.cai',
  accounts: mockNoTradingAccount
    ? []
    : [
      {
        accountNo: 'acc-1234',
        accountType: 'mock data',
        address1: '10 Mount Sophia',
        address2: '',
        address3: '',
        bcan: 'mock data',
        birthday: '19820101', // mock date set to 1982 for testing purposes see known issues in README.md
        categoryCode: 'mock data',
        cipStatus: 'mock data',
        email: 'ernest-account.cai@cgs-cimb.com',
        ew8ExpiryDate1: mockW8ExpiryDate,
        ew8ExpiryDate2: null,
        gsaStatusFlag: 'mock data',
        handphoneNo: '+65 9888 7666',
        lastUploadedOn: '2020-07-29T00:13:59.870Z',
        name: 'Ernest Cai Kun Rong',
        officePhoneNo: '+65 9888 7666',
        partyID1: 'mock data',
        partyID2: 'mock data',
        postalCode: '228459',
        rdsStatus: 'mock data',
        recordStatus: 'mock data',
        residencePhoneNo: '+65 9888 7666',
        saxoId: undefined,
        sipStatus: 'mock data',
        traderCode: 'mock data',
        unitCode: 'mock data',
        youngInvestor: mockYoungInvestor === 'true' ? INVESTOR_TYPE.YOUNG_INVESTOR : undefined,
      },
      mockPrivateWealth
        ? {
          accountType: PRIVATE_WEALTH_ACCOUNT_TYPE,
          accountNo: 'acc-1234-pwealth',
          address1: '10 Mount Sophia',
          address2: '',
          address3: '',
          bcan: 'mock data',
          birthday: '19820101', // mock date set to 1982 for testing purposes see known issues in README.md
          categoryCode: 'mock data',
          cipStatus: 'mock data',
          email: 'ernest-account.cai@cgs-cimb.com',
          ew8ExpiryDate1: mockW8ExpiryDate,
          ew8ExpiryDate2: null,
          gsaStatusFlag: 'mock data',
          handphoneNo: '+65 9888 7666',
          lastUploadedOn: '2020-07-29T00:13:59.870Z',
          name: 'Ernest Cai Kun Rong',
          officePhoneNo: '+65 9888 7666',
          partyID1: 'mock data',
          partyID2: 'mock data',
          postalCode: '228459',
          rdsStatus: 'mock data',
          recordStatus: 'mock data',
          residencePhoneNo: '+65 9888 7666',
          saxoId: undefined,
          sipStatus: 'mock data',
          traderCode: 'mock data',
          unitCode: 'mock data',
          youngInvestor: mockYoungInvestor === 'true' ? INVESTOR_TYPE.YOUNG_INVESTOR : undefined,
        }
        : {} as any,
    ],
  userAttributes: {
    novum: mockNoTradingAccount === 'true' ? undefined : '12818177',
    betaTester: mockBetaTester === 'true' ? '1' : undefined,
    generatedReferCode: 'P83F0TV',
  },
  myrInfo: {
    fundingEnabled: mockMyrDisabled === 'undefined' ? undefined : mockMyrDisabled !== 'true',
    withdrawalEnabled: mockMyrDisabled === 'undefined' ? undefined : mockMyrDisabled !== 'true',
    isResident: mockMyrIsResident === 'null' ? null : mockMyrIsResident === 'true',
  },
});

const getParticulars = async () => {
  const api: AxiosInstance = getClientPortalService();

  const { config } = store.getState();

  const queryParams = getQueryParams(window.location.search, true) as MockParams;

  const particularsResponse: GetUserParticularsResponse = await (
    !config.useMock
      ? api.get(config.endpoints.particulars)
        .then((apiResponse: AxiosResponse) => {
          return apiResponse.data;
        })
      : new Promise((res) => {
        setTimeout(() => {
          const mockW8ExpiryDate = getMockW8ExpiryDate(queryParams.mockW8Status);
          res(getMockParticulars(mockW8ExpiryDate, queryParams));
        }, 1000);
      })
  );

  if (!particularsResponse || !particularsResponse.accounts) {
    console.warn('No accounts found for user with response: ', particularsResponse);
    logoutUser(); // Unable to get back particulars, app is not usable
  }

  const { accounts, userAttributes, ...rest } = particularsResponse;
  const prosperUsAccount = accounts.find((account) => !isAccountPrivateWealth(account));
  const privateWealthAccount = accounts.find((account) => isAccountPrivateWealth(account));
  const userParticulars: UserParticulars = { // level 1 user data takes precedence over account data e.g. email
    ...prosperUsAccount,
    privateWealthAccount,
    ...rest,
    saxoId: userAttributes?.novum,
    betaTester: userAttributes?.betaTester,
    referralCode: userAttributes?.generatedReferCode,
    youngInvestor: prosperUsAccount?.youngInvestor === INVESTOR_TYPE.YOUNG_INVESTOR,
  };

  return transformUserParticulars(userParticulars);
};

export default getParticulars;
